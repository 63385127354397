import BucketImage from 'components/BucketImage';
import StepNumber from 'components/StepNumber';
import { FC } from 'react';
import { Typography } from 'ui/Material';

import './ProcedureSteps.scss';

interface Steps {
  title: string;
  description: string;
  image: string;
}

interface Props {
  steps: Array<Steps>;
}

const ProcedureSteps: FC<Props> = ({ steps }) => {
  return (
    <div className="ProcedureSteps" data-testid="ProcedureSteps">
      {steps.map(({ title, description, image }, index) => (
        <div key={index} className="step">
          <figure>
            <BucketImage loading="lazy" width="280" height="320" alt={title} src={image} />
            <StepNumber value={index + 1} />
          </figure>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
        </div>
      ))}
    </div>
  );
};

export default ProcedureSteps;
