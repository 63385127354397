class Storage {
  complete(text: string) {
    return '@STORAGE::' + String(text).toUpperCase();
  }
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  set(name: string, value: any) {
    value = JSON.stringify(value);
    return sessionStorage.setItem(this.complete(name), value);
  }
  get(name: string) {
    let data = sessionStorage.getItem(this.complete(name));
    if (data && this.isJson(data)) {
      // eslint-disable-next-line
      data = JSON.parse(data.replace(/\"/g, '"').replace(/\\\\/g, '\\'));
    }
    return data;
  }
  remove(name: string) {
    return sessionStorage.removeItem(this.complete(name));
  }
  clear() {
    return sessionStorage.clear();
  }
}

const storage = new Storage();

export default storage;
