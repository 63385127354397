import { FC } from 'react';
import { ButtonProps, Button as OriginalButton, CircularProgress } from 'ui/Material';

import './Button.scss';

interface Props extends ButtonProps {
  loading?: boolean;
}

const Button: FC<Props> = (props) => {
  const handleOnClick = (event: any) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const defaultButtonProps = {
    className: 'Button',
    disabled: props.loading || props.disabled,
    onClick: handleOnClick
  };

  return (
    <OriginalButton variant="contained" data-testid="Button" {...defaultButtonProps} {...props}>
      {props.loading && <CircularProgress data-testid="Loading" size={25} />}
      {props.children}
    </OriginalButton>
  );
};

export default Button;
