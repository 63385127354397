import { QuestionItem } from 'models/questionnaire';
import { FC } from 'react';
import MultipleOptionWithSubItems from '../MultipleOptionWithSubItems';

interface Option {
  id: any;
  text: string;
  use_input?: boolean;
  excludes?: boolean;
  sub_questions: any[];
}

interface Props {
  urlIcon?: string;
  title: string;
  description?: string;
  options: Array<Option>;
  onSubmit: any;
  SubItemsComponent?: any;
  subItemsComponentProps?: any;
  daughterQuestions?: Array<QuestionItem>;
  numberQuestion: string;
}

const MultipleOptionSingleSelection: FC<Props> = (props) => {
  const wrapperProps = {
    className: 'MultipleOptionSingleSelection',
    'data-testid': 'MultipleOptionSingleSelection'
  };

  const options = props.options.map((option) => ({
    ...option,
    excludes: true
  }));

  return (
    <div {...wrapperProps}>
      <MultipleOptionWithSubItems {...props} options={options} />
    </div>
  );
};

export default MultipleOptionSingleSelection;
