import images from 'assets/images';
import InfoByColumns from 'components/InfoByColumns';
import LinkButton from 'components/LinkButton';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'ui/Material';
import * as routes from 'navigation/routes';
import './ClientTestimonials.scss';
import BucketImage from 'components/BucketImage';

const ClientTestimonials: FC = () => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="ClientTestimonials" data-testid="ClientTestimonials">
      <InfoByColumns
        columns={[
          {
            className: 'client-wrapper',
            breakpoints: {
              md: 12,
              sm: 12,
              xs: 12
            },
            content: (
              <>
                <Typography>Claudia, contadora</Typography>
                <Typography variant="h6">
                  “Ahora sí puedo sentir que soy un buen ejemplo para mis hijos”
                </Typography>
                <Typography variant="caption">Plan enfocado a:</Typography>
                <ul>
                  <li>
                    <BucketImage
                      loading="lazy"
                      width="45"
                      height="45"
                      alt="Energía"
                      src={images.clientTestimonials.energy}
                    />
                    <Typography>Energía</Typography>
                  </li>
                  <li>
                    <BucketImage
                      loading="lazy"
                      alt="Multinutriente"
                      src={images.clientTestimonials.nutritional}
                    />
                    <Typography>Multinutriente</Typography>
                  </li>
                </ul>
                <BucketImage
                  loading="lazy"
                  width="400"
                  height="340"
                  alt="client"
                  className="client"
                  src={images.ClientWoman}
                />
              </>
            )
          },
          {
            className: 'call-to-action',
            content: (
              <>
                <Typography variant="h5">Realiza nuestro test personalizado</Typography>
                <Typography variant="h6">Sólo te toma 9 minutos</Typography>
                <Typography>
                  Adquiere el programa con seguimiento personalizado a partir de nuestro test
                  realizado por expertos para identificar lo que necesitas y mantener una vida
                  saludable
                </Typography>
                <LinkButton
                  to={routes.assessmentStart}
                  label={t('Home.testimonials.callToAction')}
                />
              </>
            )
          }
        ]}
      />
    </div>
  );
};

export default ClientTestimonials;
