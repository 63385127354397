import { FC, ReactElement } from 'react';
import { IconButton, Modal as OriginalModal, ModalProps } from 'ui/Material';
import { MdClose } from 'react-icons/md';

import './Modal.scss';

interface CustomModalProps extends Omit<ModalProps, 'children'> {
  children: ReactElement<any, any>;
}

const Modal: FC<CustomModalProps> = (props) => {
  const handleCloseEvent = (event: any) => {
    if (props.onClose) {
      props.onClose(event, 'escapeKeyDown');
    }
  };

  return (
    <OriginalModal {...props}>
      <div className="Modal" data-testid="Modal">
        <IconButton data-testid="CloseButton" className="close-button" onClick={handleCloseEvent}>
          <MdClose />
        </IconButton>
        {props.children}
      </div>
    </OriginalModal>
  );
};

export default Modal;
