import Loading from 'components/Loading/Loading';
import { FC } from 'react';
import './LoadingScreen.scss';

interface LoadingScreenProps {
  loading: boolean;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ loading }) => {
  if (!loading) {
    return <div data-testid="empty-LoadingScreen" />;
  }
  return (
    <div className="LoadingScreen" data-testid="LoadingScreen">
      <Loading loading={true}>
        <div />
      </Loading>
    </div>
  );
};

export default LoadingScreen;
