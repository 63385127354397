import { getServicePlan, saveApproach } from 'adapters/Axios/services/questionnaire.service';
import { FC, useEffect, useState } from 'react';
import { Card, Grid, Typography } from 'ui/Material';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import './PlanList.scss';
import CurrencyFormat from 'components/CurrencyFormat';
import LoadingScreen from 'components/LoadingScreen';
import TagManager from 'react-gtm-module';

interface Props {
  selectedProducts: any;
}

const PlanList: FC<Props> = ({ selectedProducts }) => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [planList, setPlanList] = useState<any>({
    singleProduct: [],
    dualProduct: []
  });

  useEffect(() => {
    getServicePlan().then((response: any) => {
      const singleProduct = response.data.find((plan: any) => plan.benefit === 1);
      const dualProduct = response.data.find((plan: any) => plan.benefit === 2);
      setPlanList({
        singleProduct: [
          {
            name: '1 mes',
            price: singleProduct.one_month,
            description: singleProduct.one_month_description,
            frequency: 1
          },
          {
            name: '3 meses',
            price: singleProduct.three_month,
            description: singleProduct.three_month_description,
            frequency: 3
          },
          {
            name: '6 meses',
            price: singleProduct.six_month,
            description: singleProduct.six_month_description,
            frequency: 6,
            disabled: true
          }
        ],
        dualProduct: [
          {
            name: '1 mes',
            price: dualProduct.one_month,
            description: dualProduct.one_month_description,
            frequency: 1
          },
          {
            name: '3 meses',
            price: dualProduct.three_month,
            description: dualProduct.three_month_description,
            frequency: 3
          },
          {
            name: '6 meses',
            price: dualProduct.six_month,
            description: dualProduct.six_month_description,
            frequency: 6,
            disabled: true
          }
        ]
      });
    });
  }, []);

  const submitPlanSelection = (plan: any) => {
    setIsLoading(true);
    saveApproach(selectedProducts, plan.frequency)
      .then(() => {
        TagManager.dataLayer({
          dataLayer: {
            mainRecomendation: selectedProducts[0],
            event: 'mainProductRecomendation'
          }
        });
        if (selectedProducts[1]) {
          TagManager.dataLayer({
            dataLayer: {
              secondaryRecomendation: selectedProducts[1],
              event: 'secondaryProductSelection'
            }
          });
        }
        TagManager.dataLayer({
          dataLayer: {
            selectedProducts,
            plan,
            event: 'selectedPlan'
          }
        });
        navigation(`/auth/payment/${plan.price}`);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Grid container className="PlanList" data-testid="PlanList" spacing={2}>
        {(selectedProducts?.length === 1 ? planList.singleProduct : planList.dualProduct).map(
          (plan: any, index: number) => (
            <Grid key={index} item md={4} sm={12} xs={12}>
              <Card key={index} className="plan-item">
                <div>
                  <Typography variant="h4">{plan.name}</Typography>
                  <hr />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: plan.description
                    }}
                  />
                </div>
                <div className="call-to-action">
                  <CurrencyFormat typographyProps={{ variant: 'h4' }} value={plan.price} />
                  <Button disabled={plan.disabled} onClick={() => submitPlanSelection(plan)}>
                    LO QUIERO
                  </Button>
                </div>
              </Card>
            </Grid>
          )
        )}
      </Grid>
      <LoadingScreen loading={isLoading} />
    </div>
  );
};

export default PlanList;
