import { getDiscountCode } from 'adapters/Axios/services/questionnaire.service';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OutlinedInput, Typography } from 'ui/Material';
import Button from 'components/Button';

import './DiscountCode.scss';
interface Props {
  onValidCode: Function;
  inputProps?: any;
}

const DiscountCode: FC<Props> = (props) => {
  const { onValidCode, inputProps } = props;

  const { t }: { t: Function } = useTranslation();

  const [code, setCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event: any) => setCode(event.target.value);

  const validateDiscountCode = () => {
    setIsLoading(true);
    getDiscountCode((code || '').trim())
      .then((response: any) => onValidCode(response.data))
      .catch(() => onValidCode(0))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="DiscountCode" data-testid="DiscountCode">
      <Typography variant="caption">{t('DiscountCode.label')}</Typography>
      <div className="input-wrapper">
        <OutlinedInput
          {...inputProps}
          placeholder={t('DiscountCode.placeholder')}
          onChange={handleInputChange}
        />
        <Button loading={isLoading} variant="text" onClick={validateDiscountCode}>
          {t('DiscountCode.apply')}
        </Button>
      </div>
    </div>
  );
};

export default DiscountCode;
