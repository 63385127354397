import { FC, useEffect, useState } from 'react';
import MultipleOptionSelector from '../MultipleOptionSelector';
import SingleOptionSelector from '../SingleOptionSelector';
import './MultipleOptionWithSubItems.scss';

interface Option {
  id: any;
  text: string;
  sub_questions: any[];
}

interface Props {
  urlIcon?: string;
  title: string;
  defaultValue?: any;
  description?: string;
  options: Array<Option>;
  onSubmit: any;
  numberQuestion: string;
}

const MultipleOptionWithSubItems: FC<Props> = (props) => {
  const [selectedSubAnswers, setSelectedSubAnswers] = useState<Array<any>>([]);

  const [isDisabled, setIsDisabled] = useState(false);

  const [mainSelection, setMainSelection] = useState<Array<any>>([]);

  const mapAnswers = (values: any) => {
    selectedSubAnswers.forEach((subAnswer: any) => {
      const questionIndex = values.findIndex((question: any) =>
        question.sub_questions.find(
          (subQuestion: any) => subQuestion.number_question === subAnswer.number_question
        )
      );
      if (values[questionIndex]) {
        const subAnswers = (values[questionIndex].sub_answer || []).filter(
          (answer: any) => answer.number_question !== subAnswer.number_question
        );

        subAnswers.push(subAnswer);

        values[questionIndex] = {
          ...values[questionIndex],
          sub_answer: subAnswers
        };
      }
    });

    return values;
  };
  const handleOnSubmit = (values: any) => props.onSubmit(mapAnswers(values));

  const validateAnswers = () => {
    const values = mapAnswers(mainSelection);

    setIsDisabled(
      !!values.find(
        (answer: any) =>
          (!answer.sub_answer && answer.sub_questions?.length) ||
          (!!answer.sub_answer && answer.sub_questions?.length !== answer.sub_answer.length)
      )
    );
  };

  const handleOnMainSelection = (values: any) => {
    const filteredSubAnswers = selectedSubAnswers.filter(
      (subAnswer) =>
        !!values.find(
          (question: any) => question.sub_answer?.number_question === subAnswer.number_question
        )
    );

    setSelectedSubAnswers(filteredSubAnswers);
    setMainSelection(values);
  };

  const findSelectedOption = (numberQuestion: any) =>
    selectedSubAnswers.findIndex(
      (selectedOption: any) => selectedOption.number_question === numberQuestion
    );

  const handleSubAnswersSubmit = (numberQuestion: any, option: any, questionIndex: number) => {
    let optionsArray = selectedSubAnswers;
    if (option.excludes) {
      optionsArray = [];
    }
    const indexOfSelectedItem = findSelectedOption(numberQuestion);
    if (indexOfSelectedItem > -1) {
      optionsArray.splice(indexOfSelectedItem, 1);
    }
    optionsArray.push({
      number_question: numberQuestion,
      sub_answer_options: [option],
      questionIndex
    });
    setSelectedSubAnswers(optionsArray);
    validateAnswers();
  };

  useEffect(validateAnswers, [mainSelection, selectedSubAnswers]);

  return (
    <div className="MultipleOptionWithSubItems" data-testid="MultipleOptionWithSubItems">
      <MultipleOptionSelector
        {...props}
        disabled={isDisabled}
        onSubmit={handleOnSubmit}
        onChange={handleOnMainSelection}
        SubItemsComponent={SingleOptionSelector}
        subItemsComponentProps={{
          useRadioButton: true,
          onSubmit: handleSubAnswersSubmit
        }}
      />
    </div>
  );
};

export default MultipleOptionWithSubItems;
