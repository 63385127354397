import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Typography } from 'ui/Material';
import * as routes from 'navigation/routes';
import Button from 'components/Button';
import moment from 'moment';

import './RenewPlan.scss';

export interface RenewPlanProps {
  value: any;
}

const RenewPlan: React.FC<RenewPlanProps> = ({ value }) => {
  const dateFormat = 'DD/MM/YYYY';
  const startDate = value?.dateDelivery;
  const endDate = startDate
    ? moment(startDate, dateFormat).add(value?.frequency, 'months').format(dateFormat)
    : '';
  const isExpired = moment(endDate, dateFormat).isBefore(moment());

  localStorage.setItem('hasExpiredPlan', String(endDate && isExpired));

  return (
    <div className="RenewPlan" data-testid="RenewPlan">
      <Card>
        <Grid container direction="row">
          <Grid item>
            <Typography variant="h5" fontWeight="500">
              Plan de {value?.frequency} meses
            </Typography>
            <Typography variant="body1">
              Del {startDate} al {endDate}
            </Typography>
          </Grid>
          <Grid item>
            <Button disabled={!isExpired}>
              <Link to={`/auth${routes.approachSelection}`}>COMPRAR NUEVAMENTE</Link>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RenewPlan;
