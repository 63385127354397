import BucketImage from 'components/BucketImage';
import { FC } from 'react';
import { Typography } from 'ui/Material';
import Modal from '../Modal';

import './ModalWithIcon.scss';

interface ModalIconProps {
  image?: string;
  title?: string;
  description?: string;
  open: boolean;
  onClose?: any;
}
const ModalWithIcon: FC<ModalIconProps> = (props) => {
  const { image, title, description, open, onClose } = props;

  return (
    <Modal {...props} open={open} onClose={onClose}>
      <div className="ModalWithIcon">
        <BucketImage loading="lazy" alt={title} src={image} />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
    </Modal>
  );
};

export default ModalWithIcon;
