import { FC } from 'react';
import Button from 'components/Button';
import PasswordInput from 'components/PasswordInput';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  OutlinedInput,
  Typography,
  useTheme
} from 'ui/Material';
import { StatusCodeLogin } from 'constants/statusCodeLogin';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { Login as LoginModel } from 'models/auth';
import { AccountStatus } from 'constants/accountStatus';
import './FormLogin.scss';
import * as routes from 'navigation/routes';
import { Link } from 'react-router-dom';
import { formatStrings } from 'util/formatStrings';

interface ErrorsForm {
  email?: FieldError;
  password?: FieldError;
}

interface FormLoginProps {
  isLoading: boolean;
  register: UseFormRegister<LoginModel>;
  handledSubmit: Function;
  errors: ErrorsForm;
  statusResponseFailureLogin: number | null;
  statusResponseSuccessLogin: number | null;
  onRecoverPassword: Function;
}

const FormLogin: FC<FormLoginProps> = (props: FormLoginProps) => {
  const {
    isLoading,
    register,
    handledSubmit,
    errors,
    statusResponseFailureLogin,
    statusResponseSuccessLogin,
    onRecoverPassword
  } = props;

  const { t }: { t: Function } = useTranslation();
  const theme = useTheme();

  return (
    <div className="FormLogin" data-testid="FormLogin">
      <FormGroup>
        <FormControl>
          <OutlinedInput
            type="email"
            error={!!errors.email}
            placeholder={t('Login.form.emailInput.placeholder')}
            {...register('email', {
              required: t(`Login.form.emailInput.errors.required`)
            })}
          />
          <FormHelperText style={{ color: theme.palette.error.main }}>
            {errors.email?.message}
          </FormHelperText>
          {statusResponseFailureLogin === StatusCodeLogin.EMAIL_NOT_EXIST && (
            <Typography className="message-error">
              {t('Login.form.emailInput.errors.emailNotExist')}
            </Typography>
          )}
        </FormControl>
        <FormControl>
          <PasswordInput
            error={!!errors.password}
            placeholder={t('Login.form.passwordInput.placeholder')}
            {...register('password', {
              required: t(`Login.form.passwordInput.errors.required`)
            })}
          />
          <FormHelperText style={{ color: theme.palette.error.main }}>
            {formatStrings(errors.password?.message)}
          </FormHelperText>
          {onRecoverPassword &&
            statusResponseFailureLogin === StatusCodeLogin.INCORRECT_PASSWORD && (
              <Typography className="message-error">
                Tu contraseña no es válida, clic
                <strong onClick={() => onRecoverPassword()}> aquí </strong>
                para recuperarla
              </Typography>
            )}
        </FormControl>
        <Typography className="link-underlined" onClick={() => onRecoverPassword()}>
          {t('Login.form.forgotPassword')}
        </Typography>
        <Button loading={isLoading} size="large" onClick={() => handledSubmit()}>
          {t('continue')}
        </Button>
        {statusResponseSuccessLogin === AccountStatus.CREATED && (
          <Typography>
            <Link className="link-underlined-error" to={routes.assessmentStart}>
              <u>{t('Login.form.actions.errors')}</u>
            </Link>
          </Typography>
        )}
      </FormGroup>
    </div>
  );
};

export default FormLogin;
