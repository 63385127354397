import { useEffect, useMemo, useState } from 'react';
import { ListRegExp } from 'constants/pattern';
import { PasswordValidation } from 'models/user';

const containsUppercaseLetters = (value: string) => ListRegExp.uppercaseLetters.test(value || '');
const containsLowercaseLetters = (value: string) => ListRegExp.lowercaseLetters.test(value || '');
const containsNumbers = (value: string) => ListRegExp.numbers.test(value || '');
const containsPasswordSymbols = (value: string) => ListRegExp.passwordSymbols.test(value || '');

const minLength =
  (min = 8) =>
  (value: string) =>
    value?.length >= min;

const passwordValidationDefault: PasswordValidation = {
  hasMinLength: false,
  containsUppercaseLetters: false,
  containsLowercaseLetters: false,
  containsNumbers: false,
  containsSymbols: false,
  confirmationMatch: false
};

const hasMinLength = minLength(8);

export const usePasswordValidation = (password: string, passwordConfirmation?: string) => {
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidation>(passwordValidationDefault);

  useEffect(() => {
    setPasswordValidation((previousValue) => ({
      ...previousValue,
      hasMinLength: hasMinLength(password),
      containsUppercaseLetters: containsUppercaseLetters(password),
      containsLowercaseLetters: containsLowercaseLetters(password),
      containsNumbers: containsNumbers(password),
      containsSymbols: containsPasswordSymbols(password)
    }));
  }, [password]);

  useEffect(() => {
    setPasswordValidation((previousValue) => ({
      ...previousValue,
      confirmationMatch: !!password && password === passwordConfirmation
    }));
  }, [password, passwordConfirmation]);

  // Password without confirmation
  const isPasswordOk: boolean = useMemo(() => {
    return (
      passwordValidation.hasMinLength &&
      passwordValidation.containsUppercaseLetters &&
      passwordValidation.containsLowercaseLetters &&
      passwordValidation.containsNumbers &&
      passwordValidation.containsSymbols
    );
  }, [passwordValidation]);

  return { passwordValidation, isPasswordOk };
};
