import { FC, useEffect, useState } from 'react';
import './Editor.scss';
import { Editor as OriginalEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

interface Props {
  value: string;
  onChange: any;
}

const Editor: FC<Props> = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const html = value || '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  useEffect(() => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  return (
    <div className="Editor" data-testid="Editor">
      <OriginalEditor editorState={editorState} onEditorStateChange={setEditorState} />
    </div>
  );
};

export default Editor;
