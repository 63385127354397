import { FC } from 'react';
import images from 'assets/images';
import CenteredMessage from 'components/CenteredMessage';
import './EmailSent.scss';
import Session from 'adapters/Session';
import { useTranslation } from 'react-i18next';

interface Props {}

const EmailSent: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();
  return (
    <div data-testid="EmailSent" className="EmailSent">
      <Session>
        {(session: any) => {
          const messageProps = {
            image: images.Mobile,
            title: t('EmailSent.title'),
            description: t('EmailSent.description', session)
          };

          return <CenteredMessage {...messageProps} />;
        }}
      </Session>
    </div>
  );
};

export default EmailSent;
