// eslint-disable-next-line react/prop-types

import React, { useEffect, useState } from 'react';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const porposeHeader = `${REACT_APP_S3_BUCKET_URL}/web/svg/porpose-header.svg`;
import PropTypes from 'prop-types';

import '../index.css';
import { getFuncionalityOption } from '../actions';
import { useTranslation } from 'react-i18next';

function SeparatorHeader({ results = {}, questionResults = {}, questions = {} }) {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [text2, setText2] = useState('');
  const [connector] = useState('');

  useEffect(() => {
    if (questions.P38[questionResults.P38.id_answer_option[0]].id === 285) {
      setText('Nuestro propósito es fortalecer tu funcionalidad ');
      setText2('');
    } else {
      setText('Nuestro propósito es acompañarte en tu objetivo de ');
      setText2(
        `${questions.P38[questionResults.P38.id_answer_option[0]].text} y en tu funcionalidad `
      );
    }
  });

  return (
    <>
      <div className="porpose-container">
        <div>
          <img src={porposeHeader} alt="sabi-icon" className="separator-header-icon" />
        </div>
        <div>
          <div className="separator-header">
            {text}
            <b>
              {text2}
              {connector}
              {t(`HealthMetrics.focusNames.${getFuncionalityOption(results)}`)}
            </b>
          </div>
        </div>
      </div>
    </>
  );
}

SeparatorHeader.propTypes = {
  results: PropTypes.object,
  questionResults: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
};

export default SeparatorHeader;
