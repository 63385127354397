import { sessionService } from 'adapters/Session/services/session.service';
import { AxiosResponse } from 'axios';
import { UserInfoModel } from 'models/user';
import client from './client';

export const validateEmail = (email: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    client
      .get(`accounts/ms/v1/accounts/validate-email/${email}`)
      .then((response: AxiosResponse) => {
        if (response.data.account.id) {
          sessionService.save(response.data.account);
          localStorage.setItem('userId', response.data.account.id);
        }

        resolve(response);
      })
      .catch(reject);
  });
};

export const createAccount = (values: UserInfoModel) => {
  if (!values.purpose) values.purpose = 'NONE';
  return new Promise<AxiosResponse>((resolve, reject) => {
    client
      .post(`accounts/ms/v1/accounts`, values)
      .then((response: AxiosResponse) => {
        sessionService.save(values);

        resolve(response);
      })
      .catch(reject);
  });
};

export const requestActivationCode = (password: string, newEmail: string) =>
  client.post(`accounts/ms/v1/accounts/survey-completed`, {
    idAccount: localStorage.getItem('userId'),
    password,
    newEmail: newEmail || null
  });

export const activateAccount = (activationCode: string) =>
  client.get(`accounts/ms/v1/accounts/confirmation-email/${activationCode}`);

export const getRecommendations = () =>
  client.get(`recommendations/ms/v1/recommendations/${localStorage.getItem('userId')}`);
