import Session from 'adapters/Session';
import { FC } from 'react';
import { Typography } from 'ui/Material';
import images from 'assets/images';
import { useTranslation } from 'react-i18next';
import './ProductPack.scss';
import BucketImage from 'components/BucketImage';

interface Props {
  mainProduct: any;
  secondaryProduct?: any;
  nameRandom?: string;
}

const ProductPack: FC<Props> = ({ mainProduct, secondaryProduct, nameRandom = 'David' }) => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="ProductPack" data-testid="ProductPack">
      <BucketImage loading="lazy" alt="product packing" src={images.LyfPacking} />
      <div className="customized-wrapper">
        <Session>
          {(session: any) => (
            <Typography variant="body1">
              <b>
                {t(
                  'ProductPacking.hiName',
                  session?.firstName ? session : { firstName: nameRandom }
                )}
              </b>
            </Typography>
          )}
        </Session>
        <Typography className="madeForYou" variant="caption">
          {t('ProductPacking.madeForYou')}
        </Typography>
        <div className="recomendations">
          <Typography variant="caption">{mainProduct}</Typography>
          {secondaryProduct && <Typography variant="caption">{secondaryProduct}</Typography>}
        </div>
      </div>
    </div>
  );
};

export default ProductPack;
