/* Configuration of axios */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { localAuthService } from 'adapters/Session/services/localAuth.service';

const requestInterceptor = (request: AxiosRequestConfig) => {
  const ACCESS_TOKEN = localAuthService.getAccessToken();
  const TOKEN_TYPE = localAuthService.getTokenType();
  if (ACCESS_TOKEN && TOKEN_TYPE) {
    request.headers = {
      ...request?.headers,
      Authorization: `${TOKEN_TYPE} ${ACCESS_TOKEN}`
    };
  }
  return request;
};

const responseInterceptor = (response: AxiosResponse) => response;

const responseErrorInterceptor = async (error: AxiosError) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    localAuthService.logout();
    location.reload();
  }
  toast.error(error?.response?.data?.message || error.message, {
    position: 'bottom-right',
    autoClose: 3000,
    draggable: false
  });
  return Promise.reject(error);
};

const requestErrorInterceptor = (error: AxiosError) => Promise.reject(error);

const axiosClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  api.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  api.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

  return api;
};

export default axiosClient();
