import { Plan } from 'models/profile';
import React from 'react';
import { Grid, Typography } from 'ui/Material';
import PlanApproach from '../PlanApproach';
import { ResultTest } from '../../../../models/profile';
import CustomerService from '../CustomerService';
import TagManager from 'adapters/TagManager';
import './ProfilePanel.scss';
import Recommendations from '../Recommendations';
import { Sticky, StickyContainer } from 'react-sticky';
import GetPlan from '../GetPlan';

export interface ProfilePanelProps {
  plan?: Plan;
  result?: ResultTest;
}

const ProfilePanel: React.FC<ProfilePanelProps> = ({ plan, result }: ProfilePanelProps) => {
  return (
    <div className={`ProfilePanel ${!!plan?.id_order && 'hasPlan'}`} data-testid="ProfilePanel">
      <TagManager />
      <StickyContainer>
        <Grid container columnSpacing={6} rowSpacing={2}>
          <Grid item xs={12} md={9}>
            {plan?.expired_at && (
              <div className="plan-end">
                <Typography variant="body2" fontWeight={500}>
                  Tu plan termina el {plan.expired_at}
                </Typography>
              </div>
            )}
            <PlanApproach
              challenge={result?.purpose}
              planFocus={result?.score_life_style}
              planFocusProductList={plan?.products}
              imc={result?.imc}
              physicalActivity={result?.physical_activity}
              water={result?.water}
              calories={result?.calories}
            />
            {!!plan?.id_order && <Recommendations />}
            {!plan?.id_order && <GetPlan />}
          </Grid>
          <Grid item xs={12} md={3} className="sticky-wrapper">
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <CustomerService />
                </div>
              )}
            </Sticky>
          </Grid>
        </Grid>
      </StickyContainer>
    </div>
  );
};

export default ProfilePanel;
