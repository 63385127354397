import storage from './storage.service';
import { AccountAuth } from '../../../models/auth';

class LocalAuthService {
  accountAuth: AccountAuth | null;
  storage;

  constructor() {
    this.accountAuth = null;
    this.storage = storage;
  }

  setAuth(accountAuth: AccountAuth): void {
    this.accountAuth = accountAuth;
    this.storage.set('account-auth', this.accountAuth);
  }

  logout(): void {
    this.storage.remove('account-auth');
  }

  getAuth(): AccountAuth | null {
    const accountAuth = Object(this.storage.get('account-auth')) as AccountAuth;
    if (Object.keys(accountAuth).length) {
      return accountAuth;
    }
    return null;
  }

  getAccessToken(): string | null {
    const auth = this.getAuth();
    if (auth) {
      return auth.accessToken;
    }
    return null;
  }

  getTokenType(): string | null {
    const auth = this.getAuth();
    if (auth) {
      return auth.tokenType;
    }
    return null;
  }

  getRefreshToken(): string | null {
    const auth = this.getAuth();
    if (auth) {
      return auth.refreshToken;
    }
    return null;
  }

  getIdAccount(): string {
    const auth = this.getAuth();
    if (auth) {
      return auth.idAccount;
    }
    return '';
  }

  getAccountStatus(): number | null {
    const auth = this.getAuth();
    if (auth) {
      return auth.status;
    }
    return null;
  }
}

export const localAuthService = new LocalAuthService();
