import React from 'react';
import PropTypes from 'prop-types';

import './alert.css';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const campana = `${REACT_APP_S3_BUCKET_URL}/circle/img/campana.png`;

export function AlertLeft({ data, labels }) {
  return (
    <div className="container_alert_i">
      <div className="text_status_izquierda_alert"> ¡Atención!</div>
      {/* <div className="img_check_izquierda"> */}
      <img className="status" src={campana} alt="status" />
      {/* </div> */}
    </div>
  );
}

AlertLeft.propTypes = {
  data: PropTypes.any,
  labels: PropTypes.any
};
