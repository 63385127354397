import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from 'navigation/PrivateRoutes/PrivateRoutes';
import * as routes from 'navigation/routes';
import Login from 'containers/Admin/Login';
import Dashboard from 'containers/Admin/Dashboard';
import AdminLayout from 'containers/AdminLayout';

const AdminSubStack = () => (
  <PrivateRoutes>
    <Routes>
      <Route path={routes.dashboard} element={<Dashboard />} />
    </Routes>
  </PrivateRoutes>
);

const AdminStack = () => (
  <AdminLayout>
    <Routes>
      <Route path={routes.index} element={<Login />} />
      <Route path={`${routes.index}*`} element={<AdminSubStack />} />
    </Routes>
  </AdminLayout>
);

export default AdminStack;
