import { getRecommendations } from 'adapters/Axios/services/account.service';
import images from 'assets/images';
import BucketImage from 'components/BucketImage';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from 'ui/Material';
import { ExpandMore } from 'ui/Material/icons';

import './Recommendations.scss';

export interface RecommendationsProps {}

const Recommendations: React.FC<RecommendationsProps> = () => {
  const [recommendations, setRecommendations] = useState<any>([]);

  useEffect(() => {
    getRecommendations().then((response) => setRecommendations(response.data));
  }, []);

  const gridProps = {
    item: true,
    xs: 6,
    md: 6,
    display: 'flex'
  };

  return (
    <div className="Recommendations" data-testid="Recommendations">
      <Typography variant="h2">Recomendaciones para ti</Typography>
      <Typography variant="h3">
        Queremos acompañarte a cumplir tus objetivos, aquí te dejamos unos tips para lograrlo
      </Typography>
      {recommendations.map((item: any, index: any) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{item.category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              {item.recommendations.map((recommendation: string, recommendationIndex: any) => (
                <Grid key={recommendationIndex} {...gridProps} flexDirection="row">
                  <div>
                    <BucketImage loading="lazy" alt="icon" src={images.BlueCheckIcon} />
                  </div>
                  <Typography variant="body1">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: recommendation
                      }}
                    />
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Recommendations;
