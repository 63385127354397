import { FC } from 'react';
import images from 'assets/images';
import './ImageCover.scss';
import BucketImage from 'components/BucketImage';

interface Props {
  children: any;
  image: string;
  TopComponent?: any;
  topComponentProps?: any;
}

const ImageCover: FC<Props> = ({ children, image, TopComponent, topComponentProps }) => {
  const imageProps = {
    alt: 'background',
    className: 'background-decoration'
  };

  return (
    <div className="ImageCover" data-testid="ImageCover">
      <div className="image-wrapper">
        {TopComponent && <TopComponent {...topComponentProps} />}
        {image && <BucketImage loading="lazy" alt="cover" src={image} />}
        <BucketImage {...imageProps} src={images.BackgroundGreenTop} />
      </div>
      {children}
    </div>
  );
};

export default ImageCover;
