import { FC } from 'react';
import { Carousel as OriginalCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IconButton } from 'ui/Material';
import { ArrowForwardIos, ArrowBackIos } from 'ui/Material/icons';
import './Carousel.scss';

const Carousel: FC<any> = ({ children, ...otherProps }) => {
  const carouselProps = {
    renderArrowNext: (click: any) => (
      <IconButton onClick={click}>
        <ArrowForwardIos />
        Siguiente
      </IconButton>
    ),
    renderArrowPrev: (click: any) => (
      <IconButton onClick={click}>
        <ArrowBackIos />
        Anterior
      </IconButton>
    )
  };

  return (
    <div className="Carousel" data-testid="Carousel">
      <OriginalCarousel {...carouselProps} {...otherProps}>
        {children}
      </OriginalCarousel>
    </div>
  );
};

export default Carousel;
