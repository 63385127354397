import { FC, useState } from 'react';
import { QuestionItem } from 'models/questionnaire';
import MultipleImageOptionSelector from 'components/Assessment/MultipleImageOptionSelector';
import MultipleOptionWithMinuteCounter from 'components/Assessment/MultipleOptionWithMinuteCounter';
import MultipleOptionSelector from 'components/Assessment/MultipleOptionSelector';
import MultipleOptionWithSubItems from 'components/Assessment/MultipleOptionWithSubItems';
import SingleImageOptionSelector from 'components/Assessment/SingleImageOptionSelector';
import SingleOptionSelector from 'components/Assessment/SingleOptionSelector';
import ClosedQuestion from 'components/Assessment/ClosedQuestion';
import OpenQuestion from 'components/Assessment/OpenQuestion';
import MultipleImageSingleOptionSelector from 'components/Assessment/MultipleImageSingleOptionSelector';
import { saveAnswer } from 'adapters/Axios/services/questionnaire.service';
import MultipleOptionSingleSelection from 'components/Assessment/MultipleOptionSingleSelection';
import { CircularProgress } from 'ui/Material';
import { useTranslation } from 'react-i18next';
import Session from 'adapters/Session';
import TagManager from 'adapters/TagManager';
import './QuestionWrapper.scss';
import SingleOptionSelectorColumn from '../SingleOptionSelectorColumns/SingleOptionSelectorColumn';

const QuestionWrapper: FC<any> = (props) => {
  const { t }: { t: Function } = useTranslation();

  const { onSubmit, defaultValue } = props;

  const [isLoading, setIsLoading] = useState(false);

  const questionTypeComponents: any = {
    'seleccion-multiple-horizontal': ClosedQuestion,
    'seleccion-multiple-imagen-horizontal': SingleImageOptionSelector,
    'seleccion-multiple-horizontal-column': SingleOptionSelectorColumn,
    'seleccion-multiple-vertical': SingleOptionSelector,
    'seleccion-multiple-respuesta-unica-vertical': MultipleOptionSingleSelection,
    'seleccion-multiple-respuesta-multiple-imagen-matriz': MultipleImageOptionSelector,
    'seleccion-multiple-respuesta-unica-imagen-matriz': MultipleImageSingleOptionSelector,
    'seleccion-multiple-respuesta-multiple-vertical-box': MultipleOptionWithSubItems,
    'seleccion-multiple-respuesta-multiple-vertical-box-internal-button': MultipleOptionSelector,
    'seleccion-multiple-respuesta-multiple-imagen-matriz-box': MultipleOptionWithMinuteCounter,
    'seleccion-multiple-respuesta-multiple-vertical-box-dos-subpreguntas': MultipleOptionSelector,
    abierta: OpenQuestion
  };

  const questionMapper: any = (item: QuestionItem) => ({
    options: item.answer_options,
    numberQuestion: item.number_question,
    questionType: item.question_type,
    urlIcon: item.url_icon,
    daughterQuestions: (item.daughter_questions || []).map(questionMapper),
    baseUnit: item.unit_of_measurement,
    ...item
  });

  const mappedProps = questionMapper(props);

  const handleOnSubmit = (value: any) => {
    setIsLoading(true);
    saveAnswer(mappedProps.numberQuestion, Array.isArray(value) ? value : [value])
      .then(() => {
        onSubmit(props, value);
      })
      .finally(() => setIsLoading(false));
  };

  const Component =
    questionTypeComponents[String(mappedProps.questionType?.name).toLocaleLowerCase()];

  return (
    <div className={`QuestionWrapper ${isLoading && 'is-loading'}`} data-testid="QuestionWrapper">
      <TagManager question={mappedProps} />
      <CircularProgress />
      <Session>
        {(session: any) =>
          Component && (
            <Component
              {...mappedProps}
              title={t(mappedProps.title, session)}
              onSubmit={handleOnSubmit}
              defaultValue={defaultValue}
            />
          )
        }
      </Session>
    </div>
  );
};

export default QuestionWrapper;
