import storage from './storage.service';

class SessionService {
  session: any;
  storage;

  constructor() {
    this.session = {};
    this.storage = storage;
  }

  save(data: any) {
    this.session = data;
    this.storage.set('session', data);
  }

  get() {
    const data = this.storage.get('session');

    if (data) {
      this.save(data);
    }
    return data;
  }

  clear() {
    this.session = {};
    this.storage.remove('session');
  }
}

export const sessionService = new SessionService();
