import { Route, Routes } from 'react-router-dom';
import * as routes from 'navigation/routes';
import Layout from 'containers/Layout';
import ApproachSelection from 'containers/ApproachSelection';
import PaymentForm from 'containers/PaymentForm';
import PrivateRoutes from 'navigation/PrivateRoutes/PrivateRoutes';
import MainProfile from 'containers/MainProfile';

const AuthStack = () => (
  <PrivateRoutes>
    <Layout>
      <Routes>
        <Route path={routes.approachSelection} element={<ApproachSelection />} />
        <Route path={routes.payment} element={<PaymentForm />} />
        <Route path={routes.mainProfile} element={<MainProfile />} />
        <Route path={routes.orders} element={<MainProfile />} />
      </Routes>
    </Layout>
  </PrivateRoutes>
);

export default AuthStack;
