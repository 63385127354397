import { useTranslation } from 'react-i18next';
import { BiUser } from 'react-icons/bi';
import { Menu, MenuItem, Typography } from 'ui/Material';
import images from 'assets/images';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Session from 'adapters/Session';
import { Menu as MenuIcon } from 'ui/Material/icons';
import React, { useState } from 'react';
import * as routes from 'navigation/routes';
import options from './options';
import Button from 'components/Button';
import { localAuthService } from 'adapters/Session/services/localAuth.service';
import { sessionService } from 'adapters/Session/services/session.service';

import './Header.scss';
import BucketImage from 'components/BucketImage';

const { REACT_APP_S3_BUCKET_URL } = process.env;

const logoBenet = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/logo_benet_purple.png`;

const Header = () => {
  const navigate = useNavigate();
  const { t }: { t: Function } = useTranslation();

  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = !!anchorEl;

  const handleToggleMenu = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sessionButtonProps = {
    id: 'session-button',
    'aria-controls': isOpen ? 'basic-menu' : undefined,
    'aria-haspopup': true,
    'aria-expanded': isOpen,
    onClick: handleClick,
    className: 'session-icon active'
  };

  const menuProps = {
    anchorEl: anchorEl,
    open: isOpen,
    onClose: handleClose,
    MenuListProps: {
      'aria-labelledby': 'session-button'
    }
  };

  const handleSignOut = () => {
    localAuthService.logout();
    sessionService.clear();
    localStorage.removeItem('userId');
    localStorage.removeItem('from');
    navigate('/');
  };

  const profileRoute = routes.usePrivatePrefix(routes.mainProfile);
  const pathLocation = location.pathname;
  const [sw, setSw] = useState(true);
  const [showIcons, setShowIcons] = useState(false);

  const url = new URL(window.location.href);
  // Almacenar el valor de "from" en el localStorage
  if (pathLocation === '/assessment/0' && sw) {
    setSw(false);
    localStorage.setItem('from', url.searchParams.get('from') || 'default');
    const show = localStorage.getItem('from') === 'benet';
    setShowIcons(show);
  }

  return (
    <>
      {!location.pathname.includes('new-results') ? (
        <nav className={`Header ${isModalOpen && 'menu-open'}`} data-testid="Header">
          <div className="items-container">
            <MenuIcon data-testid="MenuIcon" className="menu-icon" onClick={handleToggleMenu} />
            {showIcons ? (
              <a href={routes.benetUrl} rel="noopener noreferrer" className="logo-wrapper">
                <BucketImage width="127" height="74" alt="logo" src={logoBenet} />
              </a>
            ) : (
              <Link to={routes.index} className="logo-wrapper">
                {process.env.REACT_APP_IS_VIDARIUM === 'true' ? (
                  <></>
                ) : (
                  <BucketImage width="127" height="74" alt="logo" src={images.LyfLogo} />
                )}
              </Link>
            )}
            <div className="options">
              {options.map((option: any, index: any) => (
                <span
                  key={index}
                  className={`${location.pathname.includes(option.path) && 'active'}`}
                >
                  <Link to={option.path} onClick={handleToggleMenu}>
                    {t(option.label)}
                  </Link>
                </span>
              ))}
              <Button variant="contained">
                <Link to={routes.assessmentStart} onClick={handleToggleMenu}>
                  HACER TEST
                </Link>
              </Button>
            </div>

            {showIcons ? (
              <></>
            ) : (
              <Session>
                {(session: any) =>
                  session.firstName ? (
                    <>
                      <Button variant="text" {...sessionButtonProps}>
                        <Typography variant="body1">{t('Header.hiName', session)}</Typography>
                        <BiUser className="icon" />
                      </Button>
                      <Menu {...menuProps} data-testid="Menu" className="session-menu">
                        <MenuItem>
                          <Link onClick={handleToggleMenu} to={profileRoute}>
                            Perfil
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <Link to={routes.signIn} className="session-icon">
                      <BiUser className="icon" />
                      Iniciar sesión
                    </Link>
                  )
                }
              </Session>
            )}
          </div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
