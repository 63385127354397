import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './div-info.css';
export function DivInfo({ data }) {
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    setShowDiv(data[1] !== '');
  }, [data]);

  return (
    <div className="cprincipal">
      <div className="box_info box_info_1" dangerouslySetInnerHTML={{ __html: data[0] }}></div>
      {showDiv && (
        <div
          className="box_info box_info_2"
          style={{ width: '12rem auto' }}
          dangerouslySetInnerHTML={{ __html: data[1] }}
        ></div>
      )}
    </div>
  );
}

DivInfo.propTypes = {
  data: PropTypes.any
};
