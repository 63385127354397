import React from 'react';
import PropTypes from 'prop-types';

import '../index.css';
import MetricCard from './HealthMetrics';
import { useTranslation } from 'react-i18next';
import { DescriptionMetricCard } from './HealthMetrics/MetricCard';

function Card({ results = {}, questionResults = {}, questions = {} }) {
  const { t } = useTranslation();

  function getPhysicalActivityPoints(activity) {
    if (activity >= 150) return 'excellent';
    if (activity >= 100 && activity <= 149) return 'good';
    return 'bad';
  }

  function getgender(activity) {
    if (activity == 'Hombre') return 'Hombre';
    return 'Mujer';
  }

  function getWater(value) {
    if (value === 'Ninguno') return value;
    return value.split('Logro tomar ')[1].split('vasos')[0];
  }

  return (
    <div className="container-cards">
      <div className="card-principal-container">
        <MetricCard
          bgColor="card-color-1"
          metric="Peso"
          value={t('HealthMetrics.weightSuffix', {
            weight: Number(questionResults.P1.text)
          })}
        />

        <div className="card-container card-color-2">
          <MetricCard
            bgColor=""
            metric={t('MainProfile.ProfilePanel.PlanApproach.newBodyMass')}
            value={t('HealthMetrics.imcSuffix', {
              imc: Number(results.imc)
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: ''
              }}
            />
          </div>

          <div className="description-info">
            {results.imc > 24.9 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('HealthMetrics.overweightAdvice')
                }}
              />
            )}
            {results.imc >= 18.5 && results.imc <= 24.9 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('HealthMetrics.normalWeight')
                }}
              />
            )}
            {results.imc < 18.5 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('HealthMetrics.lowWeightAdvice')
                }}
              />
            )}
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.noteWeight')
              }}
            />
          </div>
        </div>
        <div className="card-container card-color-3">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.waistCircumference.title')}
            isVisible={false}
            subtitle={t('HealthMetrics.waistCircumference.subtitle')}
            value={t('HealthMetrics.waistCircumference.unitSuffix', {
              waist: questions.P6[questionResults.P6.id_answer_option[0]].text
            })}
          />
          <div className="card-value-subtitle visibilityValue">
            <span /> {questions.P6[questionResults.P6.id_answer_option[0]].text}
          </div>

          <DescriptionMetricCard
            title={t('HealthMetrics.waistCircumference.risk.title')}
            bars={t(
              `HealthMetrics.waistCircumference.risk.bars.${getgender(
                questions.P3[questionResults.P3.id_answer_option[0]].text
              )}`,
              {
                returnObjects: true
              }
            )}
          />
        </div>
      </div>
      {/* row 2 */}
      <div className="card-principal-container">
        <div className="card-container card-color-4">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.bodyFatPercentage.title')}
            isVisible={false}
            value={t('HealthMetrics.bodyFatPercentage.unitSuffix', {
              percentage: questions.P5[questionResults.P5.id_answer_option[0]].text
            })}
          />

          <div className="card-value-subtitle visibilityValue">
            <span /> {questions.P5[questionResults.P5.id_answer_option[0]].text}
          </div>

          <DescriptionMetricCard
            title={t('HealthMetrics.bodyFatPercentage.classificationTitle')}
            bars={t(
              `HealthMetrics.bodyFatPercentage.classificationList.${
                questions.P3[questionResults.P3.id_answer_option[0]].text
              }`,
              {
                returnObjects: true
              }
            )}
          />
        </div>
        <div className="card-container card-color-2">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.exerciseRecommendation.title')}
            value={t('HealthMetrics.exerciseRecommendation.unitSuffix', {
              exercise: results.physical_activity.executed_minutes_per_week
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.exerciseRecommendation.secondTitle')
              }}
            />
          </div>

          <div className="description-info-v2">
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  `HealthMetrics.exerciseRecommendation.recommendation.${getPhysicalActivityPoints(
                    results.physical_activity.executed_minutes_per_week
                  )}`
                )
              }}
            />
          </div>
        </div>

        <div className="card-container card-color-5">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.hydrationRecommendation.title')}
            value={t('HealthMetrics.hydrationRecommendation.unitSuffix', {
              water: getWater(questions.P17[questionResults.P17.id_answer_option[0]].text)
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.hydrationRecommendation.secondTitle')
              }}
            />
          </div>

          <div className="description-info-v3">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.hydrationRecommendation.recommendation', {
                  water: Number(results.water)
                })
              }}
            />
          </div>
        </div>
      </div>

      {/* row 3 */}
      <div className="card-principal-container">
        <div className="card-container card-color-6">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.energyRequirement.title')}
            value={t('HealthMetrics.energyRequirement.unitSuffix', {
              calories: Number(results.calories)
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.energyRequirement.secondTitle')
              }}
            />
          </div>

          <div className="description-info-calorias">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.energyRequirement.recommendation')
              }}
            />
          </div>
        </div>

        <div className="card-container card-color-frutas">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.fruitRecommendation.title')}
            value={t('HealthMetrics.fruitRecommendation.unitSuffix', {
              fruits:
                questions.P10[questionResults.P10.id_answer_option[0]].text.split('porciones')[0]
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.fruitRecommendation.secondTitle')
              }}
            />
          </div>

          <div className="description-info-frutas">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.fruitRecommendation.recommendation', {
                  quantity: 3
                })
              }}
            />
          </div>
        </div>

        <div className="card-container card-color-verduras">
          <MetricCard
            bgColor=""
            metric={t('HealthMetrics.vegetableRecommendation.title')}
            value={t('HealthMetrics.vegetableRecommendation.unitSuffix', {
              vegetables:
                questions.P11[questionResults.P11.id_answer_option[0]].text.split('porciones')[0]
            })}
          />

          <div className="card-value-subtitle">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.vegetableRecommendation.secondTitle')
              }}
            />
          </div>

          <div className="description-info-verduras">
            <span
              dangerouslySetInnerHTML={{
                __html: t('HealthMetrics.vegetableRecommendation.recommendation', {
                  quantity: 2
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  results: PropTypes.object.isRequired,
  questionResults: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
};

export default Card;
