import React from 'react';
import PropTypes from 'prop-types';

import './check.css';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const check = `${REACT_APP_S3_BUCKET_URL}/circle/img/check.png`;

export function CheckRight({ data, labels }) {
  return (
    <div className="container_check_d">
      {/* <div className="img_check_derecha"> */}
      <img className="status" src={check} alt="status" />
      {/* </div> */}
      <div className="text_status_derecha_ok"> ¡Muy bien!</div>
    </div>
  );
}

CheckRight.propTypes = {
  data: PropTypes.any,
  labels: PropTypes.any
};
