import { FC } from 'react';
import { Trans } from 'react-i18next';
import { FormControl, FormControlLabel, Checkbox, CheckboxProps } from 'ui/Material';

import './UrlCheckbox.scss';

interface Props {
  checkboxProps?: CheckboxProps;
  translateKey?: string;
  children?: any;
}

const UrlCheckbox: FC<Props> = ({ checkboxProps, translateKey, children }) => (
  <FormControl className="UrlCheckbox" data-testid="UrlCheckbox">
    <FormControlLabel
      control={<Checkbox {...checkboxProps} />}
      label={<Trans key={translateKey}>{children}</Trans>}
    />
  </FormControl>
);

export default UrlCheckbox;
