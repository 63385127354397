import React from 'react';
import PropTypes from 'prop-types';
import './circulo.css';
// imagenes
const { REACT_APP_S3_BUCKET_URL } = process.env;
const energia = `${REACT_APP_S3_BUCKET_URL}/circle/img/energia.png`;
const aptitud = `${REACT_APP_S3_BUCKET_URL}/circle/img/aptitud-fisica.png`;
const inmune = `${REACT_APP_S3_BUCKET_URL}/circle/img/inmune.png`;
const humano = `${REACT_APP_S3_BUCKET_URL}/circle/img/humano.png`;
const estilo = `${REACT_APP_S3_BUCKET_URL}/circle/img/estilo-de-vida.png`;
const dish = `${REACT_APP_S3_BUCKET_URL}/circle/img/dish.png`;
const digestivo = `${REACT_APP_S3_BUCKET_URL}/circle/img/digestivo.png`;

// componentes check y alert
import { CheckRight } from './checkRight/checkRight';
import { AlertRight } from './alertRight/alertRight';
import { CheckLeft } from './checkLeft/checkLeft';
import { AlertLeft } from './alertLeft/alertLeft';

export function Circle({ results }) {
  return (
    <div id="circle-results-items">
      <div className="principal">
        <div className="cricle-container">
          <div className="horizontal superior">
            <div className="circle-horizonral">
              <div className="text_item">
                <img className="item" src={energia} alt="Energía" />
                <div>Energía</div>
              </div>
            </div>
            {results.energy.score >= 20 ? <CheckRight /> : <AlertRight />}
          </div>
          <div className="lateral izquierda">
            <div className="subdiv">
              {results.cardiovascular.score >= 12.6 ? <CheckLeft /> : <AlertLeft />}

              <div className="circle-vertical">
                <div className="text_item">
                  <img className="item" src={aptitud} alt="Cardiovascular" />
                  <div>Cardiovascular</div>
                </div>
              </div>
            </div>
            <div className="subdiv">
              {/* <div className="text_status_izquierda_alert"> ¡Atención!</div> */}

              {results.digestive_health.score >= 21.5 ? <CheckLeft /> : <AlertLeft />}
              <div className="circle-vertical">
                <div className="text_item">
                  <img className="item" src={digestivo} alt="Digestivo" />
                  <div>Digestivo</div>
                </div>
              </div>
            </div>
          </div>
          <div className="central">
            <img className="imghuman" src={humano} alt="persona" />
          </div>
          <div className="lateral derecha">
            <div className="subdiv">
              <div className="circle-vertical">
                <div className="text_item">
                  <img className="item" src={estilo} alt="Estilo de vida" />
                  <div>Estilo de vida saludable</div>
                </div>
              </div>
              {results.life_style.score >= 32 ? <CheckRight /> : <AlertRight />}
            </div>
            <div className="subdiv">
              <div className="circle-vertical">
                <div className="text_item">
                  <img className="item" src={dish} alt="Alimentación saludable" />
                  <div>Alimentación saludable</div>
                </div>
              </div>
              {results.nutritional_supplement.score >= 123 ? <CheckRight /> : <AlertRight />}
            </div>
          </div>
          <div className="horizontal inferior">
            <div className="circle-horizonral">
              <div className="text_item">
                <img className="item" src={inmune} alt="Inmunidad" />
                <div>Inmunidad</div>
              </div>
            </div>
            {results.immunity.score >= 13.5 ? <CheckRight /> : <AlertRight />}
          </div>
        </div>
      </div>
    </div>
  );
}

Circle.propTypes = {
  results: PropTypes.any.isRequired
};
