import React, { useEffect, useState, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import './index.css';
import './indexv2.css';
import Header from './components/Header';
import SeparatorHeader from './components/SeparatorHeader';
import Card from './components/Cards';
import { Circle } from './components/circulo/Circulo';
import SeparatorResults from './components/SeparatorResults';
import { Carrusel } from './components/carrusel/Carrusel';
import RecommendationCardsList from './components/recommendations';
import {
  getAllProducts,
  getAnswers,
  getQuestionnaire,
  getQuestionnaireResults
} from 'adapters/Axios/services/questionnaire.service';
import TagManager from 'react-gtm-module';
import InProgress from 'containers/Results/components/InProgress';
import { DownloadPdf } from './components/DownloadPdf/DownloadPdf';
import FloatingButton from './components/FloatingButton/FloatingButton';

function NewResumen() {
  const [isReady, setIsReady] = useState(false);
  const [results, setResults] = useState();
  const [questionResults, setQuestionResults] = useState();
  const [questions, setQuestions] = useState();
  const [carrusel, setCarrusel] = useState();

  const componentRef = useRef(null);

  const pdfName = 'Resultados_Benet.pdf';

  const generatePDF = (sendEmail = true) => {
    document.querySelector('.flex_action').classList.add('hidden-in-pdf');
    const element = componentRef.current;
    const opt = {
      margin: 10,
      filename: pdfName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: [380, 1000], orientation: 'portrait' }
    };

    return html2pdf()
      .set(opt)
      .from(element)
      .outputPdf('blob')
      .then((pdfBlob) => {
        const pdfFile = new File([pdfBlob], pdfName, { type: 'application/pdf' });
        document.querySelector('.flex_action').classList.remove('hidden-in-pdf');
        return pdfFile;
      });
  };

  const downloadPDF = () => {
    generatePDF(false).then((pdfFile) => {
      const url = URL.createObjectURL(pdfFile);
      const link = document.createElement('a');
      link.href = url;
      link.download = pdfName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };

  const formatResponseByNumberOfQuestion = (data) => {
    const formattedData = {};
    data.map((x) => (formattedData[x.number_question] = x));
    return formattedData;
  };

  const formatResponseLikeHashMap = ({ data, key }) => {
    const formattedData = {};
    data.map((x) => (formattedData[x[key]] = x));
    return formattedData;
  };

  const formatQuestionsByPoption = (data) => {
    const formattedData = {};
    data.categories.map((x) =>
      x.questions.map(
        (y) =>
          (formattedData[y.number_question] = formatResponseLikeHashMap({
            data: y.answer_options,
            key: 'id'
          }))
      )
    );
    return formattedData;
  };

  const formatProducts = (data) => {
    const formattedData = {};
    data.map((x) => (formattedData[x.name] = x));
    return formattedData;
  };

  useEffect(() => {
    setIsReady(false);
    Promise.all([getQuestionnaireResults(), getAnswers(), getAllProducts(), getQuestionnaire()])
      .then(([resultsRes, answersRes, productsRes, questionnaireRes]) => {
        setResults(resultsRes.data);
        if (resultsRes.data.imc > 24.9) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'overweight',
              healthResults: resultsRes.data
            }
          });
        }
        setQuestionResults(formatResponseByNumberOfQuestion(answersRes.data));
        setCarrusel(formatProducts(productsRes.data));
        setQuestions(formatQuestionsByPoption(questionnaireRes.data));
        setIsReady(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsReady(true);
      });
  }, []);

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        generatePDF(true);
      }, 3000);
    }
  }, [isReady]);

  if (!isReady) {
    return <InProgress />;
  }

  return (
    <div className="div-resumen-color" ref={componentRef}>
      <Header results={results} />
      <div style={{ textAlign: 'justify', fontSize: '1.3rem' }}>
        <SeparatorResults
          font="'Omnesb', sans-serif"
          message="Estos resultados provienen de aplicar un algoritmo desarrollado por investigadores expertos en nutrición, salud y bienestar, y dan cuenta de tu comportamiento alimentario y estilo de vida. El cuestionario, las clasificaciones y las recomendaciones están sustentados en evidencia científica y directrices emitidas por entidades internacionales como la OMS (Organización Mundial de la Salud) y SEEDO (Sociedad Española para el Estudio de la Obesidad)"
        ></SeparatorResults>
      </div>
      <SeparatorHeader results={results} questionResults={questionResults} questions={questions} />
      <Card results={results} questionResults={questionResults} questions={questions} />

      <div className="circle-results-items">
        <SeparatorResults message="<b>A continuación encontrarás el estado en que se encuentran las siguientes funciones:</b>"></SeparatorResults>
        <br />
        <br />
        <Circle results={results}></Circle>
      </div>

      <SeparatorResults message="<b>Algunas recomendaciones personalizadas para ti:</b>"></SeparatorResults>
      <RecommendationCardsList
        results={results}
        questionResults={questionResults}
        questions={questions}
      />

      {process.env.REACT_APP_IS_VIDARIUM === 'true' ? (
        <></>
      ) : (
        <>
          <SeparatorResults message="<b>Ahora que te conocemos mejor, estos son los productos recomendados para ti:</b>"></SeparatorResults>
          <Carrusel
            results={results}
            questionResults={questionResults}
            questions={questions}
            data={carrusel}
          ></Carrusel>
        </>
      )}

      <DownloadPdf downloadPdf={downloadPDF} />
      <FloatingButton />
    </div>
  );
}

export default NewResumen;
