import { AxiosResponse } from 'adapters/Axios/models/axiosModels';
import { getCheckoutInfo } from 'adapters/Axios/services/orders.service';
import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { ButtonProps } from 'ui/Material';

import './WompiCheckout.scss';

export interface WompiCheckoutProps {
  buttonProps?: ButtonProps;
  children: any;
  onSubmit: any;
  idAccount?: string;
  idOrder?: number;
}

const WompiCheckout: React.FC<WompiCheckoutProps> = ({
  children,
  buttonProps,
  onSubmit,
  idAccount,
  idOrder
}) => {
  const [checkout, setCheckout] = useState<any>();
  const handleOnSubmit = () => {
    getCheckoutInfo(idAccount, idOrder).then((checkoutResponse: AxiosResponse) => {
      setCheckout(checkoutResponse.data);
    });
  };

  useEffect(() => {
    if (idAccount && idOrder) {
      handleOnSubmit();
    }
  }, [idAccount, idOrder]);

  useEffect(() => {
    if (checkout) {
      const wompiButton: any = document.querySelector('.WompiCheckout form button');
      wompiButton.click();
      TagManager.dataLayer({
        dataLayer: {
          checkout,
          event: 'wompiCheckout'
        }
      });
      setCheckout(null);
    }
  }, [checkout]);

  return (
    <div className="WompiCheckout" data-testid="WompiCheckout">
      {!!checkout && (
        <form action="https://checkout.wompi.co/p/" method="GET">
          <input type="hidden" name="public-key" value={checkout.publicKey} />
          <input type="hidden" name="currency" value={checkout.currency} />
          <input type="hidden" name="amount-in-cents" value={checkout['amount-in-cents']} />
          <input type="hidden" name="reference" value={checkout.reference} />
          <input type="hidden" name="redirect-url" value={checkout['redirect-url']} />

          <input type="hidden" name="customer-data:email" value={checkout['customer-data:email']} />
          <input
            type="hidden"
            name="customer-data:full-name"
            value={checkout['customer-data:full-name']}
          />
          <input
            type="hidden"
            name="customer-data:phone-number"
            value={checkout['customer-data:phone-number']}
          />
          <input
            type="hidden"
            name="customer-data:legal-id"
            value={checkout['customer-data:legal-id']}
          />
          <input
            type="hidden"
            name="customer-data:legal-id-type"
            value={checkout['customer-data:legal-id-type']}
          />
          <input
            type="hidden"
            name="shipping-address:address-line-1"
            value={checkout['shipping-address:address-line-1']}
          />
          <input
            type="hidden"
            name="shipping-address:country"
            value={checkout['shipping-address:country']}
          />
          <input
            type="hidden"
            name="shipping-address:phone-number"
            value={checkout['shipping-address:phone-number']}
          />
          <input
            type="hidden"
            name="shipping-address:city"
            value={checkout['shipping-address:city']}
          />
          <input
            type="hidden"
            name="shipping-address:region"
            value={checkout['shipping-address:region']}
          />
          <button type="submit" />
        </form>
      )}
      <Button {...buttonProps} size="large" onClick={onSubmit}>
        {children}
      </Button>
    </div>
  );
};

export default WompiCheckout;
