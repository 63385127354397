import { FC, useEffect, useState } from 'react';
import Carousel from 'components/Carousel';
import './ProductSlider.scss';
import { Typography } from 'ui/Material';
import InfoByColumns from 'components/InfoByColumns';
import LinkButton from 'components/LinkButton';
import * as routes from 'navigation/routes';
import { Product } from 'models/product';
import { getProductsSession } from 'adapters/Axios/services/product.service';
import BucketImage from 'components/BucketImage';

const ProductSlider: FC<any> = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);

  useEffect(() => {
    getProductsSession().then(({ data }) => setProducts(data));
  }, []);

  const selectedProduct = products[selectedProductIndex];

  return (
    <div className="ProductSlider" data-testid="ProductSlider">
      <InfoByColumns
        columns={[
          {
            className: 'description-wrapper',
            content: (
              <>
                <Typography variant="h5">{selectedProduct?.name}</Typography>
                <ul>
                  {(selectedProduct?.benefits || []).map((benefit: any, index: number) => (
                    <li key={index}>
                      <Typography variant="body1">{benefit.description}</Typography>
                    </li>
                  ))}
                </ul>
                <LinkButton label="MÁS INFORMACIÓN" to={routes.products} />
              </>
            )
          },
          {
            className: 'carousel-wrapper',
            breakpoints: {
              md: 12
            },
            content: (
              <>
                <div className="background" />
                <Carousel onChange={setSelectedProductIndex}>
                  {products ? (
                    products.map((product: any, index: number) => (
                      <div key={index}>
                        <BucketImage
                          loading="lazy"
                          width="400"
                          height="400"
                          alt="product packet"
                          src={product.icon_packet}
                        />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Carousel>
              </>
            )
          }
        ]}
      />
    </div>
  );
};

export default ProductSlider;
