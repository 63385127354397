import BucketImage from 'components/BucketImage';
import React from 'react';
import { Grid, Typography } from 'ui/Material';

import './HealthMetric.scss';

interface HealthMetricProps {
  metric: string;
  value?: any;
  image: string;
  xs?: number;
  md?: number;
}

const HealthMetric: React.FC<HealthMetricProps> = ({
  metric,
  value,
  image,
  xs = 12,
  md = 6
}: HealthMetricProps) => {
  return (
    <Grid className="HealthMetric" data-testid="HealthMetric" item xs={xs} md={md}>
      <Grid container spacing={2}>
        <Grid item xs={10} md={10}>
          <Typography className="metric-text">{metric}</Typography>
          <Typography className="value-text">
            {typeof value === 'string' ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: value || ''
                }}
              />
            ) : (
              value
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} md={2} className="image-wrapper">
          <BucketImage loading="lazy" src={image} alt="metric" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HealthMetric;
