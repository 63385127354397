import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export function DownloadPdf({ downloadPdf }) {
  return (
    <div className="flex_action">
      <div>
        <button className="button_sale" onClick={downloadPdf}>
          Descargar resultados
        </button>
      </div>

      <div>Se envió a tu correo electrónico registrado una copia de los resultados.</div>
    </div>
  );
}

DownloadPdf.propTypes = {
  downloadPdf: PropTypes.func.isRequired
};
