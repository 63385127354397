import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminStack from './AdminStack';
import AuthStack from './AuthStack';
import MainStack from './MainStack';
import * as routes from './routes';

interface Props {}

const Navigation: FC<Props> = () => {
  return (
    <Routes>
      <Route path="/*" element={<MainStack />} />
      <Route path={`${routes.privatePrefix}/*`} element={<AuthStack />} />
      <Route path={`${routes.adminPrefix}/*`} element={<AdminStack />} />
    </Routes>
  );
};

export default Navigation;
