import React from 'react';
import PropTypes from 'prop-types';

import './alert.css';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const campana = `${REACT_APP_S3_BUCKET_URL}/circle/img/campana.png`;

export function AlertRight({ data, labels }) {
  return (
    <div className="container_alert_d">
      {/* <div className="img_check_derecha"> */}
      <img className="status" src={campana} alt="status" />
      {/* </div> */}
      <div className="text_status_derecha_alert">¡Atención!</div>
    </div>
  );
}

AlertRight.propTypes = {
  data: PropTypes.any,
  labels: PropTypes.any
};
