import { BrowserRouter } from 'react-router-dom';
import Navigation from './navigation';
import Providers from './providers';
import Adapters from './adapters';
import UI from './ui';
import CookiesDialog from 'components/CookiesDialog';

import './i18n';
import './App.scss';

const App = () => (
  <div className="App" data-testid="App">
    <BrowserRouter>
      <CookiesDialog />
      <Adapters>
        <Providers>
          <UI>
            <Navigation />
          </UI>
        </Providers>
      </Adapters>
    </BrowserRouter>
  </div>
);

export default App;
