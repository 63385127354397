import React, { FC, useEffect, useState } from 'react';
import { InputProps, OutlinedInput } from 'ui/Material';
import { IMaskInput } from 'react-imask';
import './WeightInput.scss';

interface WeightInputProps extends InputProps {
  maxValue?: number;
}
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props: any, ref: any) {
  const { onChange, ...other } = props;

  const configBlocks = {
    weight: {
      mask: Number,
      radix: '.',
      scale: 2,
      signed: true,
      thousandsSeparator: ',',
      padFractionalZeros: false,
      normalizeZeros: true,
      value: '',
      unmask: true
    }
  };

  return (
    <IMaskInput
      {...other}
      blocks={configBlocks}
      mask="weight"
      definitions={{
        '#': /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/
      }}
      data-testid="weightInput"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      thousandsSeparator=""
    />
  );
});

const WeightInput: FC<WeightInputProps> = (props) => {
  const { placeholder } = props;
  let { maxValue } = props;
  const [value, setValue] = useState<any>('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const completeValue = () => {
    maxValue = maxValue || 700;

    const inputValue = Number(value) < maxValue ? value : maxValue;

    if (inputValue) {
      setValue(String(inputValue));
    } else {
      setValue('');
    }
  };

  useEffect(() => setValue(''), []);

  useEffect(() => setValue(Number(props.value) ? props.value : ''), [props.value]);

  return (
    <div className="WeightInput" data-testid="WeightInput">
      <OutlinedInput
        {...props}
        onBlur={completeValue}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputComponent={TextMaskCustom}
      />
    </div>
  );
};

export default WeightInput;
