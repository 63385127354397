import { FC } from 'react';
import './AccountByConfirm.scss';
import images from 'assets/images';
import { Typography } from 'ui/Material';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import * as routes from 'navigation/routes';
import BucketImage from 'components/BucketImage';

interface AccountByConfirmProps {}

const AccountByConfirm: FC<AccountByConfirmProps> = () => {
  const { t }: { t: Function } = useTranslation();

  const stateLocation = useLocation().state as { email?: string };

  if (!stateLocation?.email) {
    return <Navigate to={routes.signIn} replace={true} />;
  }

  return (
    <div className="AccountByConfirm" data-testid="AccountByConfirm">
      <BucketImage loading="lazy" src={images.login.phone} alt="login contirmation" />
      <Typography className="title">{t('AccountByConfirm.title')}</Typography>
      <Typography>{t('AccountByConfirm.message', { email: stateLocation?.email })}</Typography>
    </div>
  );
};

export default AccountByConfirm;
