import { FC, useEffect, useState } from 'react';
import ItemDoneList from 'components/ItemDoneList';
import { Grid, Typography } from 'ui/Material';
import ShippingInformation from '../ShippingInformation';
import OrdersHistory from '../OrdersHistory';
import FrequentOrder from '../FrequentOrder';
import { OrderHistoryModel, ProductTrackingModel } from '../../../../models/orders';
import { getOrdersHistory, getProductTracking } from 'adapters/Axios/services/orders.service';
import { localAuthService } from 'adapters/Session/services/localAuth.service';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import Button from 'components/Button';
import RenewPlan from '../RenewPlan';
import { refundUrl } from 'navigation/routes';

import './Orders.scss';

interface OrdersProps {
  orderId?: number;
  currentPlan: any;
}

const Orders: FC<OrdersProps> = ({ orderId, currentPlan }: OrdersProps) => {
  const { t }: { t: Function } = useTranslation();

  const [ordersHistory, setOrdersHistory] = useState<OrderHistoryModel[]>([]);

  const [productTracking, setProductTracking] = useState<ProductTrackingModel>();

  const [isLoadingOrdersHistory, setIsLoadingOrdersHistory] = useState(false);

  const [isLoadingProductTracking, setIsLoadingProductTracking] = useState(false);

  useEffect(() => {
    setIsLoadingOrdersHistory(true);

    const idAccount = localAuthService.getIdAccount();

    getOrdersHistory(idAccount)
      .then((res) => {
        setIsLoadingOrdersHistory(false);
        setOrdersHistory(res?.data);
      })
      .finally(() => setIsLoadingOrdersHistory(false));

    if (orderId) {
      setIsLoadingProductTracking(true);
      getProductTracking(idAccount, orderId)
        .then((res) => {
          setIsLoadingProductTracking(false);
          setProductTracking(res?.data);
        })
        .finally(() => setIsLoadingProductTracking(false));
    }
  }, []);

  return (
    <div className="Orders" data-testid="Orders">
      <Loading loading={isLoadingProductTracking}>
        <>
          <RenewPlan value={ordersHistory[0]} />
          <div className="title-product-tracking">
            <Typography variant="h5" textAlign="center" marginBottom={6}>
              {t('MainProfile.Orders.productTracking', { deliveryNumber: 'primera entrega' })}
            </Typography>
          </div>
          <Grid container spacing={1} marginBottom={4}>
            <Grid item xs={12} sm={5}>
              <Typography variant="h6" marginBottom={2}>
                {t('MainProfile.Orders.deliveryDates')}
              </Typography>
              <ItemDoneList itemDelivery={productTracking?.deliveries[0]} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <ShippingInformation
                address={productTracking?.direction}
                city={productTracking?.city}
              />
            </Grid>
          </Grid>
        </>
      </Loading>
      <hr className="principal-line" color="#ebebeb" />
      <Loading loading={isLoadingOrdersHistory}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={7}>
            <OrdersHistory ordersHistory={ordersHistory} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FrequentOrder value={currentPlan?.products} />
          </Grid>
        </Grid>
      </Loading>
      <a className="refund-btn" href={refundUrl} target="_blank" rel="noreferrer">
        <Button variant="outlined">SOLICITAR DEVOLUCIÓN</Button>
      </a>
    </div>
  );
};

export default Orders;
