import { FC } from 'react';
import { localAuthService } from 'adapters/Session/services/localAuth.service';
import { Navigate } from 'react-router';
import * as routes from 'navigation/routes';
import { useLocation } from 'react-router-dom';
import { AccountStatus } from 'constants/accountStatus';

interface PrivateRoutesProps {
  children: JSX.Element;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ children }: PrivateRoutesProps) => {
  const location = useLocation();

  const accountStatus = localAuthService.getAccountStatus();
  const isAccountActivated =
    accountStatus === AccountStatus.SUCCESS || accountStatus === AccountStatus.ACTIVATED;

  return isAccountActivated ? (
    children
  ) : (
    <Navigate to={`${routes.signIn}/${encodeURIComponent(location.pathname)}`} />
  );
};

export default PrivateRoutes;
