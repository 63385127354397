import * as React from 'react';
import { styled, Tab, TabProps } from 'ui/Material';

interface StyledTabProps extends TabProps {
  label?: string;
}

const StyledTab: React.FC<StyledTabProps> = styled((props: StyledTabProps) => (
  <Tab data-testid="StyledTab" disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  '&.Mui-selected': {
    color: 'black',
    fontWeight: '600'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'black'
  }
}));

export default StyledTab;
