import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sessionService } from './services/session.service';

type Props = {
  children: any;
};

const Session: FC<Props> = ({ children }) => {
  const location = useLocation();
  const [session, setSession] = useState<any>({});

  useEffect(() => {
    setSession(sessionService.get() || {});
  }, [location]);

  return children(session);
};

export default Session;
