import { FC, useState } from 'react';
import InputQuestion from 'components/InputQuestion';
import { useForm } from 'react-hook-form';

interface Props {
  onSubmit: any;
  title: string;
  description: string;
}

const OpenQuestion: FC<Props> = (props) => {
  const [value, setValue] = useState<any>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  watch((values) => {
    setValue(values.input);
  });

  const questionProps = {
    ...props,
    inputProps: {
      ...register('input'),
      'data-testid': 'openQuestion'
    },
    error: errors.value,
    onSubmit: handleSubmit(() => {
      props.onSubmit({
        ...props,
        value
      });
    })
  };

  return (
    <div className="OpenQuestion" data-testid="OpenQuestion">
      <InputQuestion {...questionProps} />
    </div>
  );
};

export default OpenQuestion;
