import React, { FC, useEffect, useState } from 'react';
import ProductBenefit from './components/ProductBenefit';
import RedirectToTest from './components/RedirectToTest';
import NutritionInformation from './components/NutritionInformation';
import { Product } from 'models/product';
import { Box, Typography } from 'ui/Material';
import StyledTabs from 'components/StyledTabs';
import StyledTab from 'components/StyledTab';
import { getProductsSession } from 'adapters/Axios/services/product.service';
import { useTranslation } from 'react-i18next';
import MoreProducts from './components/MoreProducts';
import HowTo from './components/HowTo';

import './Products.scss';

interface ProductsProps {}

const Products: FC<ProductsProps> = () => {
  const { t }: { t: Function } = useTranslation();

  const [value, setValue] = useState(0);

  const [products, setProducts] = useState<Product[]>([]);

  const [currentProduct, setCurrentProduct] = useState<Product>();

  useEffect(() => {
    getProductsSession().then(({ data }) => setProducts(data));
  }, []);

  useEffect(() => {
    setCurrentProduct(products[0]);
  }, [products]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentProduct(products[newValue]);
  };

  const handleOnClick = (product: Product) => {
    window.scrollTo(0, 0);
    setCurrentProduct(product);
    setValue(products.findIndex((item: Product) => item.id === product.id));
  };

  const tabsProps = {
    value,
    onChange: handleChange,
    scrollButtons: true,
    allowScrollButtonsMobile: true
  };

  return (
    <div className="Products" data-testid="Products">
      <div className="tabs-wrapper">
        <Box>
          <div>
            <Typography variant="h4">{t('Products.title')}</Typography>
          </div>
        </Box>
        <Box>
          <StyledTabs {...tabsProps} variant="scrollable" aria-label="scrollable auto tabs">
            {products.map((product, index) => (
              <StyledTab key={index} label={product?.name} />
            ))}
          </StyledTabs>
        </Box>
      </div>
      <ProductBenefit
        image={currentProduct?.icon_packet}
        productName={currentProduct?.name}
        benefits={currentProduct?.benefits}
        color={currentProduct?.color}
      />
      <RedirectToTest />
      <NutritionInformation
        productName={currentProduct?.name}
        productDescription={currentProduct?.description}
        ingredients={currentProduct?.ingredients}
        urlTable={currentProduct?.url_table}
      />
      <HowTo />
      <hr />
      <MoreProducts
        onClick={handleOnClick}
        products={products.filter((item: any) => item.id !== currentProduct?.id)}
      />
    </div>
  );
};

export default Products;
