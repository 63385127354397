import { FC, ReactElement, useEffect } from 'react';
import Axios from './Axios';
import { ToastContainer } from 'react-toastify';
import TagManager from './TagManager';
import Tag from 'react-gtm-module';

type Props = {
  children: JSX.Element;
};

const Adapters: FC<Props> = ({ children }: { children: ReactElement }) => {
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      Tag.dataLayer({
        dataLayer: {
          event: 'beforeunload'
        }
      });
    });
  }, []);

  return (
    <>
      <TagManager />
      <Axios>{children}</Axios>
      <ToastContainer />
    </>
  );
};

export default Adapters;
