import { AnswerOption } from 'models/questionnaire';
import client from './client';
import axios from 'axios';
import { sessionService } from 'adapters/Session/services/session.service';

export const getQuestionnaire = () => client.get(`questionnaires/ms/v1/questions`);
export const getAllProducts = () => client.get(`questionnaires/ms/v1/product/all`);

export const saveAnswer = (numberQuestion: string, answers: AnswerOption[]) =>
  client.post(`questionnaires/ms/v1/answers`, {
    id_user: localStorage.getItem('userId'),
    number_question: numberQuestion,
    answer_options: answers
  });

export const getQuestionnaireResults = () =>
  client.get(`questionnaires/ms/v1/results/${localStorage.getItem('userId')}`);

export const getProducts = () =>
  client.get(`questionnaires/ms/v1/product/${localStorage.getItem('userId')}`);

export const getProductsList = () => client.get(`orders/ms/v1/products`);

export const getDiscountCodeList = () => client.get(`orders/ms/v1/coupons`);

export const saveApproach = (selectedProducts: any, frequency: any) =>
  client.post(
    `orders/ms/v1/product/${localStorage.getItem('userId')}/${frequency}`,
    selectedProducts
  );

export const getServicePlan = () => client.get(`orders/ms/v1/plan`);

export const updateServicePlan = (id: any, data: any) =>
  client.put(`orders/ms/v1/plan/${id}`, data);

export const getDepartments = () => client.get(`orders/ms/v1/departments`);

export const getShippingCoverage = (codeDepartment: string, codeCity: string) =>
  client.get(`orders/ms/v1/coverage`, {
    params: {
      codeDepartment,
      codeCity
    }
  });

export const saveOrder = (data: any) =>
  client.post(`orders/ms/v1/orders`, {
    ...data,
    id_user: localStorage.getItem('userId')
  });

export const getDiscountCode = (code: string) =>
  client.get(`orders/ms/v1/coupon`, {
    params: {
      idUser: localStorage.getItem('userId'),
      code
    }
  });

export const getAnswers = () =>
  client.get(`questionnaires/ms/v1/answers/${localStorage.getItem('userId')}`);
const axiosClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return api;
};

export const sendPdfResultsByEmail = (file: File) => {
  console.log('sendPdfResultsByEmail');
  const formData = new FormData();
  formData.append('file', file);
  const sessionData = sessionService.session;

  return axiosClient().post(
    `notifications/ms/v1/results/${localStorage.getItem('userId')}?email=${String(
      sessionData?.email || ''
    )}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};
