import { FC, useState } from 'react';
import { OutlinedInput } from 'ui/Material';
import { Lock, LockOpen } from 'ui/Material/icons';
import './PasswordInput.scss';

const PasswordInput: FC<any> = (props) => {
  const [value, setValue] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePassword = () => setPasswordVisible(!passwordVisible);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange({ target: { name: props.name, value: event.target.value || value } });
    }
  };

  return (
    <div className="PasswordInput" data-testid="PasswordInput">
      <OutlinedInput
        type={!passwordVisible && 'password'}
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleChange}
      />
      {passwordVisible ? <LockOpen onClick={togglePassword} /> : <Lock onClick={togglePassword} />}
    </div>
  );
};

export default PasswordInput;
