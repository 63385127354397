import images from 'assets/images';
import HealthMetric from 'components/HealthMetric';
import PlanFocus from 'components/PlanFocus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Card, CardContent, Grid, Typography } from 'ui/Material';
import { PhysicalActivity, Product } from '../../../../models/profile';

import './PlanApproach.scss';

export interface PlanApproachProps {
  challenge?: string;
  planFocus?: string;
  planFocusProductList?: Product[];
  healthMetric?: {};
  imc?: number;
  water?: number;
  calories?: number;
  physicalActivity?: PhysicalActivity;
}

const PlanApproach: React.FC<PlanApproachProps> = ({
  challenge,
  planFocus,
  planFocusProductList,
  imc,
  physicalActivity,
  water,
  calories
}: PlanApproachProps) => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="PlanApproach" data-testid="PlanApproach">
      <Card className="card-plan">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={planFocusProductList ? 9 : 12}>
              <Typography className="title mb-2">
                {t('MainProfile.ProfilePanel.PlanApproach.title')}
              </Typography>
              <Typography className="subtitle">
                {t('MainProfile.ProfilePanel.PlanApproach.yourChallenge')}
              </Typography>
              <Typography className="header-text mb-2">{challenge}</Typography>
              <hr color="#ebebeb" />
              <Typography className="title mb-2 mt-2">{planFocus}</Typography>
              <Typography className="subtitle mb-2">
                {t('MainProfile.ProfilePanel.PlanApproach.currentData')}
              </Typography>
              <Grid container spacing={2}>
                <HealthMetric
                  metric={t('MainProfile.ProfilePanel.PlanApproach.bodyMass')}
                  value={t('HealthMetrics.imcSuffix', { imc })}
                  image={images.Body}
                />
                <HealthMetric
                  metric={t('MainProfile.ProfilePanel.PlanApproach.physicalActivity')}
                  value={t('HealthMetrics.minutesPerWeek', {
                    minutes: physicalActivity?.executed_minutes_per_week
                  })}
                  image={images.Runner}
                />
                <HealthMetric
                  metric={t('MainProfile.ProfilePanel.PlanApproach.water')}
                  value={t('HealthMetrics.waterSuffix', { water })}
                  image={images.GlassWater}
                />
                <HealthMetric
                  metric={t('MainProfile.ProfilePanel.PlanApproach.calories')}
                  value={
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType={'text'}
                      suffix={t('HealthMetrics.caloriesSuffix')}
                      value={Number(calories).toFixed(0)}
                    />
                  }
                  image={images.Calories}
                />
              </Grid>
            </Grid>
            {planFocusProductList && (
              <Grid item xs={12} md={3} textAlign="center">
                <Typography className="subtitle mb-2">
                  {t('MainProfile.ProfilePanel.PlanApproach.planFocus')}
                </Typography>
                <Grid container spacing={2}>
                  {planFocusProductList?.map((product: Product, index) => (
                    <PlanFocus
                      key={index}
                      product={product?.approach}
                      image={product?.icon_product}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default PlanApproach;
