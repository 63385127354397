import BucketImage from 'components/BucketImage';
import { FC } from 'react';
import './TopCover.scss';

interface Props {
  image: string;
  children: any;
}

const TopCover: FC<Props> = ({ image, children }) => {
  return (
    <div className="TopCover" data-testid="TopCover">
      <BucketImage
        width="1440"
        height="690"
        alt="background image"
        className="background-image"
        src={image}
      />
      <div className="data-wrapper">{children}</div>
    </div>
  );
};

export default TopCover;
