import { FC } from 'react';
import { Avatar, Typography, useTheme } from 'ui/Material';

import './StepNumber.scss';

interface Props {
  value: number;
}

const StepNumber: FC<Props> = ({ value }) => {
  const theme = useTheme();

  return (
    <div className="StepNumber" data-testid="StepNumber">
      <Avatar sx={{ 'background-color': theme.palette.secondary.main }}>
        <Typography variant="h6">{String(value)}</Typography>
      </Avatar>
    </div>
  );
};

export default StepNumber;
