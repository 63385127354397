import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, LinearProgressProps } from 'ui/Material';

import './ProgressBar.scss';

interface Props {
  linearProgressProps?: LinearProgressProps;
  currentStep: number;
  numberOfSteps: number;
  name: string;
}

const ProgressBar: FC<Props> = ({ currentStep, numberOfSteps, linearProgressProps, name }) => {
  const { t }: { t: Function } = useTranslation();

  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue((currentStep * 100) / numberOfSteps);
  }, [currentStep, numberOfSteps]);

  return (
    <div className="ProgressBar" data-testid="ProgressBar">
      <label>
        {currentStep} {t('outOf')} {numberOfSteps}
      </label>
      <label>{name}</label>
      <LinearProgress variant="determinate" value={value} {...linearProgressProps} />
    </div>
  );
};

export default ProgressBar;
