import { formatDnsImg } from 'util/formatStrings';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const urlBase = formatDnsImg(REACT_APP_S3_BUCKET_URL).replace('/assets', '');

const images = {
  nutritionist: {
    Maximiliano: `${urlBase}/landing/maximiliano-kammerer.png`,
    Isabel: `${urlBase}/landing/isabel-pulgarin.png`,
    Ricardo: `${urlBase}/landing/ricardo-rosero.png`,
    Gabriel: `${urlBase}/landing/gabriel-mesa.png`
  },
  procedure: {
    FirstStep: `${urlBase}/landing/men-landing-2x.png`,
    SecondStep: `${urlBase}/landing/product-lyf-3-2x.png`,
    ThirdStep: `${urlBase}/landing/cel-2x.png`
  },
  products: {
    Supplement: `${urlBase}/landing/product-lyf-1-2x.png`,
    Supplement2: `${urlBase}/landing/product-lyf-2-2x.png`
  },
  Sickness: `${urlBase}/landing/ClosedQuestion.svg`,
  LogoVidarium: `${urlBase}/landing/logo-vidarium.png`,
  ClientWoman: `${urlBase}/landing/client-woman.png`,
  ImmunityIcon: `${urlBase}/landing/inmunidad-icon.svg`,
  Experts: `${urlBase}/landing/experts.jpg`,
  BackgroundGreenTop: `${urlBase}/landing/background-green.png`,
  BackgroundGreenBottom: `${urlBase}/landing/Grupo+75968.svg`,
  BackgroundWhiteTop: `${urlBase}/landing/background-white-top.png`,
  BackgroundWhiteBottom: `${urlBase}/landing/background-white-bottom.png`,
  Exercise: `${urlBase}/landing/init-survey.svg`,
  EatingDisorder: `${urlBase}/landing/Eating+disorder-pana.svg`,
  Stop: `${urlBase}/landing/pare-y-compare+blanco-2x.png`,
  HomeCover: `${urlBase}/landing/men-wizard-2x.png`,
  LyfLogo: `${urlBase}/landing/icon-lyf-2x.png`,
  BenetLogo: `https://ause1-s3-ia012-assets-sabi.s3.amazonaws.com/landing/logo_benet_blanco.png`,
  AgeLimit: `${urlBase}/landing/-18.svg`,
  recomendations: {
    dairy: `${urlBase}/landing/dairy.svg`,
    fruits: `${urlBase}/landing/fruits.svg`,
    hydration: `${urlBase}/landing/glass-water.svg`,
    corn: `${urlBase}/landing/grains.svg`,
    physicalActivity: `${urlBase}/landing/man-running.svg`,
    meat: `${urlBase}/landing/meat.svg`,
    salt: `${urlBase}/landing/salt.svg`,
    seeds: `${urlBase}/landing/seeds.svg`,
    vitamins: `${urlBase}/landing/vitamins.svg`
  },
  MaleDisabled: `${urlBase}/landing/male-disabled%402x.png`,
  Male: `${urlBase}/landing/male%402x.png`,
  FemaleDisabled: `${urlBase}/landing/female-disabled%402x.png`,
  Female: `${urlBase}/landing/female%402x.png`,
  MentalHealth: `${urlBase}/questionnaire/mental-health.svg`,
  Body: `${urlBase}/questionnaire/imc.png`,
  Runner: `${urlBase}/questionnaire/runner.svg`,
  GlassWater: `${urlBase}/questionnaire/water.png`,
  Calories: `${urlBase}/questionnaire/calories.png`,
  OMS: `${urlBase}/questionnaire/logo-OMS.png`,
  LyfBlue: `${urlBase}/questionnaire/lyf-suffix.png`,
  Mobile: `${urlBase}/landing/mobile.svg`,
  LyfWhite: `${urlBase}/questionnaire/lyf-imagotipo.svg`,
  LyfPacking: `${urlBase}/products/packing.png`,
  login: {
    person: `${urlBase}/landing/men-wizard.png`,
    phone: `${urlBase}/landing/mobile.svg`
  },
  Delivery: `${urlBase}/questionnaire/delivery.svg`,
  CheckIcon: `${urlBase}/landing/check-icon.png`,
  BlueCheckIcon: `${urlBase}/landing/blue-check-icon.png`,
  mainProfile: {
    mobileDoctor: `${urlBase}/landing/mobile-doctor.png`
  },
  DoneIcon: `${urlBase}/landing/icon-done.svg`,
  Laboratory: `${urlBase}/landing/laboratory.svg`,
  ArrowTopIcon: `${urlBase}/landing/arrow-top.svg`,
  PagesIcon: `${urlBase}/landing/icon-pages.svg`,
  paymentMethods: {
    pse: `${urlBase}/landing/PSE.png`,
    visa: `${urlBase}/landing/visa.png`,
    american: `${urlBase}/landing/american.png`,
    mastercard: `${urlBase}/landing/mastercard.png`
  },
  productHowTo: {
    firstStep: `${urlBase}/landing/how-to-1.png`,
    secondStep: `${urlBase}/landing/how-to-2.png`,
    thirdStep: `${urlBase}/landing/how-to-3.png`
  },
  aboutUs: {
    Health: `${urlBase}/landing/about-us-health.png`,
    You: `${urlBase}/landing/about-us-grid-2.png`,
    Cover: `${urlBase}/landing/about-us-cover.png`,
    Products: `${urlBase}/landing/about-us-products.png`
  },

  material: {
    arrow_back_ios: `${urlBase}/assets/web/svg/arrow_back_ios.svg`,
    arrow_back: `${urlBase}/assets/web/svg/arrow_back.svg`,
    logout: `${urlBase}/assets/web/svg/logout.svg`,
    home: `${urlBase}/assets/web/svg/home.svg`
  },
  clientTestimonials: {
    energy: `https://revolucion.benet.com.co/products/energia.png`,
    nutritional: `${urlBase}/products/com_nutricional.png`
  },
  paymentSuccess: `${urlBase}/questionnaire/payment-success.png`,
  paymentError: `${urlBase}/questionnaire/payment-error.png`
};

export default images;
