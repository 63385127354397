import { FC, useEffect } from 'react';

import './Frame.scss';

interface Props {
  src: string;
  heightReference: string;
}
let timeout: any;
const Frame: FC<Props> = (props) => {
  const resizeIFrameToFitContent = () => {
    const iFrame: any = document.querySelector('.Frame');
    if (iFrame.src.indexOf(window.location.host) > -1) {
      const siteContent = iFrame.contentWindow.document.querySelector(props.heightReference);
      iFrame.height = siteContent?.offsetHeight || '100%';
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => resizeIFrameToFitContent(), 1000);
  };
  useEffect(() => {
    resizeIFrameToFitContent();
  }, []);

  return <iframe className="Frame" data-testid="Frame" {...props} />;
};

export default Frame;
