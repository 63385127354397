import Button from 'components/Button';
import { FC, useEffect, useState } from 'react';
import { Radio } from 'ui/Material';
import QuestionHeader from '../QuestionHeader';
import './SingleOptionSelector.scss';

interface Option {
  id: any;
  text: string;
}

interface Props {
  defaultValue?: any;
  urlIcon?: string;
  title: string;
  description?: string;
  options: Array<Option>;
  onSubmit: any;
  useRadioButton?: boolean;
  tooltip?: string;
}

const SingleOptionSelector: FC<Props> = ({
  title,
  defaultValue,
  description,
  tooltip,
  options,
  onSubmit,
  urlIcon,
  useRadioButton
}) => {
  const [selecteOption, setSelectedOption] = useState<number>();
  const defaultValueId = Array.isArray(defaultValue?.id_answer_option)
    ? defaultValue?.id_answer_option[0]
    : defaultValue?.id_answer_option;
  const handleOnClick = (value: any) => {
    setSelectedOption(value.id);
    onSubmit(value);
  };

  const renderOptions = () =>
    options.map((option, index) => {
      const buttonProps = {
        onClick: () => handleOnClick(option),
        className: `white ${!useRadioButton && option.id === defaultValueId && 'selected'}`
      };

      return (
        <Button key={index} {...buttonProps}>
          {useRadioButton && <Radio checked={selecteOption === option.id} />}
          {option.text}
        </Button>
      );
    });

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find((optionItem: any) => optionItem.id === defaultValueId);
      if (defaultOption) {
        if (useRadioButton) {
          handleOnClick(defaultOption);
        } else {
          setSelectedOption(defaultOption.id);
        }
      }
    }
  }, [defaultValue, options]);

  return (
    <div className="SingleOptionSelector" data-testid="SingleOptionSelector">
      <QuestionHeader title={title} description={description} image={urlIcon} tooltip={tooltip}>
        {renderOptions()}
      </QuestionHeader>
    </div>
  );
};

export default SingleOptionSelector;
