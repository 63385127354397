import { FC, useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import images from 'assets/images';
import { useLocation } from 'react-router-dom';
import WhatsappButton from 'components/WhatsappButton';

import './Layout.scss';
import Breadcrumb from 'components/Breadcrumb';
import BucketImage from 'components/BucketImage';

interface Props {
  children?: JSX.Element;
}

const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const [viewClassName, setViewClassName] = useState('home');

  useEffect(() => {
    const { pathname } = window.location;

    setViewClassName(pathname.split('/').join(' ').trim() || 'home');
  }, [location]);

  return (
    <div className={`Layout ${viewClassName}`} data-testid="Layout">
      <Header />
      {!location.pathname.includes('new-results') ? <WhatsappButton /> : <></>}
      <Breadcrumb />
      <div className="view-wrapper">
        <BucketImage
          loading="lazy"
          width="220"
          height="220"
          alt="backgorund decoration"
          className="circle-original green-top"
          src={images.BackgroundGreenTop}
        />
        <BucketImage
          loading="lazy"
          width="350"
          height="350"
          alt="backgorund decoration"
          className="circle-original green-bottom"
          src={images.BackgroundGreenBottom}
        />
        <BucketImage
          loading="lazy"
          width="220"
          height="220"
          alt="backgorund decoration"
          className="circle-original white-top"
          src={images.BackgroundWhiteTop}
        />
        <BucketImage
          loading="lazy"
          width="350"
          height="350"
          alt="backgorund decoration"
          className="circle-original white-bottom"
          src={images.BackgroundWhiteBottom}
        />
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
