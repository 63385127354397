import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import images from 'assets/images';
import { useTranslation } from 'react-i18next';

import './ShippingInformation.scss';
import BucketImage from 'components/BucketImage';

interface ShippingInformationProps {
  address?: string;
  city?: string;
}

const ShippingInformation: FC<ShippingInformationProps> = ({
  address,
  city
}: ShippingInformationProps) => {
  const { t }: { t: Function } = useTranslation();

  return (
    <Grid className="ShippingInformation" data-testid="ShippingInformation" container spacing={4}>
      <Grid item xs={5} sm={6} alignItems="center" margin="auto">
        <BucketImage loading="lazy" src={images.Laboratory} alt="done" width="auto" />
      </Grid>
      <Grid item xs={7} sm={6}>
        <Typography variant="h6" marginBottom={2}>
          {t('MainProfile.Orders.shippingInformation')}
        </Typography>
        <Typography fontWeight={500}>{t('MainProfile.Orders.deliveryAddress')}</Typography>
        <Typography>{address}</Typography>
        <Typography marginBottom={4}>{city}</Typography>
        <Typography variant="body1">{t('MainProfile.Orders.messageChangeAddress')}</Typography>
      </Grid>
    </Grid>
  );
};

export default ShippingInformation;
