import React from 'react';
import PropTypes from 'prop-types';

import '../index.css';
import Percent from './Percent';
import Session from 'adapters/Session';

const maxScrore = 260;

function Header({ results }) {
  return (
    <div className="header-resumen">
      <div className="header-text">
        <div className="header-name">
          <Session>{(session) => <b>{session?.firstName}</b>}</Session>
        </div>
        <div className="header-resumen-title">Según tus respuestas tu score general es:</div>
        <div className="header-result">{results.score_life_style}</div>
      </div>
      <div className="header-percent">
        <div className="header-percent-container">
          <div id="header-circle">
            <Percent
              percent={Math.round((Number(results.valueLifeStyle) / maxScrore) * 100)}
            ></Percent>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  results: PropTypes.object.isRequired
};

export default Header;
