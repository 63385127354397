import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from 'ui/Material';
import StyledTabs from 'components/StyledTabs';
import TabPanel from 'components/TabPanel';
import StyledTab from 'components/StyledTab';
import ProfilePanel from './components/ProfilePanel';
import { useTranslation } from 'react-i18next';
import Session from 'adapters/Session';
import { getProfile } from 'adapters/Axios/services/profile.service';
import { localAuthService } from 'adapters/Session/services/localAuth.service';
import { useNavigate } from 'react-router-dom';
import { ProfileResponse } from '../../models/profile';
import Orders from './components/Orders';
import Loading from 'components/Loading';
import * as routes from 'navigation/routes';

import './MainProfile.scss';

interface MainProfileProps {}

const MainProfile: FC<MainProfileProps> = () => {
  const navigate = useNavigate();

  const { t }: { t: Function } = useTranslation();

  const [value, setValue] = useState(0);

  const [profileInfo, setProfileInfo] = useState<ProfileResponse>();

  const [isLoadingProfileInfo, setIsLoadingProfileInfo] = useState(false);

  useEffect(() => {
    setIsLoadingProfileInfo(true);
    const idAccount = localAuthService.getIdAccount();
    idAccount &&
      getProfile(idAccount)
        .then((res) => handledSuccessProfileResponse(res.data))
        .finally(() => setIsLoadingProfileInfo(false));
  }, []);

  const handledSuccessProfileResponse = (profileResponse: ProfileResponse) => {
    setIsLoadingProfileInfo(false);
    setProfileInfo(profileResponse);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabsDefaultParams = {
    value: value,
    onChange: handleChange,
    scrollButtons: true,
    allowScrollButtonsMobile: true,
    'aria-label': 'scrollable auto tabs'
  };

  return (
    <div className="MainProfile" data-testid="MainProfile">
      <div className="tabs-wrapper">
        <Box>
          <Session>
            {(session: any) => <Typography>{t('MainProfile.hiName', session)}</Typography>}
          </Session>
        </Box>
        <Box>
          <StyledTabs variant="scrollable" {...tabsDefaultParams}>
            <StyledTab
              onClick={() => navigate(routes.usePrivatePrefix(routes.mainProfile))}
              label={t('MainProfile.tabs.profile')}
            />
            <StyledTab
              onClick={() => navigate(routes.usePrivatePrefix(routes.orders))}
              disabled={!profileInfo?.plan?.id_order}
              label={t('MainProfile.tabs.orders')}
            />
          </StyledTabs>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <Loading loading={isLoadingProfileInfo}>
          <ProfilePanel plan={profileInfo?.plan} result={profileInfo?.result} />
        </Loading>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Orders currentPlan={profileInfo?.plan} orderId={profileInfo?.plan?.id_order} />
      </TabPanel>
    </div>
  );
};

export default MainProfile;
