import { FC, useEffect, useState } from 'react';
import { Card, Checkbox, Grid, TextField, Tooltip, Typography } from 'ui/Material';
import QuestionHeader from 'components/Assessment/QuestionHeader';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from 'ui/Material/icons';
import './MultipleImageOptionSelector.scss';
import BucketImage from 'components/BucketImage';

interface Options {
  id: any;
  url_icon_selected?: string;
  url_icon?: string;
  text?: string;
}

interface Props {
  title: string;
  numberQuestion?: any;
  description: string;
  options: Array<Options>;
  onSubmit: any;
  defaultValue?: any;
  tooltip?: string;
  BoxComponent?: any;
  boxComponentProps?: any;
}

const MultipleImageOptionSelector: FC<Props> = ({
  title,
  tooltip,
  numberQuestion,
  description,
  options,
  onSubmit,
  defaultValue,
  BoxComponent,
  boxComponentProps
}) => {
  const { t }: { t: Function } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const [hasEmptyValues, setHasEmptyValues] = useState(false);

  const [update, setUpdate] = useState(false);

  const handleBoxChange = (option: any, value: any) => {
    const optionIndex = findSelectedOption(option);

    selectedOptions[optionIndex] = {
      ...selectedOptions[optionIndex],
      value
    };
    setSelectedOptions(selectedOptions);
    validateEmptyValues();
  };

  const validateEmptyValues = () => {
    const emptyValues = selectedOptions.filter(
      (selectedOption: any) => !selectedOption.value && !selectedOption.hide_buttons
    );
    setHasEmptyValues(!!BoxComponent && !!selectedOptions.length && !!emptyValues.length);
  };

  const handleOnClick = () => {
    onSubmit(selectedOptions);
  };

  const findSelectedOption = (option: any) =>
    selectedOptions.findIndex((selectedOption: any) => selectedOption.id === option.id);

  const handleToggleOption = (option: any) => {
    let optionsArray = selectedOptions;
    const indexOfSelectedItem = findSelectedOption(option);
    if (!option.excludes) {
      optionsArray = optionsArray.filter((opt) => !opt.excludes);
    }
    if (indexOfSelectedItem > -1) {
      optionsArray.splice(indexOfSelectedItem, 1);
    } else {
      if (option.excludes) {
        optionsArray = [];
      }
      optionsArray.push(option);
    }
    setSelectedOptions(optionsArray);
    setUpdate(!update);
  };

  const handleInputFieldChange = (event: any, option: any) => {
    const { value } = event.target;
    const optionsArray = (option.excludes ? [] : selectedOptions).filter(
      (opt) => !opt.text_answer_option
    );

    if (value) {
      optionsArray.push({ ...option, text_answer_option: value, text: value });
    }
    setSelectedOptions(optionsArray);
  };

  const renderGridItem = (option: any, key: number) => {
    if (option.use_input) {
      return (
        <TextField
          key={key}
          onChange={(event) => handleInputFieldChange(event, option)}
          placeholder={option.text}
        />
      );
    }
    const isChecked = findSelectedOption(option) > -1;

    const gridProps = {
      className: `option ${isChecked && 'checked'}`,
      key,
      item: true,
      md: 4,
      sm: 6,
      xs: 6
    };

    const optionProps = {
      onClick: () => handleToggleOption(option),
      'data-testid': 'MultipleImageOptionSelector-option'
    };

    return (
      <Grid flexDirection="column" {...gridProps}>
        <Card {...optionProps}>
          <Checkbox checked={isChecked} />
          <BucketImage loading="lazy" alt="icon" src={option.url_icon_selected} />
          <BucketImage loading="lazy" alt="icon" className="disabled" src={option.url_icon} />
          <Typography variant="caption">{option.text}</Typography>
          {option.tooltip && (
            <Tooltip title={option.tooltip}>
              <InfoOutlined className="tooltip-icon" />
            </Tooltip>
          )}
        </Card>
        {BoxComponent && !option.hide_buttons && (
          <BoxComponent
            disabled={!isChecked}
            onChange={(value: any) => handleBoxChange(option, value)}
            {...boxComponentProps}
          />
        )}
      </Grid>
    );
  };

  useEffect(() => {
    setSelectedOptions([]);
  }, [numberQuestion]);

  useEffect(() => validateEmptyValues(), [selectedOptions, selectedOptions?.length, update]);

  useEffect(() => {
    if (defaultValue && !selectedOptions.length) {
      const defaultAnswers: any = [];
      defaultValue.id_answer_option.forEach((optionId: number) => {
        const defaultOption = options.find((optionItem: any) => optionItem.id === optionId);
        if (defaultOption) {
          defaultAnswers.push(defaultOption);
        }
      });
      setSelectedOptions(defaultAnswers);
    }
  }, [defaultValue, options]);

  return (
    <div className="MultipleImageOptionSelector" data-testid="MultipleImageOptionSelector">
      <QuestionHeader title={title} description={description} tooltip={tooltip}>
        <Grid container spacing={2} justifyContent="center">
          {options.map(renderGridItem)}
        </Grid>
        <Button disabled={!selectedOptions.length || hasEmptyValues} onClick={handleOnClick}>
          {t('continue')}
        </Button>
      </QuestionHeader>
    </div>
  );
};

export default MultipleImageOptionSelector;
