export function getFuncionalityOption(results) {
  const funcionality = {
    cardiovascular: results.cardiovascular,
    energy: results.energy,
    immunity: results.immunity,
    digestive_health: results.digestive_health,
    nutritional_supplement: results.nutritional_supplement
  };

  const lower = {
    key: '',
    percentage: 1000
  };

  for (const [key, value] of Object.entries(funcionality)) {
    const calc = { score: value.score, maxValue: value.max_value };
    const percentage = (calc.score / calc.maxValue) * 100;

    if (percentage < lower.percentage) {
      lower.key = key;
      lower.percentage = percentage;
    }
  }

  return lower.key;
}
