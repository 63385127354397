import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Typography, TypographyProps } from 'ui/Material';

interface Props {
  value?: any;
  typographyProps?: TypographyProps;
}

const CurrencyFormat: FC<Props> = ({ value, typographyProps }) => (
  <Typography {...typographyProps} data-testid="CurrencyFormat">
    <NumberFormat
      className="CurrencyFormat"
      prefix={'$ '}
      thousandSeparator="."
      decimalSeparator=","
      displayType={'text'}
      value={Number(value).toFixed(0)}
    />
  </Typography>
);

export default CurrencyFormat;
