import { useEffect, useState } from 'react';
import BackButton from 'components/BackButton';
import UserEmailForm from 'components/Assessment/UserEmailForm';
import UserInfoForm from 'components/Assessment/UserInfoForm';
import SicknessWarning from './components/SicknessWarning';
import Categories from 'components/Assessment/Categories';
import * as accountService from 'adapters/Axios/services/account.service';
import { getQuestionnaire } from 'adapters/Axios/services/questionnaire.service';
import { useNavigate } from 'react-router-dom';
import * as routes from 'navigation/routes';
import { UserInfoModel } from 'models/user';
import TagManager from 'react-gtm-module';

import './Assessment.scss';

const Assessment = () => {
  const navigation = useNavigate();

  const [userEmail, setUserEmail] = useState();

  const [categories, setCategories] = useState([]);

  const [sicknessWarningVisible, setSicknessWarningVisible] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfoModel>();

  const [currentStep, setCurrentStep] = useState(0);

  const updateUserInfo = (name: string, value: any) =>
    setUserInfo({
      ...userInfo,
      [name]: value
    });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setSicknessWarningVisible(false);
    setCurrentStep(currentStep - 1);
  };

  const handleSubmitEmail = (values: any) => {
    setUserEmail(values?.email);
    updateUserInfo('email', values.email);
    nextStep();
  };

  const handleRegisteredEmail = () => {
    setCurrentStep(2);
  };

  const handleSubmitInfo = (values: any) => {
    accountService
      .createAccount({
        ...userInfo,
        ...values
      })
      .then((res) => {
        localStorage.setItem('userId', res.data.id);

        nextStep();
        setUserInfo({
          ...userInfo,
          ...values
        });
      });
  };

  const handleQuestionResponse = (question: any, value: any) => {
    if (question.number_question === 'P28' && value.text === 'Si') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sicknessWarning'
        }
      });
    }
  };

  const steps = [
    <UserEmailForm key={0} onSubmit={handleSubmitEmail} onStatusPending={handleRegisteredEmail} />,
    <UserInfoForm key={1} defaultEmail={userEmail} onSubmit={handleSubmitInfo} />,
    <Categories
      key={3}
      value={categories}
      onFinish={nextStep}
      onQuestionSubmit={handleQuestionResponse}
    />
  ];

  useEffect(() => {
    getQuestionnaire().then((response) => setCategories(response.data.categories));
    TagManager.dataLayer({
      dataLayer: {
        startDate: new Date().toString(),
        event: 'assessmentStarted'
      }
    });
  }, []);

  useEffect(() => {
    if (!steps[currentStep]) {
      navigation(routes.newResults);
      TagManager.dataLayer({
        dataLayer: {
          endDate: new Date().toString(),
          event: 'assessmentEnded'
        }
      });
    } else {
      navigation(routes.assessmentStep.replace(':step', String(currentStep)));
    }
  }, [currentStep]);

  return (
    <div className="Assessment" data-testid="Assessment">
      <div className="form-wrapper">
        {sicknessWarningVisible ? <SicknessWarning /> : steps[currentStep]}
      </div>
      <BackButton onClick={previousStep} />
    </div>
  );
};

export default Assessment;
