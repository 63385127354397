// eslint-disable-next-line react/prop-types
//  const canvas = document.getElementById("chartCanvas");
//  const ctx = canvas.getContext("2d");
let ctx;
let percentage;
let radius;
let lineWidth;
let centerX;
let centerY;

const MAX = 100;

function drawCircle(color, lineWidth, percent) {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  //  Calcular el porcentaje invertido
  const invertedPercent = percent === MAX ? percent : MAX - percent;

  //  Convertir el porcentaje invertido a radianes
  const startAngle = (1.5 + (2 * invertedPercent) / MAX) * Math.PI;
  const endAngle = 1.5 * Math.PI;

  //  sentido antihorario
  ctx.arc(centerX, centerY, radius - lineWidth, startAngle, endAngle);
  //  sentido horario
  //  ctx.arc(centerX, centerY, radius - lineWidth, -0.5 * Math.PI, (2 * percent / MAX - 0.5) * Math.PI);
  ctx.stroke();
}

function drawCirclecomplete(color, lineWidth, radius) {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.arc(centerX, centerY, radius - lineWidth, -0.5 * Math.PI, ((2 * MAX) / MAX - 0.5) * Math.PI);
  ctx.stroke();
}

// function drawText(percent) {
//   ctx.fillStyle = '#000';
//   ctx.font = 'bold 24px Arial';
//   ctx.textAlign = 'center';
//   ctx.textBaseline = 'middle';
//   ctx.fillText(percent + '%', centerX, centerY);
// }

function selectColor(percentage) {
  let color;

  if (percentage < 50) {
    color = '#ea9999';
  } else if (percentage >= 50 && percentage <= 70) {
    color = '#f9cb9c';
  } else if (percentage >= 71 && percentage <= 90) {
    color = '#fff2cc';
  } else {
    color = '#d9ead3';
  }
  return color;
}

export function drawChart(canvas, percent) {
  percentage = Number(percent); //  Porcentaje que deseas mostrar
  radius = canvas.width / 2.6;
  lineWidth = 10;
  centerX = canvas.width / 2;
  centerY = canvas.height / 2;

  ctx = canvas.getContext('2d');
  const color = selectColor(percentage);
  const color2 = '#bcbcbe'; //  Color del circulo fijo
  drawCirclecomplete(color2, 4, radius - (lineWidth / 2 + 1));
  //  Dibuja la porción del gráfico
  drawCircle(color, lineWidth, percentage);
  const div = document.getElementById('contenedorPercent');
  div.innerText = `${percentage}%`;
  //  div.style.backgroundColor = color;
  div.style.borderColor = color;

  //  Dibuja el texto con el porcentaje
  // drawText(percentage);
}
