import images from 'assets/images';
import { useTranslation } from 'react-i18next';
import InfoByColumns from 'components/InfoByColumns';
import { FC } from 'react';
import { Typography } from 'ui/Material';
import './NutritionExperts.scss';
import BucketImage from 'components/BucketImage';

interface Props {}

const NutritionExperts: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();
  return (
    <div className="NutritionExperts" data-testid="NutritionExperts">
      <InfoByColumns
        columns={[
          {
            className: 'experts-image',
            content: (
              <BucketImage
                loading="lazy"
                width="625"
                height="540"
                alt="experts"
                src={images.Experts}
              />
            )
          },
          {
            className: 'vidarium-wrapper',
            content: (
              <>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('NutritionExperts.description')
                    }}
                  />
                </Typography>
              </>
            )
          }
        ]}
      />
    </div>
  );
};

export default NutritionExperts;
