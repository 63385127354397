import { getProducts } from 'adapters/Axios/services/questionnaire.service';
import Session from 'adapters/Session';
import images from 'assets/images';
import BucketImage from 'components/BucketImage';
import Cover from 'components/Cover';
import PlanList from 'components/PlanList';
import ProductPack from 'components/ProductPack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography
} from 'ui/Material';
import './ApproachSelection.scss';

interface Props {}

const ApproachSelection: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();

  const [products, setProducts] = useState([]);
  const [secondaryProduct, setSecondaryProduct] = useState<any>();
  const [mainProduct, setMainProduct] = useState<any>();
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSecondaryProductSelection = (selected: any, product: any) => {
    setSecondaryProduct(selected ? null : product);
  };

  const renderCheckBox = (product: any, index?: number, isDefault?: boolean) => {
    const selected = product?.approach === secondaryProduct?.approach;
    return (
      <FormControl key={index} className="product-checkbox">
        <FormControlLabel
          control={
            <Checkbox
              data-testid="Checkbox"
              disabled={isDefault}
              checked={isDefault || selected}
              onClick={() => handleSecondaryProductSelection(selected, product)}
            />
          }
          label={
            <Typography>
              <BucketImage loading="lazy" alt="product icon" className="icon" src={product?.icon} />
              {product?.approach}
            </Typography>
          }
        />
      </FormControl>
    );
  };

  useEffect(() => {
    getProducts().then((response: any) => {
      setProducts(response.data);
      const mainProductObject = response.data.find((product: any) => product.main_product);
      setMainProduct(mainProductObject);
    });
  }, []);

  useEffect(() => {
    const productList: any = [mainProduct];

    if (secondaryProduct) {
      productList.push(secondaryProduct);
    }

    setSelectedProducts(productList);
  }, [mainProduct, secondaryProduct]);

  return (
    <div data-testid="ApproachSelection" className="ApproachSelection">
      <Session>
        {(session: any) => (
          <Cover
            title={t('ApproachSelection.user', session)}
            subtitle2={t('ApproachSelection.subtitle')}
            image={images.MentalHealth}
          />
        )}
      </Session>
      <Alert severity="success">{t('ApproachSelection.alert')}</Alert>
      <Card>
        <Typography variant="h5">{t('ApproachSelection.title')}</Typography>
        <Grid container display="flex" flexDirection="row">
          <Grid item md={6} sm={12} xs={12}>
            <Typography className="drink">{t('ApproachSelection.drinkRecomendation')}</Typography>
            <Typography>{t('ApproachSelection.select')}</Typography>
            <Typography className="gray">{t('ApproachSelection.recommended')}</Typography>
            <div className="main-product">{renderCheckBox(mainProduct, 0, true)}</div>
            <Typography className="gray disclaimer">{t('ApproachSelection.disclaimer')}</Typography>
            <Grid display="flex" flexDirection="column">
              {products
                .filter((product: any) => !product.main_product)
                .map((product: any, index: any) => renderCheckBox(product, index))}
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="product-wrapper">
            <ProductPack
              mainProduct={mainProduct?.approach}
              secondaryProduct={secondaryProduct?.approach}
            />
            <Typography className="gray disclaimer">{t('ApproachSelection.benefits')}</Typography>
          </Grid>
        </Grid>
      </Card>
      <div className="service-plans">
        <Typography variant="h5">
          <b>{t('ApproachSelection.planTitle')}</b>
        </Typography>
        <PlanList selectedProducts={selectedProducts} />
      </div>
    </div>
  );
};

export default ApproachSelection;
