import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputQuestion from 'components/InputQuestion';
import { useForm } from 'react-hook-form';
import { FormControl, FormHelperText, OutlinedInput } from 'ui/Material';
import { ListRegExp } from 'constants/pattern';
import * as accountService from 'adapters/Axios/services/account.service';
import { AccountSurveyStatus } from 'constants/accountStatus';

interface Props {
  onSubmit: any;
  controlProps?: any;
  onStatusCreated?: any;
  onStatusPending?: any;
  onStatusDone?: any;
}

const UserEmailForm: FC<Props> = (props) => {
  const { onSubmit, controlProps, onStatusCreated, onStatusPending, onStatusDone } = props;

  const { t }: { t: Function } = useTranslation();

  const [email, setEmail] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  watch((values) => {
    setEmail(values.email);
  });

  const validateEmail = (values: any) => {
    onSubmit(values);
  };

  const questionProps = {
    title: t('UserInfo.title'),
    description: t('UserInfo.description'),
    inputProps: {
      placeholder: t('UserInfo.email.inputPlaceholder'),
      ...register('email', {
        required: t('UserInfo.email.errors.invalid'),
        pattern: {
          value: ListRegExp.email,
          message: t('UserInfo.email.errors.invalid')
        }
      })
    },
    error: errors.email,
    onSubmit: handleSubmit(validateEmail)
  };

  return (
    <div className="UserEmailForm" data-testid="UserEmailForm">
      <InputQuestion {...questionProps} {...controlProps}>
        <FormControl error={!!errors.repeatEmail}>
          <OutlinedInput
            {...register('repeatEmail', {
              validate: {
                notSame: (repeatEmail) => repeatEmail === email,
                available: async () =>
                  accountService.validateEmail(email).then((response) => {
                    if (response.data.status === AccountSurveyStatus.CREATED) {
                      onStatusCreated && onStatusCreated();
                    }
                    if (response.data.status === AccountSurveyStatus.CREATED_SURVEY) {
                      onStatusPending && onStatusPending();
                    }
                    if (
                      response.data.status === AccountSurveyStatus.SUCCESS ||
                      response.data.status === AccountSurveyStatus.ACTIVATED
                    ) {
                      onStatusDone && onStatusDone();
                    }
                    return !response.data.status;
                  })
              }
            })}
            placeholder={t('UserInfo.repeatEmail.inputPlaceholder')}
          />
          {errors.repeatEmail?.type === 'notSame' && (
            <FormHelperText>{t('UserInfo.repeatEmail.errors.invalid')}</FormHelperText>
          )}
          {errors.repeatEmail?.type === 'available' && (
            <FormHelperText>{t('UserInfo.email.errors.generic')}</FormHelperText>
          )}
        </FormControl>
      </InputQuestion>
    </div>
  );
};

export default UserEmailForm;
