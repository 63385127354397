// eslint-disable-next-line react/prop-types

import React from 'react';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const sueno = `${REACT_APP_S3_BUCKET_URL}/circle/img/sueno.png`;
const agua = `${REACT_APP_S3_BUCKET_URL}/circle/img/3.png`;
const verduras = `${REACT_APP_S3_BUCKET_URL}/circle/img/Grupo 78059.png`;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RecommendationText from './RecommendationText';

const Col3 = ({ results = {}, questionResults = {}, questions = {} }) => {
  const { t } = useTranslation();

  const getDream = (value) => {
    if (value?.letter_indicator === 'e') return 'excelente';
    return 'esfuerzo';
  };

  return (
    <div style={{ width: '100%', padding: '0' }}>
      <div className="container-cols-recommendations">
        <div
          className="column-cols-recommendations"
          style={{
            width: '12rem',
            marginRight: '3rem'
          }}
        >
          <div
            className="card-container-recommendations"
            style={{
              backgroundColor: '#D7FEEE',
              height: '12 auto',
              width: '12rem',
              marginRight: '3rem'
            }}
          >
            <div className="card-container-recommendations-title">
              <div className="card-icon">
                <img src={agua} alt="Agua" />
              </div>
              <div className="card-title-recommendations">Agua</div>
            </div>

            <RecommendationText
              messages={t(`RecommendationsSection.VasosAgua.${Number(questionResults.P17.text)}`, {
                water: Number(results.water),
                returnObjects: true
              })}
            />
          </div>
        </div>

        <div
          className="column-cols-recommendations"
          style={{
            width: '12rem',
            marginRight: '3rem',
            padding: '0'
          }}
        >
          <div
            className="card-container-recommendations"
            style={{
              backgroundColor: '#FFEBE2',
              width: '12rem',
              height: '12 auto',
              marginRight: '3rem'
            }}
          >
            <div className="card-container-recommendations-title">
              <div className="card-icon">
                <img src={sueno} alt="Calidad del sueño" />
              </div>
              <div className="card-title-recommendations">Calidad del sueño</div>
            </div>

            <RecommendationText
              messages={t(
                `RecommendationsSection.CalidadSuenno.${getDream(
                  questions.P33[questionResults.P33.id_answer_option[0]]
                )}`,
                {
                  returnObjects: true
                }
              )}
            />
          </div>
        </div>
      </div>
      <div
        className="card-container-recommendations"
        style={{
          backgroundColor: '#D8E2DC',
          height: 'auto'
          // width: 'auto'
        }}
      >
        <div className="card-container-recommendations-title">
          <div className="card-icon">
            <img src={verduras} alt="Verduras" />
          </div>
          <div className="card-title-recommendations">Verduras</div>
        </div>

        <RecommendationText
          messages={t(
            `RecommendationsSection.verduras.${
              questions.P11[questionResults.P11.id_answer_option[0]].letter_indicator
            }`,
            {
              returnObjects: true
            }
          )}
        />
      </div>
    </div>
  );
};

Col3.propTypes = {
  results: PropTypes.object.isRequired,
  questionResults: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
};

export default Col3;
