import { createDiscountCode } from 'adapters/Axios/services/orders.service';
import WeightInput from 'components/Assessment/WeightInput';
import Button from 'components/Button';
import FixedInputPlaceholder from 'components/FixedInputPlaceholder';
import moment from 'moment';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormControl, FormGroup, FormHelperText, OutlinedInput, useTheme } from 'ui/Material';
import './DiscountCodeForm.scss';
import { formatStrings } from 'util/formatStrings';

interface DiscountCodeFormProps {
  onSuccess: any;
}
const DiscountCodeForm: FC<DiscountCodeFormProps> = ({ onSuccess }) => {
  const { t }: { t: Function } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (values: any) => {
    setIsLoading(true);
    createDiscountCode(values)
      .then(() => {
        reset({
          code: '',
          percentage: '',
          expirationDate: ''
        });
        onSuccess(values);
        toast.success('Código de descuento creado', {
          position: 'bottom-right',
          autoClose: 3000,
          draggable: false
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="DiscountCodeForm" data-testid="DiscountCodeForm">
      <FormGroup>
        {!isLoading && (
          <>
            <FormControl>
              <OutlinedInput
                error={!!errors.discountCode}
                placeholder={'Código de descuento'}
                {...register('code', {
                  required: t(`Payment.errors.required`)
                })}
              />
              <FormHelperText style={{ color: theme.palette.error.main }}>
                {formatStrings(errors.code?.message)}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <WeightInput
                maxValue={100}
                error={!!errors.discountCode}
                placeholder={'Porcentaje'}
                {...register('percentage', {
                  required: t(`Payment.errors.required`)
                })}
              />
              <FormHelperText style={{ color: theme.palette.error.main }}>
                {formatStrings(errors.percentage?.message)}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FixedInputPlaceholder
                type="date"
                placeholder={'Fecha de expiración'}
                {...register('expirationDate', {
                  required: t(`Payment.errors.required`)
                })}
                inputProps={{
                  min: moment().format('YYYY-MM-DD')
                }}
              />
              <FormHelperText style={{ color: theme.palette.error.main }}>
                {formatStrings(errors.expirationDate?.message)}
              </FormHelperText>
            </FormControl>
          </>
        )}
        <Button loading={isLoading} size="large" onClick={handleSubmit(onSubmit)}>
          {t('save')}
        </Button>
      </FormGroup>
    </div>
  );
};

export default DiscountCodeForm;
