import { FC } from 'react';
import images from 'assets/images';
import { Typography } from 'ui/Material';
import { ItemListDelivery } from 'models/orders';

import './ItemDoneList.scss';
import BucketImage from 'components/BucketImage';

interface ItemDoneListProps {
  index?: number;
  itemDelivery?: ItemListDelivery;
}

const ItemDoneList: FC<ItemDoneListProps> = ({ itemDelivery }: ItemDoneListProps) => {
  return (
    <div className="ItemDoneList" data-testid="ItemDoneList">
      <div className="icon-wrapper">
        {itemDelivery?.status === 'DELIVERIED' ? (
          <BucketImage loading="lazy" src={images.DoneIcon} alt="done" />
        ) : (
          <div className="not-done" />
        )}
      </div>
      <Typography variant="body1">
        {itemDelivery?.textDateDelivery} {itemDelivery?.dateDevlivery}
      </Typography>
    </div>
  );
};

export default ItemDoneList;
