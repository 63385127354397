import { FC } from 'react';
import './services/client';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children: JSX.Element;
};

const Provider: FC<Props> = ({ children }) => {
  return <>{children}</>;
};

export default Provider;
