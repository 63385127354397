import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'ui/Material';
import * as routes from 'navigation/routes';

import './GetPlan.scss';

export interface GetPlanProps {}

const GetPlan: React.FC<GetPlanProps> = () => {
  return (
    <div className="GetPlan" data-testid="GetPlan">
      <Typography variant="h2">¿Aún no cuentas con tu plan?</Typography>
      <Typography variant="h3">
        <Link to={`/auth${routes.approachSelection}`}>Activa ya tu suscripción</Link>
      </Typography>
    </div>
  );
};

export default GetPlan;
