import { FC } from 'react';
import { Typography } from 'ui/Material';
import LinkButton from 'components/LinkButton';
import './CenteredMessage.scss';
import BucketImage from 'components/BucketImage';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  linkTo?: string;
  linkLabel?: string;
  children?: any;
}

const CenteredMessage: FC<Props> = ({ image, title, description, linkTo, linkLabel, children }) => {
  return (
    <div data-testid="CenteredMessage" className="CenteredMessage vertical-form">
      {!!image && <BucketImage loading="lazy" alt={title} src={image} />}
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      {!!linkLabel && !!linkTo && (
        <LinkButton buttonProps={{ size: 'large' }} to={linkTo} label={linkLabel} />
      )}
      {children}
    </div>
  );
};

export default CenteredMessage;
