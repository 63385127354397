import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as routes from 'navigation/routes';
import settings from 'settings';
import moment from 'moment';
import {
  FormControl,
  FormGroup,
  OutlinedInput,
  FormHelperText,
  Typography,
  Alert
} from 'ui/Material';
import ModalWithIcon from 'components/ModalWithIcon';
import UrlCheckbox from 'components/UrlCheckbox';
import images from 'assets/images';
import './UserInfoForm.scss';
import PhoneNumberInput from 'components/PhoneNumberInput';
import FixedInputPlaceholder from 'components/FixedInputPlaceholder';
import Button from 'components/Button';
import { formatStrings } from 'util/formatStrings';

interface Props {
  onSubmit: any;
  defaultEmail?: string;
}

const UserInfoForm: FC<Props> = ({ onSubmit, defaultEmail }) => {
  const { t }: { t: Function } = useTranslation();

  const [ageLimitModalOpen, setAgeLimitModalOpen] = useState(false);

  const [warningVisible, setWarningVisible] = useState(false);

  const [userName, setUserName] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const validateAgeLimit = handleSubmit((value: any) => {
    if (moment().diff(moment(value.dateOfBirth, 'YYYY-MM-DD'), 'years') < settings.ageLimit) {
      setAgeLimitModalOpen(true);
      setUserName(value.firstName);
    } else if (!value.npp) {
      setWarningVisible(true);
    } else {
      setAgeLimitModalOpen(false);
      onSubmit(value);
    }
  });

  const renderFormControl = (name: string, validations?: any, disabled?: boolean) => (
    <FormControl error={!!errors[name]}>
      <OutlinedInput
        disabled={disabled}
        placeholder={t(`UserInfo.${name}.inputPlaceholder`)}
        {...register(name, validations)}
      />

      <FormHelperText>{formatStrings(errors[name]?.message)}</FormHelperText>
    </FormControl>
  );

  useEffect(() => {
    setValue('email', defaultEmail);
  }, [defaultEmail]);

  return (
    <div className="UserInfoForm vertical-form" data-testid="UserInfoForm">
      <Typography variant="h6">{t('UserInfo.title')}</Typography>
      <Typography variant="body1">{t('UserInfo.description')}</Typography>
      <FormGroup>
        {renderFormControl(
          'email',
          {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t('UserInfo.email.errors.invalid')
            }
          },
          true
        )}
        {renderFormControl('firstName', {
          required: t(`UserInfo.firstName.errors.invalid`)
        })}
        {renderFormControl('lastName', {
          required: t(`UserInfo.lastName.errors.invalid`)
        })}
        <FormControl error={!!errors.birthdate}>
          <FixedInputPlaceholder
            type="date"
            inputProps={{
              max: moment().format('YYYY-MM-DD')
            }}
            placeholder={t(`UserInfo.birthdate.inputPlaceholder`)}
            {...register('dateOfBirth', {
              required: t(`UserInfo.birthdate.errors.invalid`)
            })}
          />
          <FormHelperText>{formatStrings(errors.birthdate?.message)}</FormHelperText>
        </FormControl>
        <Typography variant="caption">{t(`UserInfo.birthdate.caption`)}</Typography>
        <FormControl error={!!errors.cellphone}>
          <PhoneNumberInput
            placeholder={t(`UserInfo.cellphone.inputPlaceholder`)}
            {...register('cellphone')}
          />
          <FormHelperText>{formatStrings(errors.cellphone?.message)}</FormHelperText>
        </FormControl>
        <UrlCheckbox translateKey="UserInfo.npp" checkboxProps={register('npp')}>
          Autorizo el tratamiento de{' '}
          <a rel="noreferrer" target="_blank" href={routes.nppFile}>
            datos personales
          </a>
        </UrlCheckbox>
        {warningVisible && <Alert severity="warning">{t('UserInfo.dataWarning')}</Alert>}
        <Button onClick={validateAgeLimit} size="large">
          {t('continue')}
        </Button>
      </FormGroup>
      <ModalWithIcon
        open={ageLimitModalOpen}
        image={images.AgeLimit}
        title={`¡${userName}!`}
        description={t('AgeLimit.message')}
        onClose={() => setAgeLimitModalOpen(false)}
      />
    </div>
  );
};

export default UserInfoForm;
