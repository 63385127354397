import BucketImage from 'components/BucketImage';
import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import './Cover.scss';

interface Props {
  image: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
}

const Cover: FC<Props> = ({ title, subtitle, subtitle2, image }) => {
  return (
    <div className="Cover" data-testid="Cover">
      <Grid container spacing={2}>
        <Grid item md={8} sm={12} xs={12}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
          <Typography variant="subtitle2">{subtitle2}</Typography>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <BucketImage loading="lazy" alt={title} src={image} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Cover;
