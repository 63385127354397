import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './index.css';
import Col1 from './Cards/';
import Col2 from './Cards/Col2';
import Col3 from './Cards/Col3';
import { DivInfo } from './div-info/DivInfo';
import { getFuncionalityOption } from 'containers/NewResults/actions';

const RecommendationCardsList = ({ results, questionResults, questions }) => {
  const { t } = useTranslation();

  const [getFuncionality, setFuncionality] = useState('');

  useEffect(() => {
    setFuncionality(getFuncionalityOption(results));
  }, [results]);

  function getValueP38() {
    const optionsFuncionality = ['digestive_health', 'energy'];
    const optionsLetterIndicator = ['g', 'h'];
    const message2 = questions.P38[questionResults.P38.id_answer_option[0]].description;
    const letterIndicator = questions.P38[questionResults.P38.id_answer_option[0]].letter_indicator;

    if (
      optionsFuncionality.includes(getFuncionality) &&
      optionsLetterIndicator.includes(letterIndicator)
    ) {
      return '';
    }

    return message2?.toString();
  }

  return (
    <div>
      <DivInfo
        data={[t(`HealthMetrics.focusValues.${getFuncionality}`), t(getValueP38())]}
      ></DivInfo>
      <div className="container-cols-recommendations">
        <div className="box1-recomendations">
          <div className="column-cols-recommendations">
            <Col1 results={results} questionResults={questionResults} questions={questions}></Col1>
          </div>

          <div className="column-cols-recommendations">
            <Col2 results={results} questionResults={questionResults} questions={questions}></Col2>
          </div>
        </div>
        <div className="column-cols-recommendations">
          <Col3 results={results} questionResults={questionResults} questions={questions}></Col3>
        </div>
      </div>
    </div>
  );
};

RecommendationCardsList.propTypes = {
  results: PropTypes.object.isRequired,
  questionResults: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
};

export default RecommendationCardsList;
