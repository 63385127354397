import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import images from 'assets/images';
import CenteredMessage from 'components/CenteredMessage';
import './SetPassword.scss';
import { Card, FormControl, FormGroup, FormHelperText, Grid, Typography } from 'ui/Material';
import { useForm } from 'react-hook-form';
import { usePasswordValidation } from 'hooks/password';
import { RadioButtonUnchecked, CheckCircleOutline } from 'ui/Material/icons';
import PasswordInput from 'components/PasswordInput';
import { requestActivationCode } from 'adapters/Axios/services/account.service';
import Button from 'components/Button';
import Session from 'adapters/Session';
import UserEmailForm from 'components/Assessment/UserEmailForm';
import EmailSent from 'containers/EmailSent';
import { formatStrings } from 'util/formatStrings';

interface Props {}

const SetPassword: FC<Props> = () => {
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const [isEmailFormEnabled, setIsEmailFormEnabled] = useState(false);

  const [newEmail, setNewEmail] = useState('');

  const { t }: { t: Function } = useTranslation();

  const { passwordValidation } = usePasswordValidation(password);

  const {
    register,
    watch,
    formState: { errors }
  } = useForm();

  watch((values) => setPassword(values.password));

  const renderValidationItem = (name: string) => (
    <Grid item flexDirection="row" display="flex" alignItems="center">
      {(passwordValidation as any)[name] ? (
        <CheckCircleOutline className="validation-icon checked" />
      ) : (
        <RadioButtonUnchecked className="validation-icon" />
      )}
      <Typography variant="caption">{t(`SetPassword.validations.${name}`)}</Typography>
    </Grid>
  );

  const handleOnSubmit = () => {
    setIsLoading(true);
    requestActivationCode(password, newEmail)
      .then(() => setEmailSent(true))
      .finally(() => setIsLoading(false));
  };

  const toggleEmailForm = () => setIsEmailFormEnabled(!isEmailFormEnabled);

  const handleEmailUpdate = (values: any) => {
    setNewEmail(values.email);
    toggleEmailForm();
  };

  const headProps = {
    image: images.Mobile,
    title: t('SetPassword.title'),
    description: t('SetPassword.description')
  };

  const isPasswordValid =
    passwordValidation.containsLowercaseLetters &&
    passwordValidation.containsNumbers &&
    passwordValidation.containsSymbols &&
    passwordValidation.containsUppercaseLetters &&
    passwordValidation.hasMinLength;

  const buttonProps = {
    disabled: !isPasswordValid,
    onClick: handleOnSubmit
  };

  if (emailSent) {
    return <EmailSent />;
  }

  return (
    <div data-testid="SetPassword" className="SetPassword">
      <CenteredMessage {...headProps}>
        {isEmailFormEnabled && (
          <Grid>
            <UserEmailForm
              controlProps={{
                title: '',
                description: ''
              }}
              onSubmit={handleEmailUpdate}
            />
            <Button variant="text" onClick={() => setIsEmailFormEnabled(false)}>
              CANCELAR
            </Button>
          </Grid>
        )}
        {!isEmailFormEnabled && (
          <Grid>
            <div className="update-email-wrapper">
              <Session>
                {(session: any) => (
                  <Typography variant="body1">
                    {t('SetPassword.email', {
                      email: newEmail || session.email
                    })}
                  </Typography>
                )}
              </Session>
              <Button onClick={toggleEmailForm} variant="text">
                {t('SetPassword.updateEmailButton')}
              </Button>
            </div>
            <FormGroup>
              <FormControl>
                <PasswordInput
                  placeholder={t('SetPassword.password.inputPlaceholder')}
                  {...register('password', {
                    required: t(`SetPassword.password.errors.invalid`)
                  })}
                />
                <FormHelperText>{formatStrings(errors.password?.message)}</FormHelperText>
              </FormControl>
              <Card>
                <Grid container flexDirection="column" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Typography>{t('SetPassword.validations.title')}</Typography>
                  </Grid>
                  {renderValidationItem('containsNumbers')}
                  {renderValidationItem('containsSymbols')}
                  {renderValidationItem('containsUppercaseLetters')}
                  {renderValidationItem('containsLowercaseLetters')}
                  {renderValidationItem('hasMinLength')}
                </Grid>
              </Card>
              <Button size="large" loading={isLoading} {...buttonProps}>
                {t('continue')}
              </Button>
            </FormGroup>
          </Grid>
        )}
      </CenteredMessage>
    </div>
  );
};

export default SetPassword;
