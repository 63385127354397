import Session from 'adapters/Session';
import images from 'assets/images';
import LinkButton from 'components/LinkButton';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Grid, Stack } from 'ui/Material';
import * as routes from 'navigation/routes';
import './SicknessWarning.scss';
import BucketImage from 'components/BucketImage';

interface Recomendation {
  name: string;
  image: string;
}

const SicknessWarning: FC = () => {
  const { t }: { t: Function } = useTranslation();

  const recomendations: Array<Recomendation> = [
    {
      name: 'physicalActivity',
      image: images.recomendations.physicalActivity
    },
    {
      name: 'hydration',
      image: images.recomendations.hydration
    },
    {
      name: 'fruitsAndVegetables',
      image: images.recomendations.fruits
    },
    {
      name: 'corn',
      image: images.recomendations.corn
    },
    {
      name: 'dairy',
      image: images.recomendations.dairy
    },
    {
      name: 'salt',
      image: images.recomendations.salt
    },
    {
      name: 'meat',
      image: images.recomendations.meat
    },
    {
      name: 'seeds',
      image: images.recomendations.seeds
    },
    {
      name: 'vitamins',
      image: images.recomendations.vitamins
    }
  ];

  const renderRecomendations = () =>
    recomendations.map((recomendation, index: number) => (
      <Grid container key={index} direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={2} textAlign="center">
          <BucketImage loading="lazy" alt={recomendation.name} src={recomendation.image} />
        </Grid>
        <Grid item sm={12} md={10} className="text-wrapper">
          <Typography className="title" variant="body2">
            {t(`SicknessWarning.${recomendation.name}`)}
          </Typography>
          <Typography variant="body2" className="gray">
            {t(`SicknessWarning.${recomendation.name}Description`)}
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <div className="SicknessWarning" data-testid="SicknessWarning">
      <Session>
        {(session: any) => (
          <Typography variant="h6">{t('SicknessWarning.name', session)}</Typography>
        )}
      </Session>
      <Typography variant="body1">{t('SicknessWarning.description')}</Typography>
      <Card>
        <Typography variant="body2" className="title">
          {t('SicknessWarning.recomendations')}
        </Typography>
        <Stack spacing={6}>{renderRecomendations()}</Stack>
        <LinkButton
          buttonProps={{ size: 'medium' }}
          to={routes.benetUrl}
          label={t('SicknessWarning.backButton')}
        />
      </Card>
    </div>
  );
};

export default SicknessWarning;
