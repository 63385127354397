import { FC } from 'react';
import { Typography } from 'ui/Material';
import './ViewSection.scss';

interface Props {
  children: JSX.Element | Array<JSX.Element>;
  title?: string;
}

const ViewSection: FC<Props> = ({ children, title }) => {
  return (
    <div className="ViewSection" data-testid="ViewSection">
      {!!title && <Typography variant="h4">{title}</Typography>}
      {children}
    </div>
  );
};

export default ViewSection;
