import { useTranslation } from 'react-i18next';
import ProcedureSteps from 'components/ProcedureSteps';
import images from 'assets/images';
import './Home.scss';
import ViewSection from 'components/ViewSection';
import ProductSlider from 'components/ProductSlider';
import NutritionExperts from 'components/NutritionExperts';
import ClientTestimonials from 'components/ClientTestimonials';
import HomeCover from 'components/HomeCover';
import LinkButton from 'components/LinkButton';
import * as routes from 'navigation/routes';

const Home = () => {
  const { t }: { t: Function } = useTranslation();
  const steps = [
    {
      title: t('Home.procedure.firstStep.title'),
      description: t('Home.procedure.firstStep.description'),
      image: images.procedure.FirstStep
    },
    {
      title: t('Home.procedure.secondStep.title'),
      description: t('Home.procedure.secondStep.description'),
      image: images.procedure.SecondStep
    },
    {
      title: t('Home.procedure.thirdStep.title'),
      description: t('Home.procedure.thirdStep.description'),
      image: images.procedure.ThirdStep
    }
  ];

  return (
    <div className="Home" data-testid="Home">
      <HomeCover />
      <ViewSection title={t('Home.procedure.title')}>
        <ProcedureSteps steps={steps} />
        <LinkButton label={t('Home.procedure.callToAction')} to={routes.assessmentStart} />
      </ViewSection>
      <ViewSection title={t('Home.products.title')}>
        <ProductSlider />
      </ViewSection>
      <ViewSection title={t('Home.experts.title')}>
        <NutritionExperts />
      </ViewSection>
      <ViewSection>
        <ClientTestimonials />
      </ViewSection>
    </div>
  );
};

export default Home;
