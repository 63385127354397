import { FC, useState } from 'react';
import { OutlinedInput } from 'ui/Material';
import './FixedInputPlaceholder.scss';

const FixedInputPlaceholder: FC<any> = (props) => {
  const { placeholder } = props;
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange({ target: { name: props.name, value: event.target.value || value } });
    }
  };

  return (
    <OutlinedInput
      className={`FixedInputPlaceholder ${value && 'filled'}`}
      data-testid="FixedInputPlaceholder"
      data-placeholder={placeholder}
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleChange}
    />
  );
};

export default FixedInputPlaceholder;
