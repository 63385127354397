import * as routes from 'navigation/routes';

const options = [
  {
    path: routes.home,
    label: 'Header.home'
  },
  {
    path: routes.aboutUs,
    label: 'Header.about_us'
  },
  {
    path: routes.products,
    label: 'Header.products'
  },
  {
    path: routes.blog,
    label: 'Header.blog'
  },
  {
    path: routes.faqs,
    label: 'Header.questions'
  }
];

export default options;
