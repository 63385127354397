import { FC, ReactElement } from 'react';
// import ThemeProvider from './Material/ThemeProvider';

type Props = {
  children: JSX.Element;
};

import { ReactNode } from 'react';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <>{children}</>;
};

const Providers: FC<Props> = ({ children }: { children: ReactElement }) => (
  <ThemeProvider>{children}</ThemeProvider>
);

export default Providers;
