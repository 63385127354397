import { FC } from 'react';
import MultipleImageOptionSelector from '../MultipleImageOptionSelector';
import MinuteCounter from 'components/MinuteCounter';
import './MultipleOptionWithMinuteCounter.scss';

interface Options {
  id: any;
  url_icon_selected?: string;
  url_icon?: string;
  text?: string;
}

interface Props {
  title: string;
  description: string;
  options: Array<Options>;
  onSubmit: any;
}

const MultipleOptionWithMinuteCounter: FC<Props> = (props) => {
  return (
    <div className="MultipleOptionWithMinuteCounter" data-testid="MultipleOptionWithMinuteCounter">
      <MultipleImageOptionSelector {...props} BoxComponent={MinuteCounter} />
    </div>
  );
};

export default MultipleOptionWithMinuteCounter;
