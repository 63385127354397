import { FC } from 'react';
import images from 'assets/images';
import CenteredMessage from 'components/CenteredMessage';
import './InProgress.scss';
import { CircularProgress } from 'ui/Material';

interface Props {}

const InProgress: FC<Props> = () => {
  const headProps = {
    image: images.Mobile,
    title: 'Estamos trabajando en tu plan',
    description: 'En unos segundos tendremos tu resultado'
  };

  return (
    <div data-testid="InProgress" className="InProgress">
      <CenteredMessage {...headProps}>
        <CircularProgress />
      </CenteredMessage>
    </div>
  );
};

export default InProgress;
