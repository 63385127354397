import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FloatingButton.css';
import images from '../../../../assets/images';

const FloatingButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/assessment/0');
  };

  return (
    <button className="floating-button" onClick={handleClick}>
      <img src={images.material.logout} alt="logout" />
    </button>
  );
};

export default FloatingButton;
