// prettier-ignore
/* eslint-disable */
import { FC, useState } from 'react';
import images from 'assets/images';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from 'ui/Material';
import { login as loginService, recoverPassword } from 'adapters/Axios/services/auth.service';
import { Login as LoginModel } from 'models/auth';
import { AccountAuth } from '../../models/auth';
import { AccountStatus } from 'constants/accountStatus';
import { AxiosError } from 'adapters/Axios/models/axiosModels';
import { StatusCodeLogin } from 'constants/statusCodeLogin';
import FormLogin from './components';
import * as routes from 'navigation/routes';
import ModalWithIcon from 'components/ModalWithIcon';
import './Login.scss';
import BucketImage from 'components/BucketImage';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const { t }: { t: Function } = useTranslation();

  const navigate = useNavigate();
  const { next } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [statusResponseFailureLogin, setStatusResponseFailureLogin] = useState<number | null>(null);

  const [statusResponseSuccessLogin, setStatusResponseSuccessLogin] = useState<number | null>(null);

  const [formLoginState, setFormLoginState] = useState<LoginModel>();

  const [isOpenModalRecoverPassword, setIsOpenModalRecoverPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<LoginModel>();

  watch((values: any, { name }) => {
    if (statusResponseFailureLogin === StatusCodeLogin.EMAIL_NOT_EXIST && name === 'email') {
      setStatusResponseFailureLogin(null);
    }
    if (statusResponseFailureLogin === StatusCodeLogin.INCORRECT_PASSWORD && name === 'password') {
      setStatusResponseFailureLogin(null);
    }
    setFormLoginState(values);
    setStatusResponseSuccessLogin(null);
  });

  const onLogin = (values: LoginModel) => {
    setFormLoginState(values);
    setIsLoading(true);
    loginService(values)
      .then((res) => handledSuccessResponseLogin(res.data, values))
      .catch((e) => handledFailureResponseLogin(e))
      .finally(() => setIsLoading(false));
  };

  const handledSuccessResponseLogin = (auth: AccountAuth, values: LoginModel) => {
    if (auth.status === AccountStatus.CREATED) {
      setStatusResponseSuccessLogin(AccountStatus.CREATED);
    }
    if (auth.status === AccountStatus.CREATED_SURVEY) {
      navigate(routes.accountByConfirm, { state: { email: values.email } });
    }
    if (auth.status === AccountStatus.SUCCESS || auth.status === AccountStatus.ACTIVATED) {
      navigate(next ? decodeURIComponent(next) : `/auth${routes.mainProfile}`);
    }
  };

  const handledFailureResponseLogin = (error: AxiosError) => {
    if (error.response?.status === StatusCodeLogin.EMAIL_NOT_EXIST) {
      setStatusResponseFailureLogin(error.response?.status);
    }
    if (error.response?.status === StatusCodeLogin.INCORRECT_PASSWORD) {
      setStatusResponseFailureLogin(error.response?.status);
    }
  };

  const onRecoverPassword = () => {
    if (formLoginState) {
      recoverPassword(formLoginState).then(() => setIsOpenModalRecoverPassword(true));
    }
  };

  return (
    <div className="Sign-in vertical-form" data-testid="Sign-in">
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className="wrapper-section">
              <BucketImage
                loading="lazy"
                className="image-top"
                src={images.login.phone}
                alt="login"
              />
              <FormLogin
                register={register}
                handledSubmit={handleSubmit(onLogin)}
                errors={errors}
                isLoading={isLoading}
                statusResponseFailureLogin={statusResponseFailureLogin}
                statusResponseSuccessLogin={statusResponseSuccessLogin}
                onRecoverPassword={onRecoverPassword}
              />
              <div className="wrapper-redirect">
                <Typography>{t('Login.sectionRedirect.accountCreateMessage')}</Typography>
                <Button className="button-outlined" variant="outlined" size="large">
                  <Link to={routes.assessmentStart}>{t('start')}</Link>
                </Button>
                <Typography>{t('Login.sectionRedirect.infoMessage')}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className="grid-right" item xs={false} md={6}>
          <BucketImage
            loading="lazy"
            className="image-right"
            src={images.login.person}
            alt="login"
            width="100%"
          />
        </Grid>
      </Grid>
      <ModalWithIcon
        open={isOpenModalRecoverPassword}
        onClose={() => setIsOpenModalRecoverPassword(false)}
        description={t('Login.modal.description')}
      />
    </div>
  );
};
/* eslint-enable */
export default Login;
