import Button from 'components/Button';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'ui/Material';
import './CookiesDialog.scss';

interface Props {
  onClick?: any;
}

const CookiesDialog: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', new Date().toString());
    handleClose();
  };

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');

    if (!cookiesAccepted) {
      setIsOpen(true);
    }
  }, [setIsOpen]);

  return (
    <div className="CookiesDialog" data-testid="CookiesDialog">
      <Snackbar
        open={isOpen}
        onClose={handleClose}
        message={t('CookiesDialog.message')}
        action={
          <Button onClick={handleAcceptCookies}>{t('CookiesDialog.acceptButtonText')}</Button>
        }
      />
    </div>
  );
};

export default CookiesDialog;
