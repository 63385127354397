import Button from 'components/Button';
import { QuestionItem } from 'models/questionnaire';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, TextField } from 'ui/Material';
import QuestionHeader from '../QuestionHeader';
import './MultipleOptionSelector.scss';

interface Option {
  id: any;
  text: string;
  use_input?: boolean;
  sub_questions: QuestionItem[];
}

interface Props {
  urlIcon?: string;
  title: string;
  numberQuestion?: any;
  defaultValue?: any;
  tooltip?: string;
  description?: string;
  options: Array<Option>;
  onSubmit: any;
  onChange?: any;
  SubItemsComponent?: any;
  subItemsComponentProps?: any;
  disabled?: boolean;
}

const MultipleOptionSelector: FC<Props> = ({
  title,
  description,
  defaultValue,
  numberQuestion,
  tooltip,
  options,
  disabled,
  onSubmit,
  onChange,
  urlIcon,
  SubItemsComponent,
  subItemsComponentProps
}) => {
  const { t }: { t: Function } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const [update, setUpdate] = useState(false);

  const handleOnClick = () => {
    onSubmit(selectedOptions);
  };

  const findSelectedOption = (option: any) =>
    selectedOptions.findIndex((selectedOption: any) => selectedOption.id === option.id);

  const handleToggleOption = (option: any) => {
    let optionsArray = selectedOptions;
    const indexOfSelectedItem = findSelectedOption(option);
    if (!option.excludes) {
      optionsArray = optionsArray.filter((opt: any) => !opt.excludes);
    }
    if (indexOfSelectedItem > -1) {
      optionsArray.splice(indexOfSelectedItem, 1);
    } else {
      if (option.excludes) {
        optionsArray = [];
      }
      optionsArray.push(option);
    }
    setSelectedOptions(optionsArray);
    setUpdate(!update);
  };

  const handleInputFieldChange = (event: any, option: any) => {
    const { value } = event.target;
    const optionsArray = selectedOptions.filter((opt: any) => !opt.text_answer_option);

    if (value) {
      optionsArray.push({ ...option, text_answer_option: value, text: value });
    }
    setSelectedOptions(optionsArray);
  };

  const renderOptions = () =>
    options.map((option, index) => {
      if (option.use_input) {
        return (
          <TextField
            key={option.text}
            onChange={(event) => handleInputFieldChange(event, option)}
            placeholder={option.text}
          />
        );
      }
      const isChecked = findSelectedOption(option) > -1;

      const buttonProps = {
        onClick: () => handleToggleOption(option),
        className: `white ${isChecked && 'checked'}`,
        'data-testid': 'MultipleOptionSelector-option'
      };

      return (
        <Grid item key={index} md={12} sm={12} xs={12}>
          <Button {...buttonProps}>
            <Checkbox checked={isChecked} /> {option.text}
          </Button>
          {SubItemsComponent &&
            isChecked &&
            option.sub_questions?.map((subQuestion, subIndex) => (
              <SubItemsComponent
                key={subIndex}
                {...subItemsComponentProps}
                {...subQuestion}
                options={subQuestion.sub_answer_options}
                defaultValue={(defaultValue?.sub_answers || []).find(
                  (answers: any) => answers.number_question === subQuestion.number_question
                )}
                onSubmit={(subOption: any) =>
                  subItemsComponentProps.onSubmit(subQuestion.number_question, subOption, index)
                }
              />
            ))}
        </Grid>
      );
    });

  useEffect(() => setSelectedOptions([]), [numberQuestion]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedOptions);
    }
  }, [selectedOptions]);

  const isButtonDisabled = !selectedOptions.length || disabled;

  useEffect(() => {
    if (defaultValue && !selectedOptions.length) {
      const defaultAnswers: any = [];
      defaultValue.id_answer_option.forEach((optionId: number) => {
        const defaultOption = options.find((optionItem: any) => optionItem.id === optionId);
        if (defaultOption) {
          defaultAnswers.push(defaultOption);
        }
      });
      setSelectedOptions(defaultAnswers);
      setUpdate(!update);
    }
  }, [defaultValue, options]);

  return (
    <div className="MultipleOptionSelector" data-testid="MultipleOptionSelector">
      <QuestionHeader title={title} description={description} image={urlIcon} tooltip={tooltip}>
        <Grid container spacing={1}>
          {renderOptions()}
        </Grid>
        <Button disabled={isButtonDisabled} onClick={handleOnClick}>
          {t('continue')}
        </Button>
      </QuestionHeader>
    </div>
  );
};

export default MultipleOptionSelector;
