const letters = 'a-zA-Z';
const uppercaseLetters = 'A-Z';
const lowercaseLetters = 'a-z';
const numbers = '0-9';
const lettersWithAccent = '\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u0178';
const alphanumeric = letters + numbers;
const alphabet = letters + lettersWithAccent;
const completeAlphanumeric = alphanumeric + lettersWithAccent;
const passwordSymbols = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/;

export const ListRegExp = {
  letters: new RegExp(`[${letters}]`),
  uppercaseLetters: new RegExp(`[${uppercaseLetters}]`),
  lowercaseLetters: new RegExp(`[${lowercaseLetters}]`),
  numbers: new RegExp(`[${numbers}]`),
  lettersWithAccent,
  alphanumeric,
  alphabet,
  completeAlphanumeric,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  onlyText: new RegExp(`^[${lowercaseLetters}\\s]+$`, 'i'),
  onlyNumber: new RegExp(`^[${numbers}]+$`),
  passwordSymbols
};
