import Button from 'components/Button';
import { FC } from 'react';
import { MdArrowBack } from 'react-icons/md';

import './BackButton.scss';

interface Props {
  onClick?: any;
}

const BackButton: FC<Props> = ({ onClick }) => (
  <div className="BackButton">
    <Button variant="text" onClick={onClick} data-testid="BackButton" startIcon={<MdArrowBack />}>
      Atrás
    </Button>
  </div>
);

export default BackButton;
