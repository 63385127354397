import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Login as LoginModel } from 'models/auth';
import { login as loginService } from 'adapters/Axios/services/auth.service';
import FormLogin from 'containers/Login/components';
import { useNavigate } from 'react-router-dom';
import * as routes from 'navigation/routes';
import './Login.scss';

const Login: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginModel>();

  const onLogin = (values: any) => {
    setIsLoading(true);
    loginService(values, true)
      .then(() => navigate(`${routes.adminPrefix}${routes.dashboard}`))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="Login" data-testid="Login">
      <FormLogin
        register={register}
        handledSubmit={handleSubmit(onLogin)}
        errors={errors}
        isLoading={isLoading}
        statusResponseFailureLogin={null}
        statusResponseSuccessLogin={null}
        onRecoverPassword={() => null}
      />
    </div>
  );
};

export default Login;
