// eslint-disable-next-line react/prop-types
import React from 'react';
import PropTypes from 'prop-types';

const RecommendationText = ({ messages }) => {
  return (
    <>
      {messages && Array.isArray(messages) ? (
        messages.map((message, index) => (
          <div key={index} className="line">
            <div className="card-value-text-recommendations">{message}</div>
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

RecommendationText.propTypes = {
  messages: PropTypes.any
};

export default RecommendationText;
