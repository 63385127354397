import BucketImage from 'components/BucketImage';
import Button from 'components/Button';
import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import './MoreProducts.scss';

interface MoreProductsProps {
  products: any;
  onClick: Function;
}

const MoreProducts: FC<MoreProductsProps> = ({ products, onClick }: MoreProductsProps) => {
  const gridProps = {
    container: true,
    spacing: 4
  };

  return (
    <div className="MoreProducts">
      <Typography variant="h6">Otros Productos</Typography>
      <Grid {...gridProps} data-testid="MoreProducts">
        {products.map((product: any, index: number) => (
          <Grid key={index} item md={3} sm={6} xs={12}>
            <BucketImage loading="lazy" alt={product.name} src={product.icon_packet} />
            <Typography fontWeight="medium">{product.name}</Typography>
            <Button
              data-testid="MoreButton"
              variant="outlined"
              size="medium"
              onClick={() => onClick(product)}
            >
              LEER MÁS
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MoreProducts;
