// eslint-disable-next-line react/prop-types

import React from 'react';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const corporal = `${REACT_APP_S3_BUCKET_URL}/circle/img/corporal.png`;
const frutas = `${REACT_APP_S3_BUCKET_URL}/circle/img/fruta.png`;

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import RecommendationText from './RecommendationText';

const Col2 = ({ results = {}, questionResults = {}, questions = {} }) => {
  const p5Value = questions.P5[questionResults.P5.id_answer_option[0]];

  const { t } = useTranslation();

  const getFruits = (value) => {
    if (['a', 'b'].includes(value)) return value;

    if (results.imc <= 24.9) return 'c1';
    return 'c2';
  };

  const getGCWomen = () => {
    let mensaje = '';
    if (p5Value.letter_indicator === 'a') {
      if (results.imc < 18.5) {
        mensaje = 'Mujer.baja';
      } else {
        mensaje = '';
      }
    } else if (p5Value.letter_indicator === 'b') {
      if (results.imc >= 18.5) {
        mensaje = 'Mujer.adecuado1';
      } else {
        mensaje = 'Mujer.adecuado2';
      }
    } else if (['a', 'b'].includes(p5Value.letter_indicator)) {
      mensaje = '';
    } else if (p5Value.letter_indicator === 'c') {
      mensaje = 'Límite alto de grasa corporal.';
    } else {
      mensaje = 'Mujer.obesidad';
    }

    return mensaje;
  };

  const getGCMenV2 = () => {
    if (p5Value.letter_indicator === 'a' && results.imc < 18.5) {
      return 'Hombre.baja';
    } else if (p5Value.letter_indicator === 'a' && results.imc >= 18.5) {
      return 'Hombre.NoRecommendation';
    } else if (p5Value.letter_indicator === 'b' && results.imc >= 18.5) {
      return 'Hombre.adecuado1';
    } else if (p5Value.letter_indicator === 'b' && results.imc < 18.5) {
      return 'Hombre.adecuado2';
    } else if (p5Value.letter_indicator === 'c' || p5Value.letter_indicator === 'd') {
      return 'Hombre.obesidad';
    }

    return 'Hombre.default';
  };

  function getGCResponse() {
    if (p5Value.text === 'No la conozco') return 'default';

    if (questions.P3[questionResults.P3.id_answer_option[0]].text === 'Mujer') return getGCWomen();
    return getGCMenV2();
  }

  return (
    <div>
      <div
        className="card-container-recommendations card-color-corporal"
        style={{ backgroundColor: '#EDF6F9' }}
      >
        <div className="card-container-recommendations-title">
          <div className="card-icon">
            <img src={corporal} alt="Grasa corporal" />
          </div>
          <div className="card-title-recommendations">Grasa corporal</div>
        </div>

        <RecommendationText
          messages={t(`RecommendationsSection.GC.${getGCResponse()}`, {
            returnObjects: true
          })}
        />
      </div>

      <div className="container-cols-recommendations">
        <div
          className="card-container-recommendations"
          style={{
            backgroundColor: '#F9FFD3',
            height: 'auto'
            // width: '100%'
          }}
        >
          <div className="card-container-recommendations-title">
            <div className="card-icon">
              <img src={frutas} alt="Frutas" />
            </div>
            <div className="card-title-recommendations">Frutas</div>
          </div>

          <RecommendationText
            messages={t(
              `RecommendationsSection.frutas.${getFruits(
                questions.P10[questionResults.P10.id_answer_option[0]].letter_indicator
              )}`,
              {
                returnObjects: true
              }
            )}
          />
        </div>
      </div>
    </div>
  );
};

Col2.propTypes = {
  results: PropTypes.object.isRequired,
  questionResults: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
};

export default Col2;
