import React, { FC, useEffect, useState } from 'react';
import { InputProps, OutlinedInput } from 'ui/Material';
import { IMaskInput } from 'react-imask';
import './HeightInput.scss';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props: any, ref: any) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="0.00"
      definitions={{
        '#': /[1-9]/
      }}
      data-testid="heightInput"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const HeightInput: FC<InputProps> = (props) => {
  const { placeholder } = props;
  const [value, setValue] = useState<any>('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const completeValue = () => {
    const maxValue = Number(value) < 3 ? value : 3;

    if (maxValue) {
      setValue(maxValue + '00');
    } else {
      setValue('');
    }
  };

  useEffect(() => setValue(''), []);

  useEffect(() => setValue(Number(props.value) ? props.value : ''), [props.value]);

  return (
    <div className="HeightInput" data-testid="HeightInput">
      <OutlinedInput
        {...props}
        onBlur={completeValue}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputComponent={TextMaskCustom}
      />
    </div>
  );
};

export default HeightInput;
