import PropTypes from 'prop-types';

import React, { useEffect, useRef } from 'react';

import './index.css';
import { drawChart } from './circle';

function Percent(props) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    drawChart(canvas, props.percent);
  }, [props.percent]);

  return (
    <div className="chart-container">
      <div className="chart">
        <div className="chart-text">
          <div className="contenedorPercent" id="contenedorPercent">
            {props.percent}%
          </div>
        </div>
        <canvas id="chartCanvas" width="200" height="200" ref={canvasRef}></canvas>
      </div>
    </div>
  );
}

Percent.propTypes = {
  percent: PropTypes.any.isRequired
};

export default Percent;
