import React from 'react';
const { REACT_APP_S3_BUCKET_URL } = process.env;

const facebookLogo = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/facebook.svg`;
const youtubeLogo = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/youtube.svg`;
const instagramLogo = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/instagram.svg`;
const whatsappLogo = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/whatsapp.svg`;
const logoBenet = `${REACT_APP_S3_BUCKET_URL}/web/images/footer/logo_benet_blanco.png`;

import './NewFooter.scss';

const NewFooter: React.FC = () => {
  return (
    <footer className="footer-container">
      <div
        style={{
          display: 'flex',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          width: '100%',
          height: '100%'
        }}
      >
        <div style={{ height: '122px', width: '585px', marginTop: '5px', paddingLeft: '5%' }}>
          <div
            className="redes"
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/benetnutricion">
                  {' '}
                  <img className="logos" src={facebookLogo} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/benetnutricion/"
                >
                  <img className="logos" src={instagramLogo} alt="instagram" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send/?phone=57&amp;text&amp;type=phone_number&amp;app_absent=0"
                >
                  <img className="logos" src={whatsappLogo} alt="whatsapp" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UC2XNz6qUU6hcOozox1pd1yA"
                >
                  <img className="logos" src={youtubeLogo} alt="youtube" />
                </a>
              </li>
            </ul>
          </div>
          <hr />
          <p>
            Todos los derechos reservados © |
            <a
              href="https://benet.com.co/terminos-y-condiciones"
              rel="noreferrer"
              target="_blank"
              style={{ color: 'white', textDecoration: 'none', marginLeft: '5px' }}
            >
              Términos y Condiciones
            </a>
            <a href="https://www.smdigital.com.co/" target="_blank" rel="noreferrer" className="by">
              <img
                alt="SMDigital"
                height={'15px'}
                src="https://benet.com.co/wp-content/themes/benet-colombia-ecommerce/library/images/by-smdigital.png"
              />
            </a>
          </p>
        </div>
        <div
          style={{
            height: '122px',
            width: '585px',
            margin: 'auto',
            paddingRight: '5%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <a href="https://benet.com.co/" rel="noreferrer" className="by">
            {process.env.REACT_APP_IS_VIDARIUM === 'true' ? (
              <></>
            ) : (
              <img src={logoBenet} width={'60%'} alt="logo" />
            )}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
