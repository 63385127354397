// eslint-disable-next-line react/prop-types
import React from 'react';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const indice = `${REACT_APP_S3_BUCKET_URL}/circle/img/indice.png`;
const ejercicio = `${REACT_APP_S3_BUCKET_URL}/circle/img/ejercicio.png`;

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import RecommendationText from './RecommendationText';

const Col1 = ({ results = {} }) => {
  const { t } = useTranslation();

  function getPhysicalActivityValues(activity) {
    if (activity >= 150) return 'excelente';
    if (activity >= 100 && activity <= 149) return 'bien';
    return 'esfuerzo';
  }

  function getIMCValues() {
    if (results.imc < 18.5) return '5';
    if (results.imc < 25) return '10';
    return 'default';
  }

  return (
    <div>
      <div className="card-container-recommendations card-color-corporal">
        <div className="card-container-recommendations-title">
          <div className="card-icon">
            <img src={indice} alt="indice corporal" />
          </div>
          <div className="card-title-recommendations">Índice de masa corporal</div>
        </div>

        <RecommendationText
          messages={t(`RecommendationsSection.imc.${getIMCValues()}`, {
            returnObjects: true
          })}
        />
      </div>

      <div
        className="card-container-recommendations"
        style={{
          backgroundColor: '#FFE5EC',
          height: 'auto',
          marginTop: '1.2rem'
        }}
      >
        <div className="card-container-recommendations-title">
          <div className="card-icon">
            <img src={ejercicio} alt="Ejercicio" />
          </div>
          <div className="card-title-recommendations">Ejercicio</div>
        </div>

        <RecommendationText
          messages={t(
            `RecommendationsSection.Ejercicio.${getPhysicalActivityValues(
              results.physical_activity.executed_minutes_per_week
            )}`,
            {
              returnObjects: true
            }
          )}
        />
      </div>
    </div>
  );
};

Col1.propTypes = {
  results: PropTypes.object.isRequired
};

export default Col1;
