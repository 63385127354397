import { FC, useEffect, useState } from 'react';
import Category from 'components/Assessment/Category';
import { QuestionnaireCategory } from 'models/questionnaire';
import Stepper from '../Stepper';
import { Grid } from 'ui/Material';
import { getAnswers } from 'adapters/Axios/services/questionnaire.service';
import { AxiosResponse } from 'adapters/Axios/models/axiosModels';
import './Categories.scss';
import TagManager from 'adapters/TagManager';
import Tag from 'react-gtm-module';

interface Props {
  value: Array<QuestionnaireCategory>;
  onFinish: Function;
  onQuestionSubmit: Function;
}

const Categories: FC<Props> = ({ value, onFinish, onQuestionSubmit }) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const [defaultCategoryIndex, setDefaultCategoryIndex] = useState(0);

  const [defaultQuestionIndex, setDefaultQuestionIndex] = useState(0);

  const [startBackwards, setStartBackwards] = useState(false);

  const [isRestored, setIsRestored] = useState(false);

  const [defaultAnswerValue, setDefaultAnswerValue] = useState<any>();

  const [answers, setAnswers] = useState([]);

  const clearDefaultAnswer = () => setDefaultAnswerValue(null);

  const categoriesMapper = (category: QuestionnaireCategory) => ({
    ...category,
    onQuestionSubmit
  });

  const fetchAnswers = () =>
    getAnswers().then((response: AxiosResponse) => {
      setAnswers(response.data);
    });

  const findAnswerPosition = (answer: any) => {
    let categoryPosition = 0;
    let questionPosition = 0;
    let answerValue;

    answer = Array.isArray(answer) ? answer[0] : answer;

    (value || []).forEach((category: any, categoryIndex: number) => {
      const questionIndex = category.questions.findIndex(
        (question: any) => question.number_question === answer?.number_question
      );

      if (questionIndex > -1) {
        categoryPosition = categoryIndex;
        questionPosition = questionIndex;
        answerValue = answer;
      }
    });
    setDefaultCategoryIndex(categoryPosition);
    setDefaultQuestionIndex(questionPosition);
    setDefaultAnswerValue(answerValue);
  };

  const handleOnQuestionChange = (question: any) => {
    window.scrollTo(0, 0);
    fetchAnswers();

    question = Array.isArray(question) ? question[0] : question;

    const defaultAnswer = answers.find(
      (answer: any) => answer.number_question === question?.number_question
    );

    if (defaultAnswer) {
      findAnswerPosition(defaultAnswer);
    } else {
      clearDefaultAnswer();
    }
  };

  const handleOnPrevStep = () => {
    setTimeout(() => {
      setStartBackwards(true);
    }, 200);
  };

  useEffect(() => {
    if (!value[currentCategoryIndex]) {
      onFinish();
    }
  }, [currentCategoryIndex]);

  useEffect(() => {
    fetchAnswers();
  }, [value]);

  useEffect(() => {
    const lastAnswer: any = answers[0];

    if (lastAnswer && !isRestored) {
      setIsRestored(true);
      Tag.dataLayer({
        dataLayer: {
          event: 'restoredAnswer'
        }
      });
      findAnswerPosition(lastAnswer);
    }
  }, answers);

  useEffect(() => {
    setStartBackwards(false);
  }, [startBackwards]);

  return (
    <Grid container className="Categories" data-testid="Categories">
      <TagManager />
      <Stepper
        steps={value}
        defaultStep={defaultCategoryIndex}
        onPrevStep={handleOnPrevStep}
        Component={Category}
        ComponentProps={{
          startBackwards: startBackwards,
          defaultQuestionIndex,
          defaultAnswerValue,
          defaultAnswers: answers,
          onQuestionChange: handleOnQuestionChange
        }}
        mapper={categoriesMapper}
        onStepChange={setCurrentCategoryIndex}
      />
    </Grid>
  );
};

export default Categories;
