import { FC } from 'react';
import { formatDnsImg } from 'util/formatStrings';

const BucketImage: FC<any> = (props) => {
  const { REACT_APP_S3_BUCKET_URL } = process.env;
  const src = (props.src || '').replace(/.*\.(com|co|net|assets)/, '');

  const urlBase = formatDnsImg(REACT_APP_S3_BUCKET_URL || '');

  console.log('urlBase', urlBase);

  return <img data-testid="BucketImage" {...props} src={urlBase + src} />;
};

export default BucketImage;
