import { getQuestionnaire } from 'adapters/Axios/services/questionnaire.service';
import BucketImage from 'components/BucketImage';
import { FC, useEffect, useState } from 'react';
import { Card, Step, StepLabel, Stepper } from 'ui/Material';
import './CategoryStepsIndicator.scss';

interface Props {
  currentCategory: string;
}

const CategoryStepsIndicator: FC<Props> = ({ currentCategory }) => {
  const [categories, setCategories] = useState<any>([]);

  const CustomStepIcon = (props: any) => {
    const { active, completed } = props;

    const category = (categories || [])[Number(props.icon) - 1];

    return (
      <BucketImage
        loading="lazy"
        className="icon"
        alt="step-icon"
        src={completed || active ? category?.url_icon_selected : category?.url_icon}
      />
    );
  };

  useEffect(() => {
    getQuestionnaire().then((response) => setCategories(response.data.categories));
  }, []);

  const activeStep = categories.findIndex((category: any) => category.name === currentCategory);

  return (
    <div className="CategoryStepsIndicator" data-testid="CategoryStepsIndicator">
      <Card>
        <Stepper activeStep={activeStep}>
          {categories.map((category: any, index: number) => {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={CustomStepIcon}>{category.name}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Card>
      <BucketImage
        loading="lazy"
        className="cover-image"
        alt="category"
        src={categories[activeStep]?.url_image}
      />
    </div>
  );
};

export default CategoryStepsIndicator;
