import React, { FC } from 'react';

interface Props {
  bgColor: any;
  metric: any;
  value: any;
  subtitle: any;
  isVisible: any;
}

const MetricCard: FC<Props> = ({
  bgColor,
  metric,
  value,
  subtitle = 'SABI',
  isVisible = false
}) => {
  return (
    <>
      <div className={`card-container ${bgColor}`}>
        <div className="card-title">{metric}</div>
        <div className={`card-subtitle ${isVisible ? '' : 'visibilityValue'}`}>{subtitle}</div>
        <div className="line">
          <div className="card-value">
            {typeof value === 'string' ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: value || ''
                }}
              />
            ) : (
              value
            )}
          </div>
          <div className="card-details"></div>
        </div>
      </div>
    </>
  );
};

interface Bar {
  bar: string;
  message: string;
  bgColor: string;
}

interface Subdescription {
  title: string;
  bars: Bar[];
}
export const DescriptionMetricCard: FC<Subdescription> = ({ title, bars }) => {
  return (
    <>
      <div className="description-info-v">
        <div className="title">{title}</div>

        <div className="card-list">
          {bars.map((item: any, index: any) => (
            <div key={index} className="b1">
              <div className={item.bar}></div>
              <div className="msg">{item.message}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MetricCard;
