import PhoneNumberInput from 'components/PhoneNumberInput';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Card,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Typography,
  Alert
} from 'ui/Material';
import { StickyContainer, Sticky } from 'react-sticky';
import {
  getDepartments,
  getShippingCoverage,
  saveOrder
} from 'adapters/Axios/services/questionnaire.service';
import './PaymentForm.scss';
import { useParams } from 'react-router-dom';
import images from 'assets/images';
import CurrencyFormat from 'components/CurrencyFormat';
import DiscountCode from 'components/DiscountCode';
import WompiCheckout from './components/WompiCheckout';
import { AxiosResponse } from 'adapters/Axios/models/axiosModels';
import LoadingScreen from 'components/LoadingScreen';
import * as routes from 'navigation/routes';
import TagManager from 'react-gtm-module';
import BucketImage from 'components/BucketImage';
import { formatStrings } from 'util/formatStrings';

interface Props {}

const PaymentForm: FC<Props> = () => {
  const { price: planPrice } = useParams();

  const [departments, setDepartments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [discount, setDiscount] = useState(0);

  const [unavailableShipping, setUnavailableShipping] = useState(false);

  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [selectedCity, setSelectedCity] = useState('');

  const [shippingPrice, setShippingPrice] = useState(0);

  const [cities, setCities] = useState([]);

  const [order, setOrder] = useState<any>();

  const { t }: { t: Function } = useTranslation();

  const documentTypes = [
    {
      value: 'CC',
      label: 'Cédula de ciudadanía'
    },
    {
      value: 'CE',
      label: 'Cédula de extranjería'
    },
    {
      value: 'PP',
      label: 'Pasaporte'
    }
  ];

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  watch((values: any) => {
    setSelectedDepartment(values.department);
    setSelectedCity(values.city);
  });

  useEffect(() => {
    getDepartments().then((response: any) =>
      setDepartments(
        response.data.map((department: any) => ({
          ...department,
          value: department.code,
          label: department.name
        }))
      )
    );
  }, []);

  useEffect(() => {
    const selectedDepartmentObject: any = departments.find(
      (department: any) => department.value === selectedDepartment
    );

    setCities(
      (selectedDepartmentObject?.cities || []).map((department: any) => ({
        value: department.code,
        label: department.name
      }))
    );
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment && selectedCity) {
      getShippingCoverage(selectedDepartment, selectedCity)
        .then((response: any) => {
          TagManager.dataLayer({
            dataLayer: {
              selectedDepartment,
              selectedCity,
              event: 'availableShipping'
            }
          });
          setUnavailableShipping(false);
          setShippingPrice(response.data);
        })
        .catch(() => {
          TagManager.dataLayer({
            dataLayer: {
              selectedDepartment,
              selectedCity,
              event: 'unavailableShipping'
            }
          });
          setUnavailableShipping(true);
          setShippingPrice(0);
        });
    }
  }, [selectedCity]);

  const renderFormControl = (name: string, validations?: any, disabled?: boolean) => (
    <Grid item md={6} sm={6} xs={12}>
      <FormControl error={!!errors[name]}>
        <OutlinedInput
          disabled={disabled}
          placeholder={t(`Payment.${name}.inputPlaceholder`)}
          {...register(name, validations)}
        />
        <FormHelperText>{formatStrings(errors[name]?.message)}</FormHelperText>
      </FormControl>
    </Grid>
  );

  const renderSelectControl = (
    name: string,
    options: Array<any>,
    validations?: any,
    disabled?: boolean
  ) => {
    const selectProps = {
      ...register(name, validations),
      disabled,
      'data-testid': name
    };

    return (
      <Grid item md={6} sm={6} xs={12}>
        <FormControl error={!!errors[name]}>
          <InputLabel>{t(`Payment.${name}.inputPlaceholder`)}</InputLabel>
          <Select {...selectProps}>
            {(options || []).map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const submitOrder = handleSubmit((values: any) => {
    setIsLoading(true);
    const department: any = departments.find(
      (departmentItem: any) => departmentItem.value === selectedDepartment
    );
    const city: any = cities.find((cityItem: any) => cityItem.value === selectedCity);

    saveOrder({
      ...values,
      department: department.label,
      city: city?.label
    })
      .then((response: AxiosResponse) => {
        if (values.discount_code) {
          TagManager.dataLayer({
            dataLayer: {
              order: {
                ...response.data,
                discountCode: values.discount_code
              },
              event: 'orderWithDiscountCode'
            }
          });
        } else {
          TagManager.dataLayer({
            dataLayer: {
              order: response.data,
              event: 'order'
            }
          });
        }
        setOrder(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const getDiscount = () => Number(planPrice) * (discount / 100);

  const getParcial = () => Number(planPrice) - getDiscount();

  const getTotals = () => getParcial() + shippingPrice;

  const countries = [
    {
      value: 'CO',
      label: 'Colombia'
    }
  ];

  const buttonProps = {
    disabled: !shippingPrice
  };

  const wompiProps = {
    buttonProps: buttonProps,
    onSubmit: submitOrder,
    idAccount: order?.idUser,
    idOrder: order?.id
  };

  return (
    <div data-testid="PaymentForm" className="PaymentForm vertical-form">
      <StickyContainer>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12}>
            <FormGroup>
              <BucketImage
                loading="lazy"
                alt="delivery icon"
                src={images.Delivery}
                className="delivery-icon"
              />
              <Typography variant="body1">
                <a href={routes.coverageFile} target="_blank" rel="noreferrer">
                  {t(`Payment.coverageLabel`)}
                </a>
              </Typography>
              <Typography variant="h5">Información de entrega</Typography>
              <Grid container spacing={2} display="flex" flexDirection="row" flexWrap="wrap">
                {renderFormControl('name', {
                  required: t(`Payment.name.errors.invalid`)
                })}
                {renderFormControl('sur_name', {
                  required: t(`Payment.sur_name.errors.invalid`)
                })}
                {renderSelectControl('document_type', documentTypes, {
                  required: t(`Payment.name.errors.invalid`)
                })}
                {renderFormControl('document', {
                  required: t(`Payment.name.errors.invalid`)
                })}
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl error={!!errors.phone}>
                    <PhoneNumberInput
                      placeholder={t(`Payment.phone.inputPlaceholder`)}
                      {...register('phone', {
                        required: t(`Payment.errors.required`),
                        minLength: 17
                      })}
                    />
                    <FormHelperText>{formatStrings(errors.phone?.message)}</FormHelperText>
                  </FormControl>
                </Grid>
                {renderSelectControl('country', countries, {
                  required: t(`Payment.errors.required`)
                })}
                {renderSelectControl('department', departments, {
                  required: t(`Payment.errors.required`)
                })}
                {renderSelectControl('city', cities, {
                  required: t(`Payment.errors.required`)
                })}
                {renderFormControl('direction', {
                  required: t(`Payment.errors.required`)
                })}
                {renderFormControl('postal_code')}
                {renderFormControl('complement')}
              </Grid>
              <Grid md={6} sm={6} xs={12}>
                <DiscountCode inputProps={register('discount_code')} onValidCode={setDiscount} />
              </Grid>
              {unavailableShipping && <Alert severity="error">{t('Payment.coverageError')}</Alert>}
            </FormGroup>
          </Grid>
          <Grid item md={4} sm={12} xs={12} className="sticky-wrapper">
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <Card className="resume-card">
                    <Typography variant="h6">Resumen de tu plan</Typography>
                    <Grid container>
                      <Grid md={12} sm={12} xs={12} display="flex" justifyContent="space-between">
                        <Typography className="gray">Valor del plan</Typography>
                        <CurrencyFormat value={planPrice} />
                      </Grid>
                      <Grid md={12} sm={12} xs={12} display="flex" justifyContent="space-between">
                        <Typography className="gray">Descuento</Typography>
                        <CurrencyFormat value={getDiscount()} />
                      </Grid>
                      <Grid md={12} sm={12} xs={12} display="flex" justifyContent="space-between">
                        <Typography className="gray">Total parcial</Typography>
                        <CurrencyFormat value={getParcial()} />
                      </Grid>
                      <Grid md={12} sm={12} xs={12} display="flex" justifyContent="space-between">
                        <Typography className="gray">Envío</Typography>
                        <CurrencyFormat value={shippingPrice} />
                      </Grid>
                      <hr />
                      <Grid md={12} sm={12} xs={12} display="flex" justifyContent="space-between">
                        <Typography className="gray">Valor total</Typography>
                        <CurrencyFormat value={getTotals()} />
                      </Grid>
                    </Grid>
                  </Card>
                  <div className="payment-methods">
                    <Typography variant="h6">Métodos de pago</Typography>
                    <div className="logos">
                      <BucketImage loading="lazy" alt="VISA" src={images.paymentMethods.visa} />
                      <BucketImage
                        loading="lazy"
                        alt="MASTERCARD"
                        src={images.paymentMethods.mastercard}
                      />
                      <BucketImage
                        loading="lazy"
                        alt="AMERICAN EXPRESS"
                        src={images.paymentMethods.american}
                      />
                      <BucketImage loading="lazy" alt="PSE" src={images.paymentMethods.pse} />
                    </div>
                  </div>
                </div>
              )}
            </Sticky>
          </Grid>
        </Grid>
        <Grid md={12} sm={12} xs={8} justifyContent="center" display="flex">
          <WompiCheckout {...wompiProps}>{t('Payment.order')}</WompiCheckout>
        </Grid>
      </StickyContainer>
      <LoadingScreen loading={isLoading} />
    </div>
  );
};

export default PaymentForm;
