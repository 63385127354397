import images from 'assets/images';
import * as routes from 'navigation/routes';
import LinkButton from 'components/LinkButton';
import BucketImage from 'components/BucketImage';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Typography } from 'ui/Material';
import TopCover from 'components/TopCover';
import './AboutUs.scss';

const AboutUs = () => {
  const { t }: { t: Function } = useTranslation();

  const experts = [
    {
      profile: images.nutritionist.Maximiliano,
      name: 'Maximiliano Kammerer López',
      profesion: 'Nutricionista Dietista. MSc. En Ciencias de la Nutrición y alimentación Humana',
      description:
        'Educador físico  Esp. En nutrición aplicada a la actividad física y el deporte. Docente Investigador y Fundador de la Maestría en nutrición deportiva de la Universidad CES. Nutricionista Asistencial Centro de rehabilitación REACT'
    },
    {
      profile: images.nutritionist.Isabel,
      name: 'Isabel Cristina Pulgarín Zapata',
      profesion:
        'Nutricionista Dietista y Magíster en Ciencias de la Alimentación y Nutrición Humana de la Universidad de Antioquia',
      description:
        'Desde hace 8 años tiene cargo de Especialista en Nutrición en Vidarium, Centro de Investigación en Alimentación, Salud y Bienestar de Grupo Nutresa.'
    },
    {
      profile: images.nutritionist.Ricardo,
      name: 'Ricardo Rosero Ravelo',
      profesion:
        'Médico internista de la Universidad de La Sabana con especialización en endocrinología en la Fundación Universitaria de Ciencias de la Salud.',
      description:
        'Experto en obesidad y metabolismo. Director del centro de Obesidad y Metabolismo Kylogrammo. Coordinador del comité de Obesidad y Miembro de Número de la Asociación Colombiana de Endocrinología.'
    }
    // ,
    // {
    //   profile: images.nutritionist.Gabriel,
    //   name: 'Gabriel Mesa Nicholls',
    //   profesion:
    //     'Médico de la Universidad CES, patólogo de la Universidad de Boston. Cuenta con MBA Ejecutivo de la Universidad de California en Los Ángeles (UCLA).',
    //   description:
    //     'Trabajó en la industria farmacéutica/biotecnológica en AMGEN en California y como Gerente General de EPS SURA en Colombia. Actualmente se desempeña como Director del área académica de Ciencias del Cuidado y de la Vida en la Universidad EAFIT.'
    // }
  ];
  return (
    <div className="AboutUs" data-testid="AboutUs">
      <TopCover image={images.aboutUs.Cover}>
        <Grid container direction="row">
          <Grid display="flex" md={4} sm={12} xs={12} direction="column" justifyContent="center">
            <Typography variant="h4" fontWeight="600">
              LYF, nutrición tan única como tú.
            </Typography>
            <Typography>
              Somos un equipo multidisciplinario compuesto por expertos y científicos con mas de 70
              años de experiencia en el mundo en los alimentos que entiende que cada cuerpo tiene
              particularidades y que por eso necesita productos enfocados en atenderlas.
            </Typography>
          </Grid>
        </Grid>
      </TopCover>
      <Grid container spacing={4} className="grid-item">
        <Grid item md={6} sm={6} xs={12}>
          <BucketImage loading="lazy" alt="nos importa tu salud" src={images.aboutUs.Health} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Typography variant="h5" fontWeight="600">
            Nos importa tu salud
          </Typography>
          <Typography variant="body1">
            Por eso creamos LYF Nutrición personalizada, porque queremos que tu nutrición sea tan
            única como tú y estamos obsesionados con hacerlo posible.
            <br />
            <br />A través de nuestra herramienta diseñada por expertos y científicos te conocemos y
            diseñamos para ti un plan de nutrición personalizada a la medida de tus necesidades y lo
            llevamos a la puerta de tu casa.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} flexDirection="row-reverse" className="grid-item">
        <Grid item md={6} sm={6} xs={12}>
          <BucketImage loading="lazy" alt="queremos conocerte" src={images.aboutUs.You} />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Typography variant="h5" fontWeight="600">
            Queremos conocerte
          </Typography>
          <Typography variant="body1">
            Conocerte es solo el principio, podrás ver tu progreso, conversar con especialistas y
            tener acceso a otros servicios que complementarán tu plan para que te sientas cada vez
            mejor.
            <br />
            <br />
            Tu salud es nuestra prioridad y sabemos que para ti también lo es, por eso te
            acompañamos y te damos todas las herramientas que tu necesitas para que puedas disfrutar
            tu vida al máximo.
            <br />
            <br />
            Nuestros productos están hechos con ingredientes que le ayudan a tu cuerpo a tener un
            mejor funcionamiento y te explicamos como lo hacen.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h5" fontWeight="600">
        Nuestro equipo de especialistas
      </Typography>
      <Typography variant="h4">Expertos en nutrición</Typography>
      <Grid container spacing={2} justifyContent="space-around">
        {experts.map((expert: any, index: number) => (
          <Grid key={index} item md={3} sm={6} xs={12}>
            <Card>
              <BucketImage loading="lazy" alt={expert.name} src={expert.profile} />
              <Typography variant="h5">{expert.name}</Typography>
              <Typography variant="caption">{expert.profesion}</Typography>
              <Typography className="description" variant="caption">
                {expert.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container flexDirection="column" className="experts-info">
        <Grid item>
          <Typography>{t('AboutUs.description')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">{t('AboutUs.testTitle')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{t('AboutUs.testDuration')}</Typography>
        </Grid>
        <Grid item>
          <Typography>{t('AboutUs.testBenefits')}</Typography>
        </Grid>
        <Grid item>
          <LinkButton to={routes.assessmentStart} label={t('AboutUs.testButton')} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUs;
