import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import images from 'assets/images';
import { useTranslation } from 'react-i18next';
import { Benefit } from 'models/product';

import './ProductBenefit.scss';
import BucketImage from 'components/BucketImage';

interface ProductBenefitProps {
  productName?: string;
  benefits?: Benefit[];
  color?: string;
  image?: string;
}

const ProductBenefit: FC<ProductBenefitProps> = ({
  productName,
  benefits,
  color = '#AEE2F3',
  image
}: ProductBenefitProps) => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="ProductBenefit" data-testid="ProductBenefit">
      <div className="background" style={{ backgroundColor: color }}>
        <BucketImage
          loading="lazy"
          alt="background decoration"
          className="image-1"
          src={images.BackgroundWhiteTop}
        />
        <BucketImage
          loading="lazy"
          alt="background decoration"
          className="image-2"
          src={images.BackgroundWhiteBottom}
        />
      </div>
      <Grid container>
        <Grid item md={4} sm={12} xs={12} alignItems="center">
          <div className="custom-packing">
            <BucketImage loading="lazy" alt="packing" src={image} />
          </div>
        </Grid>
        <Grid item md={8} sm={12} xs={12} rowSpacing={4}>
          <Typography variant="h3">{t('Products.ProductBenefit.title')}</Typography>
          <Grid container spacing={2}>
            {benefits?.map((benefit, index) => (
              <Grid key={index} item flexDirection="row" display="flex" md={6} sm={12} xs={12}>
                <Grid display="flex" alignItems="start" marginRight={2} md={'auto'} sm xs>
                  <BucketImage src={images.CheckIcon} loading="lazy" alt="check icon" />
                </Grid>
                <Grid item md={10} sm={10} xs={10}>
                  <Typography variant="body2" fontWeight={300} paddingTop={1}>
                    {benefit.description}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductBenefit;
