import { FC } from 'react';
import MultipleImageOptionSelector from '../MultipleImageOptionSelector';

interface Options {
  id: any;
  url_icon_selected?: string;
  url_icon?: string;
  text?: string;
}

interface Props {
  title: string;
  description: string;
  options: Array<Options>;
  onSubmit: any;
  BoxComponent?: any;
  boxComponentProps?: any;
}

const MultipleImageSingleOptionSelector: FC<Props> = (props) => {
  const wrapperProps = {
    className: 'MultipleImageSingleOptionSelector',
    'data-testid': 'MultipleImageSingleOptionSelector'
  };

  const options = props.options.map((option) => ({
    ...option,
    excludes: true
  }));

  return (
    <div {...wrapperProps}>
      <MultipleImageOptionSelector {...props} options={options} />
    </div>
  );
};

export default MultipleImageSingleOptionSelector;
