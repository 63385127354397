import Frame from 'components/Frame';
import { faqsUrl } from 'navigation/routes';
import './FAQs.scss';

const FAQs = () => {
  return (
    <div className="FAQs" data-testid="FAQs">
      <Frame src={faqsUrl} heightReference=".site-content" />
    </div>
  );
};

export default FAQs;
