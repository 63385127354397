import { FC, useEffect, useState } from 'react';
import { Grid, IconButton, OutlinedInput } from 'ui/Material';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import './MinuteCounter.scss';

interface Props {
  onChange: any;
  disabled?: boolean;
}

const MinuteCounter: FC<Props> = ({ onChange, disabled }) => {
  const { t }: { t: Function } = useTranslation();

  const [value, setValue] = useState(0);

  const handleAdd = () => {
    setValue(Number(value) + 10);
  };

  const handleSubtract = () => {
    if (value > 0) {
      const subtractResult = Number(value) - 10;
      setValue(subtractResult >= 0 ? subtractResult : 0);
    }
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  const handleOnChange = (e: any) => setValue(Number((e.target.value || '').replace(/\D/g, '')));

  useEffect(() => {
    if (disabled) {
      setValue(0);
    }
  }, [disabled]);

  return (
    <div className="MinuteCounter" data-testid="MinuteCounter">
      <Grid container spacing={1} justifyContent="center" alignItems="center" flexDirection="row">
        <Grid item>
          <IconButton disabled={disabled} onClick={handleSubtract} data-testid="subtract">
            <MdRemove />
          </IconButton>
        </Grid>
        <Grid item className="value-wrapper">
          <OutlinedInput
            data-testid="value"
            value={value}
            onChange={handleOnChange}
            disabled={disabled}
            placeholder={`0 ${t('MinuteCounter.suffix')}`}
          />
        </Grid>
        <Grid item>
          <IconButton disabled={disabled} onClick={handleAdd} data-testid="add">
            <MdAdd />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default MinuteCounter;
