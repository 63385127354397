import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './AdminLayout.scss';

interface Props {
  children?: JSX.Element;
}

const AdminLayout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const [viewClassName, setViewClassName] = useState('home');

  useEffect(() => {
    const { pathname } = window.location;

    setViewClassName(pathname.split('/').join(' ').trim() || 'home');
  }, [location]);

  return (
    <div className={`AdminLayout ${viewClassName}`} data-testid="AdminLayout">
      <div className="view-wrapper">{children}</div>
    </div>
  );
};

export default AdminLayout;
