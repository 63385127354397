function formatStrings(string) {
  return string || '';
}

function formatDnsImg(urlImage) {
  const ASSETS = 'assets';

  if (!urlImage.includes(ASSETS)) urlImage += ASSETS;
  return urlImage;
}

module.exports = {
  formatStrings,
  formatDnsImg
};
