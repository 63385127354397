import Session from 'adapters/Session';
import { FC } from 'react';
import TagInitialize from './TagInitialize';

const TagProvider: FC<any> = (props) => (
  <Session>
    {(session: any) => {
      return (
        <>
          <TagInitialize
            dataLayer={{
              session,
              ...props
            }}
          />
        </>
      );
    }}
  </Session>
);

export default TagProvider;
