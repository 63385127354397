import images from 'assets/images';
import Button from 'components/Button';
import CenteredMessage from 'components/CenteredMessage';
import PasswordInput from 'components/PasswordInput';
import { usePasswordValidation } from 'hooks/password';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, FormControl, FormGroup, FormHelperText, Grid, Typography } from 'ui/Material';
import { CheckCircleOutline, RadioButtonUnchecked } from 'ui/Material/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from 'adapters/Axios/services/auth.service';
import './ResetPassword.scss';
import { formatStrings } from 'util/formatStrings';

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = () => {
  const { t }: { t: Function } = useTranslation();

  const navigation = useNavigate();

  const { code } = useParams();

  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { passwordValidation } = usePasswordValidation(password);

  const {
    register,
    watch,
    formState: { errors }
  } = useForm();

  watch((values) => setPassword(values.password));

  const headProps = {
    image: images.Mobile,
    title: t('ResetPassword.title')
  };

  const isPasswordValid =
    passwordValidation.containsLowercaseLetters &&
    passwordValidation.containsNumbers &&
    passwordValidation.containsSymbols &&
    passwordValidation.containsUppercaseLetters &&
    passwordValidation.hasMinLength;

  const handleOnSubmit = () => {
    if (!code) {
      return;
    }
    const resetPasswordRequest = {
      code,
      password
    };
    setIsLoading(true);
    resetPassword(resetPasswordRequest)
      .then(() => navigation('/sign-in'))
      .finally(() => setIsLoading(false));
  };

  const buttonProps = {
    disabled: !isPasswordValid,
    onClick: handleOnSubmit
  };

  const renderValidationItem = (name: string) => (
    <Grid item flexDirection="row" display="flex" alignItems="center">
      {(passwordValidation as any)[name] ? (
        <CheckCircleOutline className="validation-icon checked" />
      ) : (
        <RadioButtonUnchecked className="validation-icon" />
      )}
      <Typography variant="caption">{t(`SetPassword.validations.${name}`)}</Typography>
    </Grid>
  );

  return (
    <div data-testid="ResetPassword" className="ResetPassword">
      <CenteredMessage {...headProps}>
        <Grid>
          <FormGroup>
            <FormControl>
              <PasswordInput
                placeholder={t('ResetPassword.form.passwordInput.placeholder')}
                {...register('password', {
                  required: t(`ResetPassword.form.passwordInput.errors.required`)
                })}
              />
              <FormHelperText>{formatStrings(errors.password?.message)}</FormHelperText>
            </FormControl>
            <Card>
              <Grid container flexDirection="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography>{t('SetPassword.validations.title')}</Typography>
                </Grid>
                {renderValidationItem('containsNumbers')}
                {renderValidationItem('containsSymbols')}
                {renderValidationItem('containsUppercaseLetters')}
                {renderValidationItem('containsLowercaseLetters')}
                {renderValidationItem('hasMinLength')}
              </Grid>
            </Card>
            <Button size="large" loading={isLoading} {...buttonProps}>
              {t('continue')}
            </Button>
          </FormGroup>
        </Grid>
      </CenteredMessage>
    </div>
  );
};

export default ResetPassword;
