import Frame from 'components/Frame';
import { blogUrl } from 'navigation/routes';
import { useParams } from 'react-router-dom';
import './Blog.scss';

const Blog = () => {
  const { url } = useParams();

  return (
    <div className="Blog" data-testid="Blog">
      <Frame src={url || blogUrl} heightReference=".site-content" />
    </div>
  );
};

export default Blog;
