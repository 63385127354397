export const index = '/';
export const dashboard = '/dashboard';
export const home = '/home';
export const assessment = '/assessment';
export const assessmentStep = '/assessment/:step';
export const assessmentStart = '/assessment-start';
export const signIn = '/sign-in';
export const signInNext = '/sign-in/:next';
export const accountByConfirm = '/account-by-confirm';
export const confirmAccount = '/confirm-account/:activationCode';
export const setPassword = '/set-password';
export const resetPassword = '/reset-password/:code';
export const blog = '/blog';
export const blogParams = '/blog/:url';
export const products = '/products';
export const faqs = '/faqs';
export const aboutUs = '/about-us';
export const results = '/new-results';
export const newResults = '/new-results';
export const approachSelection = '/approach-selection';
export const payment = '/payment/:price';
export const certificatePayment = '/certificate-payment/:reference';
export const privatePrefix = '/auth';
export const adminPrefix = '/admin';
export const mainProfile = '/main-profile';
export const orders = '/main-profile/orders';

const { REACT_APP_S3_BUCKET_URL } = process.env;

export const tcFile = `${REACT_APP_S3_BUCKET_URL}/tyc/BenetRecomendaci%C3%B3n_TYC_Sep2024.pdf`;
export const nppFile = `${REACT_APP_S3_BUCKET_URL}/tyc/AUTORIZACIÓN%20DE%20TRATAMIENTO%20DE%20DATOS%20PERSONALES.pdf`;
export const personalInfoPolicy = `https://data.gruponutresa.com/prosperidad/politica_de_tratamiento_de_datos_personales.pdf`;
export const coverageFile = `${REACT_APP_S3_BUCKET_URL}/docs/Cobertura.pdf`;

export const refundFile = `${REACT_APP_S3_BUCKET_URL}/docs/POLITICA+DE+VENTA%2C+GARANTIAS+Y+DEVOLUCIONES+LYF+NUTRICIO%CC%81N+PERSONALIZADA.pdf`;

export const usePrivatePrefix = (route: string) => `${privatePrefix}${route}`;

export const refundUrl = 'https://forms.gle/Va7CGLhRMJv6FmAG9';

export const blogUrl = 'https://blog.lyfnutricion.com';

export const faqsUrl = 'https://blog.lyfnutricion.com/faqs';

export const landingBuyUrl = 'https://landing.lyfnutricion.net/quiero-comprar/';

export const landingHelpUrl = 'https://landing.lyfnutricion.net/deseo-contactarme-con-un-asesor/';

export const benetUrl = 'https://benet.com.co/';
