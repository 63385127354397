import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import images from 'assets/images';
import CenteredMessage from 'components/CenteredMessage';
import './ConfirmAccount.scss';
import Session from 'adapters/Session';
import { activateAccount } from 'adapters/Axios/services/account.service';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from 'ui/Material';

interface Props {}

const ConfirmAccount: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();

  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const { activationCode } = useParams();

  useEffect(() => {
    if (activationCode && !isLoading) {
      setIsLoading(true);
      activateAccount(activationCode)
        .then(() => {
          setTimeout(() => {
            navigation(`/auth/approach-selection`);
          }, 3000);
        })
        .catch(() => setHasErrors(true))
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <div data-testid="ConfirmAccount" className="ConfirmAccount">
      <Session>
        {(session: any) => {
          let title = 'ConfirmAccount.title';

          if (isLoading) {
            title = 'ConfirmAccount.wait';
          } else if (hasErrors) {
            title = 'ConfirmAccount.error';
          }

          const messageProps = {
            image: images.Mobile,
            title: t(title),
            description: !isLoading && !hasErrors && t('ConfirmAccount.description', session)
          };

          return (
            <CenteredMessage {...messageProps}>{isLoading && <CircularProgress />}</CenteredMessage>
          );
        }}
      </Session>
    </div>
  );
};

export default ConfirmAccount;
