import * as React from 'react';
import { styled, Tabs } from 'ui/Material';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  scrollButtons?: 'auto' | false | true;
  'aria-label'?: string;
  allowScrollButtonsMobile?: boolean;
}

const StyledTabs: React.FC<StyledTabsProps> = styled((props: StyledTabsProps) => (
  <Tabs
    data-testid="StyledTabs"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'black'
  }
});

export default StyledTabs;
