import { FC, useEffect, useState } from 'react';
import { Grid } from 'ui/Material';
import QuestionHeader from 'components/Assessment/QuestionHeader';
import { useTranslation } from 'react-i18next';
import './SingleImageOptionSelector.scss';
import Button from 'components/Button';
import BucketImage from 'components/BucketImage';

interface Options {
  id: any;
  image?: string;
  imageDisabled?: string;
}

interface Props {
  title: string;
  description: string;
  options: Array<Options>;
  onSubmit: any;
  defaultValue?: any;
  tooltip?: string;
}

const SingleImageOptionSelector: FC<Props> = ({
  title,
  description,
  tooltip,
  options,
  onSubmit,
  defaultValue
}) => {
  const { t }: { t: Function } = useTranslation();

  const [selected, setSelected] = useState({
    id: null
  });

  const handleOnClick = () => {
    onSubmit(selected);
  };

  const renderButton = (option: any, index: number) => {
    const optionProps = {
      className: `option ${selected.id === option.id && 'selected '}`,
      key: index,
      item: true,
      md: 6,
      'data-testid': 'SingleImageOptionSelector-option',
      onClick: () => setSelected(option)
    };

    return (
      <Grid {...optionProps}>
        <BucketImage loading="lazy" alt="icon" src={option.url_icon_selected} />
        <BucketImage loading="lazy" alt="icon" className="disabled" src={option.url_icon} />
      </Grid>
    );
  };

  const renderOptions = () => options.map(renderButton);

  useEffect(() => {
    if (defaultValue?.id_answer_option) {
      setSelected(
        options.find((optionItem: any) => optionItem.id === defaultValue.id_answer_option[0]) ||
          selected
      );
    }
  }, [defaultValue]);

  return (
    <div className="SingleImageOptionSelector" data-testid="SingleImageOptionSelector">
      <QuestionHeader title={title} description={description} tooltip={tooltip}>
        <Grid className="option-wrapper" container spacing={1}>
          {renderOptions()}
        </Grid>
        <Button disabled={!selected?.id} onClick={handleOnClick}>
          {t('continue')}
        </Button>
      </QuestionHeader>
    </div>
  );
};

export default SingleImageOptionSelector;
