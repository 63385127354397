import BucketImage from 'components/BucketImage';
import React from 'react';
import { Grid, Typography } from 'ui/Material';

import './PlanFocus.scss';

interface PlanFocusProps {
  product?: string;
  image?: string;
}

const PlanFocus: React.FC<PlanFocusProps> = ({ product, image }: PlanFocusProps) => {
  return (
    <Grid className="PlanFocus" data-testid="PlanFocus" item xs={6} md={12}>
      {image && <BucketImage loading="lazy" src={image} alt="product focus" />}
      {product && <Typography>{product}</Typography>}
    </Grid>
  );
};

export default PlanFocus;
