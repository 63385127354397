import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import { useTranslation } from 'react-i18next';
import './NutritionInformation.scss';
import BucketImage from 'components/BucketImage';

interface NutritionInformationProps {
  productName?: string;
  productDescription?: string;
  ingredients?: string;
  urlTable?: string;
}

const NutritionInformation: FC<NutritionInformationProps> = ({
  productName,
  productDescription,
  ingredients,
  urlTable
}: NutritionInformationProps) => {
  const { t }: { t: Function } = useTranslation();

  const gridProps = {
    container: true,
    padding: { md: 4, sd: 4, xs: 0 },
    spacing: 6,
    className: 'NutritionInformation'
  };

  return (
    <Grid {...gridProps} data-testid="NutritionInformation">
      <Grid item md={7} sm={7} xs={12}>
        <Typography variant="h5" marginBottom={1}>
          {productName}
        </Typography>
        <Typography variant="body2" marginBottom={4}>
          <div
            dangerouslySetInnerHTML={{
              __html: productDescription || ''
            }}
          />
        </Typography>
        <Typography variant="h6" marginBottom={1}>
          {t('Products.NutritionInformation.ingredients')}
        </Typography>
        <Typography variant="body2" marginBottom={2}>
          <div
            dangerouslySetInnerHTML={{
              __html: ingredients || ''
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={5} sm={5} xs={12}>
        {urlTable && <BucketImage loading="lazy" src={urlTable} alt="nutritional table" />}
      </Grid>
    </Grid>
  );
};

export default NutritionInformation;
