import React, { FC, useState } from 'react';
import { InputProps, OutlinedInput, Typography } from 'ui/Material';
import { IMaskInput } from 'react-imask';
import './PhoneNumberInput.scss';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props: any, ref: any) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 000 000 0000"
      definitions={{
        '#': /[1-9]/
      }}
      data-testid="phoneInput"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const PhoneNumberInput: FC<InputProps> = (props) => {
  const { placeholder } = props;
  const [value, setValue] = useState('(57)');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur({
        ...event,
        target: {
          ...event.target,
          value: event.target.value
        }
      });
    }
  };

  return (
    <div className={`PhoneNumberInput ${!!value && 'filled'}`} data-testid="PhoneNumberInput">
      <Typography variant="caption">{placeholder}</Typography>
      <OutlinedInput
        {...props}
        placeholder={!value ? placeholder : ''}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        inputComponent={TextMaskCustom}
      />
    </div>
  );
};

export default PhoneNumberInput;
