import client from './client';
import { AxiosResponse } from 'axios';
import { AccountAuth, Login, ResetPasswordRequest } from 'models/auth';
import { localAuthService } from 'adapters/Session/services/localAuth.service';
import { validateEmail } from './account.service';

export const login = (loginRequest: Login, admin?: boolean) => {
  return new Promise<AxiosResponse<AccountAuth>>((resolve, reject) => {
    client
      .post(`accounts/ms/v1/login`, {
        ...loginRequest,
        admin
      })
      .then((response: AxiosResponse<AccountAuth>) => {
        validateEmail(loginRequest.email)
          .then(() => {
            if (response.data?.accessToken) {
              localAuthService.setAuth(response.data);
            }
            resolve(response);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

export const recoverPassword = (loginRequest: Login) =>
  client.post(`accounts/ms/v1/accounts/forgot-password`, loginRequest);

export const resetPassword = (resetPasswordRequest: ResetPasswordRequest) =>
  client.post(`accounts/ms/v1/accounts/change-password`, resetPasswordRequest);
