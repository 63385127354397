import * as React from 'react';
import { Box } from 'ui/Material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  const defaultParams = {
    role: 'tabpanel',
    hidden: value !== index,
    id: `simple-tabpanel-${index}`,
    'aria-labelledby': `simple-tab-${index}`,
    'data-testid': 'TabPanel'
  };

  return (
    <div {...defaultParams} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
