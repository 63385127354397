import { FC, useEffect, useState } from 'react';
import {
  Stack,
  FormControl,
  InputProps,
  OutlinedInput,
  FormGroup,
  FormHelperText,
  FormControlProps
} from 'ui/Material';
import { ErrorOption } from 'react-hook-form';
import QuestionHeader from 'components/Assessment/QuestionHeader';
import { useTranslation } from 'react-i18next';
import HeightInput from 'components/Assessment/HeightInput';
import WeightInput from 'components/Assessment/WeightInput';

import './InputQuestion.scss';
import Button from 'components/Button';

interface Props {
  title: string;
  defaultValue?: any;
  description: string;
  tooltip?: string;
  image?: string;
  inputProps?: InputProps;
  controlProps?: FormControlProps;
  error?: ErrorOption;
  onSubmit?: any;
  children?: any;
  baseUnit?: string;
}

const InputQuestion: FC<Props> = ({
  image,
  title,
  defaultValue,
  description,
  tooltip,
  inputProps,
  controlProps,
  error,
  onSubmit,
  children,
  baseUnit
}) => {
  const { t }: { t: Function } = useTranslation();

  const [value, setValue] = useState<string>('');

  const stackProps = {
    className: 'InputQuestion',
    'data-testid': 'InputQuestion'
  };

  const buttonProps = {
    disabled: !value,
    onClick: onSubmit
  };

  const handleOnChange = (event: any) => {
    setValue(event.target?.value);
    if (inputProps?.onChange) {
      inputProps.onChange(event);
    }
  };

  let InputComponent;

  switch (baseUnit) {
    case 'm':
      InputComponent = HeightInput;
      break;
    case 'kg':
      InputComponent = WeightInput;
      break;
    default:
      InputComponent = OutlinedInput;
      break;
  }

  useEffect(() => {
    setValue(defaultValue?.text || '');
  }, [defaultValue]);

  return (
    <Stack {...stackProps} justifyContent="center" alignItems="center">
      <QuestionHeader title={title} description={description} image={image} tooltip={tooltip}>
        <FormGroup>
          <FormControl error={!!error} {...controlProps}>
            <InputComponent
              placeholder={baseUnit && `0 ${baseUnit}`}
              value={value}
              {...inputProps}
              inputProps={{
                'data-testid': 'input-question'
              }}
              onChange={handleOnChange}
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
          {children}
          <Button type="submit" size="large" {...buttonProps}>
            {t('next')}
          </Button>
        </FormGroup>
      </QuestionHeader>
    </Stack>
  );
};

export default InputQuestion;
