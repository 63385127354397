import Button from 'components/Button';
import { FC } from 'react';
import { Stack } from 'ui/Material';
import QuestionHeader from '../QuestionHeader';

import './ClosedQuestion.scss';

interface Option {
  id: any;
  text: string;
  image?: string;
  imageDisabled?: string;
}

interface Props {
  defaultValue?: any;
  urlIcon?: string;
  title: string;
  description: string;
  onSubmit: Function;
  options: Array<Option>;
  tooltip?: string;
}

const ClosedQuestion: FC<Props> = ({
  defaultValue,
  urlIcon,
  title,
  tooltip,
  description,
  onSubmit,
  options
}) => {
  const handleClick = (value: any) => onSubmit(value);

  const defaultValueId = Array.isArray(defaultValue?.id_answer_option)
    ? defaultValue?.id_answer_option[0]
    : defaultValue?.id_answer_option;

  const renderOptions = () =>
    options.map((option: Option, index) => {
      const buttonProps = {
        className: `white ${option.id === defaultValueId && 'selected'}`,
        onClick: () => handleClick(option)
      };
      return (
        <Button key={index} size="large" {...buttonProps}>
          {option.text}
        </Button>
      );
    });

  return (
    <div className="ClosedQuestion" data-testid="ClosedQuestion">
      <QuestionHeader title={title} description={description} image={urlIcon} tooltip={tooltip}>
        <Stack className="options" direction="row" justifyContent="space-evenly">
          {renderOptions()}
        </Stack>
      </QuestionHeader>
    </div>
  );
};

export default ClosedQuestion;
