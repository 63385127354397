import client from './client';
import { ProductTrackingModel } from '../../../models/orders';
import { OrderHistoryModel } from 'models/orders';

export const getOrdersHistory = (idAccount?: string) => {
  return client.get<OrderHistoryModel[]>(`orders/ms/v1/orders/${idAccount}`);
};

export const getProductTracking = (idAccount?: string, idOrder?: number) => {
  return client.get<ProductTrackingModel>(`orders/ms/v1/orders/deliveries/${idAccount}/${idOrder}`);
};

export const getCheckoutInfo = (idAccount?: string, idOrder?: number) => {
  return client.get(`payments/ms/v1/payments/integration/${idAccount}/${idOrder}`);
};

export const getPaymentStatus = (reference: string) => {
  return client.get(`payments/ms/v1/payments/${reference}/status`);
};

export const createDiscountCode = (data: any) => {
  return client.post(`orders/ms/v1/coupon`, data);
};
