import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

interface Props {
  dataLayer: any;
}

const TagInitialize: FC<Props> = ({ dataLayer }) => {
  useEffect(() => {
    if ((window as any).dataLayer) {
      TagManager.dataLayer({
        dataLayer
      });
    } else {
      ReactGA.initialize('G-GMKJ1NH8EZ');

      TagManager.initialize({
        gtmId: 'GTM-WHW32HX',
        dataLayer
      });
    }
  }, [dataLayer]);

  return <></>;
};

export default TagInitialize;
