import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Grid } from 'ui/Material';
import ProgressBar from 'components/ProgressBar';
import CheckPoint from 'components/Assessment/CheckPoint';
import { QuestionnaireCategory } from 'models/questionnaire';
import Stepper from '../Stepper';
import QuestionWrapper from '../QuestionWrapper';
import './Category.scss';
import TagManager from 'react-gtm-module';

interface CategoryProps extends QuestionnaireCategory {
  onSubmit: Function;
  onQuestionSubmit: Function;
  onQuestionChange: Function;
  defaultQuestionIndex?: number;
  defaultAnswerValue?: any;
  defaultAnswers?: any;
  startBackwards?: boolean;
}

const Category: FC<CategoryProps> = ({
  name,
  questions,
  defaultQuestionIndex,
  defaultAnswerValue,
  defaultAnswers,
  onQuestionChange,
  onSubmit,
  onQuestionSubmit,
  startBackwards
}) => {
  const { t }: { t: Function } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [answers, setAnswers] = useState<any>({});

  const handleOnStepChange = (index: number) => {
    setCurrentQuestion(index);
  };

  const handleOnSubmit = (...params: any) => {
    const question = params[0];
    const answer = params[1];

    TagManager.dataLayer({
      dataLayer: {
        event: 'answer',
        question
      }
    });

    setAnswers({
      ...answers,
      [question.number_question]: {
        question,
        answer: {
          id: Array.isArray(answer) ? answer.map((item) => item.id) : [answer.id]
        }
      }
    });

    filterQuestions();

    onQuestionSubmit(...params);
  };

  const questionsMapper = (question: any) => ({
    ...question,
    name,
    icon: question.url_icon,
    selectedIcon: question.url_icon_selected,
    cover: question.url_image
  });

  const useConditions = (conditionItem: any) => {
    const { condition_type: conditionType, question, option_id: optionId } = conditionItem;
    if (answers[question]) {
      if (conditionType === 'equals') {
        return (answers[question]?.answer?.id || []).find(
          (answerId: any) => String(answerId) === String(optionId)
        );
      }
      if (conditionType === 'excludes') {
        return !!(answers[question]?.answer?.id || []).find((answerId: any) =>
          optionId.split(',').includes(String(answerId))
        );
      }
    }
    return false;
  };

  const filterByConditions = (conditions: any) => {
    conditions = (conditions || []).map((condition: any) => useConditions(condition));

    return !conditions.filter((condition: boolean) => condition).length;
  };

  const filterOptions = (optionList: any) =>
    (optionList || []).filter((optionItem: any) => filterByConditions(optionItem.conditions));

  const filterQuestions = () => {
    const questionList = JSON.parse(JSON.stringify(questions || []));

    const filteredQuestionList = questionList
      .filter((questionItem: any) => filterByConditions(questionItem.conditions))
      .map((questionItem: any) => {
        questionItem.answer_options = filterOptions(questionItem.answer_options);

        return questionItem;
      })
      .reduce((group: any, question: any) => {
        const { position } = question;
        group[position] = group[position] ?? [];
        group[position].push(question);
        return group;
      }, [])
      .filter((item: any) => item);

    setFilteredQuestions(filteredQuestionList);
  };

  useEffect(() => {
    const currentQuestionItem: any = (filteredQuestions || [])[currentQuestion];
    if (filteredQuestions.length && !currentQuestionItem) {
      onSubmit();
    }
    onQuestionChange(currentQuestionItem);
  }, [currentQuestion]);

  useEffect(() => {
    filterQuestions();
  }, [questions, answers]);

  useEffect(() => {
    let answersList = {
      ...answers
    };
    if (defaultAnswers) {
      defaultAnswers.forEach((answer: any) => {
        answersList = {
          ...answersList,
          [answer.number_question]: {
            answer: {
              id: answer.id_answer_option,
              sub_answers: answer.sub_answers
            }
          }
        };
      });
    }

    setAnswers(answersList);
  }, [defaultAnswers]);

  return (
    <Grid container flexDirection="column" className="Category" data-testid="Category">
      <div className="top-wrapper">
        <ProgressBar
          name={name}
          numberOfSteps={filteredQuestions.length}
          currentStep={currentQuestion + 1}
        />
        {currentQuestion === 0 && <Alert severity="success">{t('Category.restoreMessage')}</Alert>}
      </div>
      <Stepper
        steps={filteredQuestions}
        defaultStep={defaultQuestionIndex}
        Component={QuestionWrapper}
        startBackwards={startBackwards}
        ComponentProps={{
          defaultValue: defaultAnswerValue
        }}
        mapper={questionsMapper}
        onStepChange={handleOnStepChange}
        onSubmit={handleOnSubmit}
        CheckPointComponent={CheckPoint}
      />
    </Grid>
  );
};

export default Category;
