import { FC } from 'react';
import ImageCover from '../ImageCover';
import CategoryStepsIndicator from '../CategoryStepsIndicator';
import { Typography } from 'ui/Material';
import { useTranslation } from 'react-i18next';
import './CheckPoint.scss';
import Button from 'components/Button';

interface Props {
  onSubmit: any;
  name: string;
  cover: string;
}

const CheckPoint: FC<Props> = ({ onSubmit, name, cover }) => {
  const { t }: { t: Function } = useTranslation();

  const coverProps = {
    image: cover,
    TopComponent: CategoryStepsIndicator,
    topComponentProps: { currentCategory: name }
  };

  return (
    <div className="CheckPoint" data-testid="CheckPoint">
      <ImageCover {...coverProps}>
        <Typography variant="h6">{name}</Typography>
        <Button onClick={onSubmit} size="large">
          {t('continue')}
        </Button>
      </ImageCover>
    </div>
  );
};

export default CheckPoint;
