import Button from 'components/Button';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps } from 'ui/Material';
import * as routes from 'navigation/routes';

interface Props {
  buttonProps?: ButtonProps;
  to: string;
  label: string;
}

const LinkButton: FC<Props> = ({ buttonProps, to, label }) => (
  <Button variant="contained" className="LinkButton" data-testid="LinkButton" {...buttonProps}>
    {to === routes.benetUrl ? (
      <a href={to} rel="noopener noreferrer" className="logo-wrapper">
        {label}
      </a>
    ) : (
      <Link to={to}>{label}</Link>
    )}
  </Button>
);

export default LinkButton;
