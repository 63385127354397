import { FC } from 'react';
import { Typography } from 'ui/Material';
import LinkButton from 'components/LinkButton';
import images from 'assets/images';
import * as routes from 'navigation/routes';
import './HomeCover.scss';
import TopCover from 'components/TopCover';

interface Props {}

const HomeCover: FC<Props> = () => {
  return (
    <div className="HomeCover" data-testid="HomeCover">
      <TopCover image={images.HomeCover}>
        <Typography variant="h1">
          Nutrición personalizada <br />
          para una vida saludable
        </Typography>
        <Typography variant="h5">
          Un plan a tu medida con el acompañamiento <br />
          de expertos en nutrición 24/7
        </Typography>
        <Typography>
          Realiza nuestro test para crear tu plan personalizado junto a nuestros expertos
        </Typography>
        <LinkButton buttonProps={{ size: 'medium' }} to={routes.assessmentStart} label="COMENZAR" />
        <Typography variant="caption">Te tomará 9 minutos responderlo</Typography>
      </TopCover>
    </div>
  );
};

export default HomeCover;
