import {
  getProductsList,
  getServicePlan,
  updateServicePlan,
  getDiscountCodeList
} from 'adapters/Axios/services/questionnaire.service';
import Button from 'components/Button';
import Editor from 'components/Editor';
import React, { FC, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardProps,
  Grid,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from 'ui/Material';
import images from 'assets/images';
import './Dashboard.scss';
import DiscountCodeForm from '../DiscountCodeForm/DiscountCodeForm';
import { updateProduct } from 'adapters/Axios/services/product.service';
import LoadingScreen from 'components/LoadingScreen';
import BucketImage from 'components/BucketImage';

interface Props extends CardProps {
  onSelected: any;
  onCancel: any;
  onSave: any;
  description: string;
  isSelected: boolean;
  isPlanEdit?: boolean;
  value: any;
  price?: string;
  ingredients?: string;
}

const CardItem: FC<Props> = ({
  title,
  price,
  ingredients,
  onSelected,
  onCancel,
  onSave,
  description,
  isSelected,
  isPlanEdit,
  onChange,
  value,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newDescription, setNewDescription] = useState(description);
  const [newTitle, setNewTitle] = useState(title);
  const [newPrice, setNewPrice] = useState(price);
  const [newIngredients, setNewIngredients] = useState(ingredients);

  const handleOnSubmit = () => {
    setIsLoading(true);
    onSave({
      ...value,
      name: newTitle,
      description: newDescription,
      price: newPrice,
      ingredients: newIngredients
    })
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNewDescription(description);
    setNewTitle(title);
    setNewPrice(price);
    setNewIngredients(ingredients);
  }, [description, title, price, ingredients]);

  return (
    <Card variant="outlined" {...props}>
      <CardContent>
        {!isPlanEdit && isSelected && (
          <OutlinedInput
            className="Card-title"
            value={newTitle}
            onChange={(event) => setNewTitle(event.target.value)}
          />
        )}
        {((!isPlanEdit && !isSelected) || isPlanEdit) && (
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>
        )}
        {isPlanEdit && isSelected && (
          <>
            <Typography gutterBottom variant="subtitle2">
              Precio
            </Typography>
            <OutlinedInput
              className="price-input"
              value={newPrice}
              onChange={(event) => setNewPrice(event.target.value)}
            />
          </>
        )}
        {!isSelected && (
          <Typography variant="body2" color="text.secondary">
            <div
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
          </Typography>
        )}
        {isSelected && (
          <>
            <Typography gutterBottom variant="subtitle2">
              Descripción
            </Typography>
            <Editor value={newDescription} onChange={setNewDescription} />
          </>
        )}
        {!isPlanEdit && isSelected && (
          <>
            <Typography gutterBottom variant="subtitle2">
              Ingredientes
            </Typography>
            <Editor value={newIngredients || ''} onChange={setNewIngredients} />
          </>
        )}
      </CardContent>
      <CardActions>
        <Button variant="text" onClick={onCancel}>
          Cancelar
        </Button>
        {!isSelected && <Button onClick={onSelected}>Editar</Button>}
        {isSelected && (
          <Button loading={isLoading} onClick={handleOnSubmit}>
            Guardar
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const renderList = (
  list: any,
  selectedIndex: any,
  setSelectedIndex: any,
  saveService: any,
  isPlanEdit?: boolean
) => {
  return list.map((item: any, index: number) => {
    const isSelected = selectedIndex === index;
    const className = `${isSelected && 'selected'}`;
    const props = {
      title: item.name,
      description: item.description,
      price: item.price,
      ingredients: item.ingredients,
      onSelected: () => setSelectedIndex(index),
      onCancel: () => setSelectedIndex(null),
      className,
      isSelected,
      isPlanEdit,
      value: item,
      onSave: saveService
    };
    return (
      <Grid item key={index}>
        <CardItem {...props} />
      </Grid>
    );
  });
};

const Dashboard: FC = () => {
  const [planList, setPlanList] = useState<any>({
    singleProduct: [],
    dualProduct: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [discountCodeList, setDiscountCodeList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<number>();
  const [selectedPlan, setSelectedPlan] = useState<number>();

  const updateProducts = () => {
    setIsLoading(true);
    getProductsList()
      .then((response) => setProductList(response.data))
      .finally(() => setIsLoading(false));
  };
  const updateDiscountCodes = () => {
    setIsLoading(true);
    getDiscountCodeList()
      .then(({ data }) => setDiscountCodeList(data))
      .finally(() => setIsLoading(false));
  };
  const updatePlanList = () => {
    setIsLoading(true);
    getServicePlan()
      .then((response: any) => {
        const singleProduct = response.data.find((plan: any) => plan.benefit === 1);
        const dualProduct = response.data.find((plan: any) => plan.benefit === 2);
        setPlanList({
          singleProduct: [
            {
              ...singleProduct,
              name: '1 mes',
              price: singleProduct.one_month,
              description: singleProduct.one_month_description,
              editKey: 'one_month',
              frequency: 1
            },
            {
              ...singleProduct,
              name: '3 meses',
              price: singleProduct.three_month,
              description: singleProduct.three_month_description,
              editKey: 'three_month',
              frequency: 3
            },
            {
              ...singleProduct,
              name: '6 meses',
              price: singleProduct.six_month,
              description: singleProduct.six_month_description,
              editKey: 'six_month',
              frequency: 6
            }
          ],
          dualProduct: [
            {
              ...dualProduct,
              name: '1 mes',
              price: dualProduct.one_month,
              description: dualProduct.one_month_description,
              editKey: 'one_month',
              frequency: 1
            },
            {
              ...dualProduct,
              name: '3 meses',
              price: dualProduct.three_month,
              description: dualProduct.three_month_description,
              editKey: 'three_month',
              frequency: 3
            },
            {
              ...dualProduct,
              name: '6 meses',
              price: dualProduct.six_month,
              description: dualProduct.six_month_description,
              editKey: 'six_month',
              frequency: 6
            }
          ]
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = () => {
    updatePlanList();
    updateDiscountCodes();
    updateProducts();
  };

  const handleProductEdit = (values: any) =>
    updateProduct(values.id, values).then(() => updateData());

  const handleServicePlanEdit = (values: any) =>
    updateServicePlan(values.id, {
      ...values,
      [`${values.editKey}_description`]: values.description,
      [values.editKey]: values.price
    }).then(() => updateData());

  useEffect(() => updateData(), []);

  return (
    <div className="Dashboard" data-testid="Dashboard">
      {process.env.REACT_APP_IS_VIDARIUM === 'true' ? (
        <></>
      ) : (
        <BucketImage loading="lazy" alt="logo" src={images.LyfLogo} />
      )}

      <Grid container spacing={2}>
        <Grid item md={8} sm={12} xs={12}>
          <Typography variant="h5">Productos</Typography>
          <Grid container spacing={2} direction="column">
            {renderList(productList, selectedProduct, setSelectedProduct, handleProductEdit)}
          </Grid>
          <Typography variant="h5">Planes de 1 Producto</Typography>
          <Grid container spacing={2} direction="column">
            {renderList(
              planList.singleProduct,
              selectedPlan,
              setSelectedPlan,
              handleServicePlanEdit,
              true
            )}
          </Grid>
          <Typography variant="h5">Planes de 2 Productos</Typography>
          <Grid container spacing={2} direction="column">
            {renderList(
              planList.dualProduct,
              selectedPlan,
              setSelectedPlan,
              handleServicePlanEdit,
              true
            )}
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography variant="h5">Cupones</Typography>
          <Card variant="outlined">
            <DiscountCodeForm onSuccess={updateData} />
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell align="right">Expiración</TableCell>
                    <TableCell align="right">Porcentaje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discountCodeList.map((discountCode: any, index) => (
                    <TableRow key={discountCode.code}>
                      <TableCell scope="row">{discountCode.code}</TableCell>
                      <TableCell align="right">{discountCode.expirationDate}</TableCell>
                      <TableCell align="right">{discountCode.percentage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
      <LoadingScreen loading={isLoading} />
    </div>
  );
};

export default Dashboard;
