import { FC } from 'react';
import images from 'assets/images';
import { Typography } from 'ui/Material';
import { OrderHistoryModel } from '../../../../models/orders';
import { useTranslation } from 'react-i18next';

import './OrdersHistory.scss';
import BucketImage from 'components/BucketImage';

interface OrdersHistoryProps {
  ordersHistory?: OrderHistoryModel[];
}

const OrdersHistory: FC<OrdersHistoryProps> = ({ ordersHistory }: OrdersHistoryProps) => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="OrdersHistory" data-testid="OrdersHistory">
      <div className="title-wrapper">
        <BucketImage loading="lazy" src={images.ArrowTopIcon} alt="arrow top" />
        <Typography variant="h6">{t('MainProfile.Orders.ordersHistory')}</Typography>
      </div>
      {ordersHistory?.map((orderHistory) => (
        <>
          <div className="history-wrapper">
            <div>
              <Typography variant="body2" fontWeight={500}>
                {orderHistory.textNumber} - {orderHistory.textPlan}
              </Typography>
              <Typography variant="body2" fontWeight={100}>
                {orderHistory.dateDelivery}
              </Typography>
            </div>
          </div>
          <hr color="#ebebeb" />
        </>
      ))}
    </div>
  );
};

export default OrdersHistory;
