import { FC } from 'react';
import { Box, CircularProgress, Fade } from 'ui/Material';

interface LoadingProps {
  loading?: boolean;
  children: JSX.Element;
}

const Loading: FC<LoadingProps> = ({ loading, children }: LoadingProps) => {
  const sx = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

  return loading ? (
    <Box data-testid="Loading" sx={sx}>
      <Box sx={{ height: 80 }}>
        <Fade in={loading}>
          <CircularProgress />
        </Fade>
      </Box>
    </Box>
  ) : (
    children
  );
};

export default Loading;
