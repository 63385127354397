import { FC } from 'react';
import { Breadcrumbs, Link, Typography } from 'ui/Material';
import options from 'components/Header/options';

import './Breadcrumb.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {}

const Breadcrumb: FC<Props> = () => {
  const location = useLocation();
  const { t }: { t: Function } = useTranslation();

  const currentView = options.find(
    (item: any) => item.path === location.pathname && location.pathname.indexOf('home') < 0
  );

  if (!currentView) {
    return <div data-testid="Breadcrumb" />;
  }
  return (
    <div className="Breadcrumb" data-testid="Breadcrumb">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="primary" href="/">
          Inicio
        </Link>
        <Typography>{t(currentView?.label)}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
