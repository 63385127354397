import Button from 'components/Button';
import { FC } from 'react';
import { Typography } from 'ui/Material';
import * as routes from 'navigation/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './RedirectToTest.scss';

interface RedirectToTestProps {}

const RedirectToTest: FC<RedirectToTestProps> = () => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div className="RedirectToTest" data-testid="RedirectToTest">
      <div>
        <Typography variant="subtitle1" fontWeight={600}>
          {t('Products.RedirectToTest.title')}
        </Typography>
        <Typography variant="caption">{t('Products.RedirectToTest.subtitle')}</Typography>
      </div>
      <div>
        <Button variant="contained">
          <Link to={routes.assessmentStart}>{t('start')}</Link>
        </Button>
      </div>
    </div>
  );
};

export default RedirectToTest;
