import { FC } from 'react';
import { Grid } from 'ui/Material';

interface Breakpoints {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface Column {
  className?: string;
  content?: JSX.Element | Array<JSX.Element>;
  breakpoints?: Breakpoints;
}

interface Props {
  columns: Array<Column>;
}

const InfoByColumns: FC<Props> = ({ columns }) => {
  return (
    <div className="InfoByColumns" data-testid="InfoByColumns">
      <Grid container>
        {columns.map((column: Column, index) => {
          const gridProps = {
            className: column.className,
            item: true,
            lg: 12 / columns.length,
            ...column.breakpoints
          };

          return (
            <Grid key={index} {...gridProps}>
              {column.content}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default InfoByColumns;
