// eslint-disable-next-line react/prop-types

import React from 'react';
import PropTypes from 'prop-types';
import '../index.css';

function SeparatorResults({ message = '', font = 'result_bold' }) {
  if (!message) message = '';
  return (
    <div className="porpose-container" style={{ fontFamily: font, marginTop: '2rem' }}>
      <div
        className="separator-header"
        style={{ fontFamily: font }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
}

SeparatorResults.propTypes = {
  message: PropTypes.string,
  font: PropTypes.string
};

export default SeparatorResults;
