import { FC } from 'react';
import ReactWhatsapp from 'react-whatsapp';
import { WhatsApp } from 'ui/Material/icons';
import { Fab } from 'ui/Material';

import './WhatsappButton.scss';

interface Props {}

const WhatsappButton: FC<Props> = () => {
  const wsProps: any = {
    number: '57-321-221-8463',
    message: '',
    className: 'WhatsappButton',
    'data-testid': 'WhatsappButton'
  };

  return (
    <ReactWhatsapp {...wsProps}>
      <Fab>
        <WhatsApp fontSize="large" />
        Contactanos por Whatsapp
      </Fab>
    </ReactWhatsapp>
  );
};

export default WhatsappButton;
