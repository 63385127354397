import { FC } from 'react';
import images from 'assets/images';
import { Grid, Typography } from 'ui/Material';
import './FrequentOrder.scss';
import BucketImage from 'components/BucketImage';

interface FrequentOrderProps {
  value: any;
}

const FrequentOrder: FC<FrequentOrderProps> = ({ value }) => {
  const mainProduct = value.find(({ main_product: isMain }: any) => isMain);
  const secondaryProduct = value.find(({ secondary_product: isSecondary }: any) => isSecondary);
  return (
    <div className="FrequentOrder" data-testid="FrequentOrder">
      <div className="title-wrapper">
        <BucketImage loading="lazy" src={images.ArrowTopIcon} alt="arrow top" />
        <Typography variant="h6">Producto recomendado</Typography>
      </div>
      <Grid container spacing={2} className="history-wrapper">
        <Grid item md={12} sm={12} xs={12}>
          <BucketImage loading="lazy" src={mainProduct?.icon_product} alt={mainProduct?.approach} />
          <Typography variant="body1">{mainProduct?.approach}</Typography>
        </Grid>
        {secondaryProduct && (
          <Grid item md={12} sm={12} xs={12}>
            <BucketImage
              loading="lazy"
              src={secondaryProduct?.icon_product}
              alt={secondaryProduct?.approach}
            />
            <Typography variant="body1">{secondaryProduct?.approach}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FrequentOrder;
