import React from 'react';
import PropTypes from 'prop-types';

import './check.css';

const { REACT_APP_S3_BUCKET_URL } = process.env;
const check = `${REACT_APP_S3_BUCKET_URL}/circle/img/check.png`;

export function CheckLeft({ data, labels }) {
  return (
    <div className="container_ckeck_i">
      <div className="text_status_izquierda_ok"> ¡Muy bien!</div>
      {/* <div className="img_check_izquierda"> */}
      <img className="status" src={check} alt="status" />
      {/* </div> */}
    </div>
  );
}
CheckLeft.propTypes = {
  data: PropTypes.any,
  labels: PropTypes.any
};
