import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import images from 'assets/images';
import CenteredMessage from 'components/CenteredMessage';
import * as routes from 'navigation/routes';
import './AssessmentStart.scss';

interface Props {}

const AssessmentStart: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();

  return (
    <div data-testid="AssessmentStart" className="AssessmentStart">
      <CenteredMessage
        image={images.Exercise}
        title={t('Assessment.start.title')}
        description={t('Assessment.start.description')}
        linkTo={routes.assessment}
        linkLabel={t('Assessment.start.submitButton')}
      />
    </div>
  );
};

export default AssessmentStart;
