export const AccountStatus = {
  SUCCESS: 0,
  CREATED: 1,
  CREATED_SURVEY: 2,
  ACTIVATED: 3
};

export const AccountSurveyStatus = {
  SUCCESS: 'SUCCESS',
  CREATED: 'CREATED',
  CREATED_SURVEY: 'CREATED',
  ACTIVATED: 'ACTIVATED'
};
