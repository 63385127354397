import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CenteredMessage from 'components/CenteredMessage';
import { Link, useParams } from 'react-router-dom';
import { getPaymentStatus } from 'adapters/Axios/services/orders.service';
import { AxiosResponse } from 'adapters/Axios/models/axiosModels';
import LoadingScreen from 'components/LoadingScreen';
import images from 'assets/images';
import Button from 'components/Button';
import * as routes from 'navigation/routes';
import './CertificatePayment.scss';
import TagManager from 'react-gtm-module';

interface Props {}

const CertificatePayment: FC<Props> = () => {
  const { t }: { t: Function } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [payment, setPayment] = useState<any>();

  const { reference } = useParams();

  useEffect(() => {
    if (reference && !isLoading) {
      setIsLoading(true);
      getPaymentStatus(reference)
        .then((response: AxiosResponse) => setPayment(response.data))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const isApproved = payment?.status === 'APPROVED';

  useEffect(() => {
    const hasExpiredPlan = localStorage.getItem('hasExpiredPlan');
    if (reference && !isLoading && payment) {
      if (isApproved) {
        const event = hasExpiredPlan === 'true' ? 'renewPlan' : 'getPlan';
        TagManager.dataLayer({
          dataLayer: {
            payment,
            event
          }
        });
        localStorage.removeItem('hasExpiredPlan');
      } else {
        TagManager.dataLayer({
          dataLayer: {
            payment,
            event: 'transactionDeclined'
          }
        });
      }
    }
  }, [payment]);

  const messageProps = {
    title: t('CertificatePayment.title'),
    description: isApproved ? 'Pago exitoso' : 'Pago rechazado',
    image: isApproved ? images.paymentSuccess : images.paymentError
  };

  return (
    <div data-testid="CertificatePayment" className="CertificatePayment">
      <LoadingScreen loading={isLoading} />
      <CenteredMessage {...messageProps}>
        <Button>
          <Link to={routes.usePrivatePrefix(routes.mainProfile)}>IR AL PERFIL</Link>
        </Button>
      </CenteredMessage>
    </div>
  );
};

export default CertificatePayment;
