import images from 'assets/images';
import BucketImage from 'components/BucketImage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from 'ui/Material';

import './CustomerService.scss';

interface CustomerServiceProps {}

const CustomerService: FC<CustomerServiceProps> = () => {
  const { t }: { t: Function } = useTranslation();

  return (
    <Card className="CustomerService" data-testid="CustomerService">
      <CardContent>
        <BucketImage loading="lazy" src={images.mainProfile.mobileDoctor} alt="mobile doctor" />
        <Typography variant="h6" marginBottom={2} fontWeight={500}>
          {t('MainProfile.ProfilePanel.CustomerService.title')}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} marginBottom={2}>
          {t('MainProfile.ProfilePanel.CustomerService.message')}
        </Typography>
        <hr color="#8B84D7" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} justifyContent="center" alignItems="center" margin="auto">
            <Typography variant="subtitle2" fontWeight={200} marginBottom={2}>
              {t('MainProfile.ProfilePanel.CustomerService.messageFooter')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomerService;
