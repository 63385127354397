import BucketImage from 'components/BucketImage';
import { FC } from 'react';
import { Stack, Tooltip, Typography } from 'ui/Material';
import { InfoOutlined } from 'ui/Material/icons';
import './QuestionHeader.scss';

interface Props {
  title: string;
  tooltip?: string;
  description?: string;
  image?: string;
  children?: any;
}

const QuestionHeader: FC<Props> = ({ image, title, description, tooltip, children }) => {
  const stackProps = {
    className: 'QuestionHeader',
    'data-testid': 'QuestionHeader'
  };

  const imageProps = {
    alt: 'question-header',
    'data-testid': 'QuestionHeader-image'
  };

  return (
    <Stack {...stackProps} justifyContent="center" alignItems="center">
      {!!image && <BucketImage loading="lazy" {...imageProps} src={image} />}
      <Typography variant="h6">{title}</Typography>
      {description && (
        <Typography variant="body1">
          <div
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </Typography>
      )}
      {tooltip && (
        <Tooltip data-testid="Tooltip" title={tooltip}>
          <InfoOutlined color="primary" className="tooltip" />
        </Tooltip>
      )}
      {children}
    </Stack>
  );
};

export default QuestionHeader;
