import BackButton from 'components/BackButton';
import Button from 'components/Button';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Stepper.scss';

interface StepperProps {
  steps: Array<any>;
  defaultStep?: number;
  onStepChange: Function;
  onPrevStep?: Function;
  Component: any;
  ComponentProps?: any;
  mapper?: any;
  onSubmit?: Function;
  CheckPointComponent?: any;
  startBackwards?: boolean;
}

const Stepper: FC<StepperProps> = ({
  steps,
  Component,
  ComponentProps,
  mapper,
  onStepChange,
  onPrevStep,
  onSubmit,
  defaultStep,
  startBackwards,
  CheckPointComponent
}) => {
  const { t }: { t: Function } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  const [isStarted, setIsStarted] = useState(false);

  const [canRestore, setCanRestore] = useState(true);

  const [totalSubmittedTimes, setTotalSubmittedTimes] = useState(0);

  const [simulateInProgress, setSimulateInProgress] = useState(false);

  const [isMovingForward, setIsMovingForward] = useState(true);

  const stepValues = (steps || [])[currentStep];

  let stepPropsList;

  if (Array.isArray(stepValues)) {
    stepPropsList = mapper && stepValues ? stepValues.map(mapper) : stepValues;
  } else {
    stepPropsList = mapper && stepValues ? [mapper(stepValues)] : [stepValues];
  }

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    setTotalSubmittedTimes(0);
    setCanRestore(false);
    setIsMovingForward(true);
  };

  const previousStep = () => {
    setTotalSubmittedTimes(0);
    setCurrentStep(currentStep - 1);
    setIsMovingForward(false);
    if (onPrevStep) {
      onPrevStep();
    }
  };

  const handleOnSubmit = (...values: any) => {
    const submittedTimes = totalSubmittedTimes + 1;
    if (submittedTimes === stepPropsList.length) {
      if (onSubmit) {
        onSubmit(...values);
      }
      nextStep();
    } else {
      setTotalSubmittedTimes(submittedTimes);
    }
  };

  const onCheckPointSubmit = () => {
    setIsStarted(true);
    setTotalSubmittedTimes(0);
    setCanRestore(false);
    setIsMovingForward(true);
  };

  const simulateMultipleClicks = () => {
    setSimulateInProgress(true);
    setTotalSubmittedTimes(0);
    const questionButtons = document.querySelectorAll(
      '.QuestionWrapper .MuiButton-root:not(.simulate-button)'
    );
    const interval = 500;
    questionButtons.forEach((button: any, index: number) => {
      setTimeout(() => {
        button.click();
        if (index === questionButtons.length - 1) {
          setSimulateInProgress(false);
        }
      }, index * interval);
    });
  };

  useEffect(() => {
    onStepChange(currentStep);

    if (!currentStep && isStarted && isMovingForward) {
      setIsStarted(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (steps?.length === currentStep) {
      setCurrentStep(0);
      setTotalSubmittedTimes(0);
      setIsMovingForward(true);
    }
  }, [steps]);

  useEffect(() => {
    if (defaultStep && canRestore) {
      setCanRestore(false);
      setCurrentStep(defaultStep);
      setIsStarted(true);
    }
  }, [defaultStep]);

  useEffect(() => {
    if (startBackwards) {
      setCurrentStep(steps.length - 1);
    }
  }, [startBackwards]);

  const hasMultipleItems = stepPropsList.length > 1;

  const componentWrapperClassName = `component-wrapper ${hasMultipleItems && 'hide-buttons'} ${
    simulateInProgress && 'is-loading'
  }`;

  const simulationButtonProps = {
    loading: simulateInProgress,
    className: 'simulate-button',
    onClick: simulateMultipleClicks
  };
  return (
    <div className="Stepper" data-testid="Stepper">
      {currentStep > 0 && <BackButton onClick={previousStep} />}
      {!isStarted &&
        CheckPointComponent &&
        stepPropsList.map((item: any, index: number) => (
          <CheckPointComponent key={index} {...item} onSubmit={onCheckPointSubmit} />
        ))}
      <div className={componentWrapperClassName}>
        {(isStarted || !CheckPointComponent) &&
          stepPropsList.map((item: any, index: number) => (
            <Component key={index} {...ComponentProps} {...item} onSubmit={handleOnSubmit} />
          ))}
        {hasMultipleItems && <Button {...simulationButtonProps}>{t('continue')}</Button>}
      </div>
    </div>
  );
};

export default Stepper;
