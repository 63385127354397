import { Routes, Route } from 'react-router-dom';
import * as routes from 'navigation/routes';
import Home from 'containers/Home';
import Layout from 'containers/Layout';
import Assessment from 'containers/Assessment';
import AssessmentStart from 'containers/AssessmentStart';
import Login from 'containers/Login';
import AccountByConfirm from '../../containers/AccountByConfirm';
import ConfirmAccount from 'containers/ConfirmAccount';
import SetPassword from 'containers/SetPassword';
import ResetPassword from 'containers/ResetPassword/ResetPassword';
import Blog from 'containers/Blog';
import FAQs from 'containers/FAQs';
import AboutUs from 'containers/AboutUs';
import Products from 'containers/Products';
import CertificatePayment from 'containers/CertificatePayment';
import NewResumen from 'containers/NewResults';

const MainStack = () => (
  <Layout>
    <Routes>
      <Route index element={<Home />} />
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.assessment} element={<Assessment />} />
      <Route path={routes.assessmentStep} element={<Assessment />} />
      <Route path={routes.assessmentStart} element={<AssessmentStart />} />
      <Route path={routes.signIn} element={<Login />} />
      <Route path={routes.signInNext} element={<Login />} />
      <Route path={routes.accountByConfirm} element={<AccountByConfirm />} />
      <Route path={routes.confirmAccount} element={<ConfirmAccount />} />
      <Route path={routes.setPassword} element={<SetPassword />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      <Route path={routes.blog} element={<Blog />} />
      <Route path={routes.blogParams} element={<Blog />} />
      <Route path={routes.faqs} element={<FAQs />} />
      <Route path={routes.aboutUs} element={<AboutUs />} />
      <Route path={routes.results} element={<NewResumen />} />
      <Route path={routes.newResults} element={<NewResumen />} />
      <Route path={routes.products} element={<Products />} />
      <Route path={routes.certificatePayment} element={<CertificatePayment />} />
    </Routes>
  </Layout>
);

export default MainStack;
