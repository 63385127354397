import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from 'ui/Material';
import Logo from 'assets/images/Logo-white.png';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import './Footer.scss';
import { personalInfoPolicy, tcFile, refundFile } from 'navigation/routes';
import * as routes from 'navigation/routes';
import { useLocation, Link } from 'react-router-dom';
import NewFooter from 'components/NewFooter/index';

const Footer = () => {
  const { t }: { t: Function } = useTranslation();
  const facebookUrl = 'https://www.facebook.com/lyfnutricionpersonalizada/';
  const instagramUrl = 'https://www.instagram.com/lyfnutricionpersonalizada/';
  const youtubeUrl = 'https://www.youtube.com/channel/UCueLKmwcpoh75wUCrYGtPug';
  const gridProps = {
    container: true,
    className: 'Footer',
    justifyContent: 'flex-start',
    'data-testid': 'Footer'
  };
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes('new-results') ? (
        <Grid direction="row" {...gridProps}>
          <Grid item xs={12} sm={12} md={3} justifyContent="center">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              {process.env.REACT_APP_IS_VIDARIUM === 'true' ? (
                <></>
              ) : (
                <img
                  loading="lazy"
                  width="130"
                  height="80"
                  className="logo-img"
                  alt="logo"
                  src={Logo}
                />
              )}

              <Stack direction="row" justifyContent="flex-start" className="icons">
                <a href={facebookUrl} target="_blank" rel="noreferrer">
                  <BsFacebook />
                </a>
                <a href={instagramUrl} target="_blank" rel="noreferrer">
                  <BsInstagram />
                </a>
                <a href={youtubeUrl} target="_blank" rel="noreferrer">
                  <BsYoutube />
                </a>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3} justifyContent="center">
            <Stack direction="column" spacing={4}>
              <Typography variant="h6">Políticas</Typography>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href={tcFile}>
                    <Typography>Términos y condiciones</Typography>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href={personalInfoPolicy}>
                    <Typography>Tratamiento de datos personales</Typography>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href={refundFile}>
                    <Typography>Devoluciones</Typography>
                  </a>
                </li>
              </ul>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3} justifyContent="center">
            <Stack direction="column" spacing={4}>
              <Typography variant="h6">Contáctanos</Typography>
              <ul>
                <li>
                  <a
                    href="mailto:servicioalcliente@lyfnutricion.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography>servicioalcliente@lyfnutricion.com</Typography>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href={routes.refundUrl}>
                    <Typography>Peticiones, quejas y reclamos</Typography>
                  </a>
                </li>
              </ul>
            </Stack>
            <Stack direction="column" spacing={4}>
              <Typography variant="h6">Otros sitios</Typography>
              <ul>
                <li>
                  <a href="https://www.sic.gov.co/" target="_blank" rel="noreferrer">
                    <Typography>www.sic.gov.co</Typography>
                  </a>
                </li>
              </ul>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3} justifyContent="center">
            <Stack direction="column" spacing={4}>
              <Typography variant="h6">
                <Link to={routes.faqs}>Preguntas frecuentes</Link>
              </Typography>
              <ul>
                <li>
                  <Typography>{t('Footer.copyright')}</Typography>
                </li>
              </ul>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          {/* <FooterNewResult></FooterNewResult> */}
          <NewFooter></NewFooter>
        </>
      )}
    </>
  );
};

export default Footer;
