import images from 'assets/images';
import { FC } from 'react';
import { Grid, Typography } from 'ui/Material';
import * as routes from 'navigation/routes';
import './HowTo.scss';
import LinkButton from 'components/LinkButton';
import BucketImage from 'components/BucketImage';

const HowTo: FC = () => {
  const gridProps = {
    container: true,
    spacing: 4
  };

  const steps = [
    {
      image: images.productHowTo.firstStep,
      label: 'Toma un sobre por día'
    },
    {
      image: images.productHowTo.secondStep,
      label: 'Mezclalo con bebida favorita ya sea fría o caliente'
    },
    {
      image: images.productHowTo.thirdStep,
      label: 'Disfruta de tu alimento LYF'
    }
  ];

  return (
    <div className="HowTo">
      <Typography variant="h6">¿Cómo prepararlo para beber?</Typography>
      <Grid {...gridProps} data-testid="HowTo">
        {steps.map((step: any, index: number) => (
          <Grid key={index} item md={4} sm={4} xs={12}>
            <BucketImage loading="lazy" alt={step.label} src={step.image} />
            <Typography>{step.label}</Typography>
          </Grid>
        ))}
      </Grid>
      <LinkButton to={routes.blog} label="VER MÁS TIPS" />
    </div>
  );
};

export default HowTo;
